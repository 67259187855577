import toast from "react-hot-toast";
import {
  ServerURL,
  ShipperMethodURL,
  getAccessToken,fetch_instance,
  GetUserShipperPermissionMethodAPIUrl,
  SaveUserShipperPermissionMethodAPIUrl,
  FormDataUserShipperPermissionMethodAPIUrl,SaveFollowUp,followUpGet, SaveShipperSwapPermission,GetShipperSwapPermissionMethodAPIUrl,GetUserPermissionMethodAPIUrl, SaveUseruserPermissionMethodAPIUrl,
} from "./ServerConfig";

class shipper_user_api {
  async getUserList() {
    let obj = {};
    await fetch_instance(`${ServerURL}${FormDataUserShipperPermissionMethodAPIUrl}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(obj);
  }
  async getPUserList(user) {
    let obj = {};
    await fetch_instance(`${ServerURL}${GetUserPermissionMethodAPIUrl}?user=${user}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(obj);
  }

  async getFormData(user) {
    let obj = {};
    await fetch_instance(`${ServerURL}${GetUserShipperPermissionMethodAPIUrl}`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        user: user,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString())
console.log(error);
      });
    return Promise.resolve(obj);
  }
  async getUserFormData(user) {
    let obj = {};
    await fetch_instance(`${ServerURL}custompermissions/getUserPermission`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        user: user,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString())
console.log(error);
      });
    return Promise.resolve(obj);
  }

  async save_permissions(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${SaveUserShipperPermissionMethodAPIUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
      // return Promise.resolve(result)
    });
    return Promise.resolve(result);
  }
  async saveuser_permissions(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${SaveUseruserPermissionMethodAPIUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
      // return Promise.resolve(result)
    });
    return Promise.resolve(result);
  }
  /////////////////////////////////////////////////////////////

  async getFollowSettingFormData(user) {
    let obj = {};
    await fetch_instance(`${ServerURL}${followUpGet}`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        user: user,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(obj);
  }


  async save_follow_up_permissions(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${SaveFollowUp}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
      // return Promise.resolve(result)
    });
    return Promise.resolve(result);
  }


  //####################### Shipper Swap Apis ###################

  async getShipperSwapFormData(user) {
    let obj = {};
    await fetch_instance(`${ServerURL}${GetShipperSwapPermissionMethodAPIUrl}`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        user: user,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString())
console.log(error);
      });
    return Promise.resolve(obj);
  }

  async save_shipper_Swap_permissions(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${SaveShipperSwapPermission}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
      // return Promise.resolve(result)
    });
    return Promise.resolve(result);
  }
}
export const ShipperUserApi = new shipper_user_api();
