import { subHours, subDays } from "date-fns";
import {
  ServerURL,
  getAccessToken,fetch_instance,
  CreateUserMethodAPIUrl,
  UserGetPermittedActionApiMethodUrl,
  UserResetMethodAPIUrl,
  CustomerResetMethodAPIUrl,
  ShipperResetMethodAPIUrl,
} from "./ServerConfig";
import toast from "react-hot-toast";


class user_api {
  async check_user_permission(perm) {
    let result = {};

    await fetch_instance(`${ServerURL}customization/check_user_permission`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        perm: perm,
      }),
    }).then((response) => {
      return response.json()
    }).then((data) => {
      result = data;
      // console.log(data);
      return Promise.resolve(result);
    });
    return Promise.resolve(result);
  }

  async getUsers() {
    let users = [];
    await fetch_instance(`${ServerURL}users/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        users = data;
        // console.log(data);
        return Promise.resolve(users);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });

    return Promise.resolve(users);
  }


  async create_user(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${CreateUserMethodAPIUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
    });
    return Promise.resolve(result);
  }
  async reset_password(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${UserResetMethodAPIUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
    });
    return Promise.resolve(result);
  }
  async customer_reset_password(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${CustomerResetMethodAPIUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
    });
    return Promise.resolve(result);
  }
  async shipper_reset_password(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${ShipperResetMethodAPIUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      // console.log(response);
      result = response;
      // return Promise.resolve(result)
    });
    return Promise.resolve(result);
  }
  async user_has_permission(model, perm_type) {
    let has_permission = false;

    await fetch_instance(`${ServerURL}${UserGetPermittedActionApiMethodUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        model: model,
        perm_type: perm_type,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        has_permission = data.has_permission || false;
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(has_permission);
  }
}

export const UserApi = new user_api();
