/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Typography
} from '@material-ui/core';
import SearchIcon from '../../icons/Search';
import XIcon from '../../icons/X';
import { format } from "date-fns";
import Scrollbar from '../Scrollbar';
import wait from '../../utils/wait';
import { orderApi } from "../../__fakeApi__/orderApi";
import { DateApi } from "../../__fakeApi__/DateApi";
import { useTranslation } from "react-i18next";
import { getOrderStatusLabel } from "../../__fakeApi__/OrderStatus";
import { OrderStatusApi } from "../../__fakeApi__/OrderStatusApis";
import  CopyToClipboard  from 'react-copy-to-clipboard';
const statusOptions = ['Pending', 'Out For Deliver', 'Deliverd','Hold','Undeliverd',"Rejected","In Hub"];
const results = [
  {
    description: 'Algolia broadly consists of two parts: search implementation and search analytics. We provide tools that make it easy for your developers...',
    title: 'What does Algolia do?'
  },
  {
    description: 'To be clear, search doesn’t know the direction that your business should take. However, it can help you gather information on what your customers want...',
    title: 'Search as a feedback loop'
  },
  {
    description: 'Algolia provides your users with a fast and rich search experience. Your Algolia search interface can contain a search bar, filters, infinite scrolling...',
    title: 'What can Algolia do for my users?'
  }
];

const ContentSearch = () => {
  const [value, setValue] = useState('');
  const [order, setOrder] = useState({});
  const [statusdata, setStatusData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [trackinglink,setTrackinglink] = useState("https://rtxexpress.com/order_tracking.html?tracking_id=")
  const { t } = useTranslation();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const search = async () => {
    setShowResults(false);
    setIsLoading(true);
    // Do search here
    //wait(1500);
    const data = await orderApi.getSingleOrder(value.trim());
    const stdata = await OrderStatusApi.get_order_status_list(value.trim());
    console.log("stdata",stdata)
    setTrackinglink(`https://rtxexpress.com/order_tracking.html?tracking_id=${data.slug}`)
    if(!data["detail"]){
    setOrder(data)
    setStatusData(stdata)
    console.log("data",data)
    console.log("stdata",stdata)
    
    setShowResults(true);
    }else{
      console.log("from else")
      setOrder([])
      setStatusData([])
      //setShowResults(false)
    }
    setIsLoading(false);
  };

  const handleClick = () => {
    search();
  };

  const handleKeyUp = (event) => {
    if (event.code === 'ENTER' || event.charCode == 13) {
      search();
    }
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton
          color="inherit"
          onClick={handleOpen}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="top"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: '100%' }
        }}
        variant="temporary"
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton onClick={handleClose}>
              <XIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ p: 3 }}>
          <Container maxWidth="md">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
             
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                onChange={(event) => setValue(event.target.value)}
                // onKeyUp={handleKeyUp}
                onKeyPress={handleKeyUp}
                placeholder="Search..."
                value={value}
              />
              <Button
                color="primary"
                onClick={handleClick}
                size="large"
                sx={{ ml: 2 }}
                variant="contained"
              >
                {t("Search")}
              </Button>
              <CopyToClipboard text={trackinglink?trackinglink :""}>
                              <Button sx={{ ml: 2,width:220 }} size="large" variant="contained" color="primary">
                                {t("Copy Tracking Link")}
                              </Button>
                            </CopyToClipboard> <br/>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Scrollbar options={{ suppressScrollX: true }}>
                {isLoading
                  ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )
                  : (
                    <>
                      {showResults && order&&(
                        <>
                          
                            <Box
                              sx={{ mb: 2 }}
                            >
                              
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Serial")}</strong>: {order.serial}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Full Name")}</strong>: {order.full_name}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Address")}</strong>: {order.address}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Total Fees")}</strong>: {order.total_fees}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Total Amount")}</strong>: {order.total_amount}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Status")}</strong>:{" "}
                            {order.Order_Delivery_Status
                              ? getOrderStatusLabel(order.Order_Delivery_Status)
                              : ""}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Setteled Client")}</strong>: {order.Order_Collect_Status ? t("Yes") :t("No")}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Return Client")}</strong>: {order.Returned_Client ? t("Yes") :t("No")}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Area")}</strong>: {order.area_name + "-" + order.mandoob_zone}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <Link
                            color="textPrimary"
                            component={RouterLink}
                            to="/dashboard/"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Order Date")}</strong>:{" "}
                            {format(DateApi.getDate(order.order_date), "dd-MM-yyyy")}
                          </Link>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Company")}</strong>: {order.company_name}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("City")}</strong>: {order.city}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Shipment Content")}</strong>: {order.shipment_contents}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Phone")}</strong>: {order.phone}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Status Date")}</strong>:
                            {order.Delivery_satus_date ? format(DateApi.getDate(order.Delivery_satus_date), "dd-MM-yyyy") :""}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Paid by sender")}</strong>:{" "}
                            {order.paid_py_sender ? "Yes" : "No"}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Mandoob")}</strong>: {order.shipper} - {order.shipper_phone}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            sx={{ fontSize: 20 }}
                          >
                            <strong>{t("Notes")}</strong>: {order.notes}
                          </Typography>
                        </Box>
                      </Grid>
                      
                    </Grid>
            <Table sx={{ height: "100%", width: "100%" }}>
            <TableHead>
              <TableRow sx={{ height: "100%" }}>
                <TableCell
                  sx={{ width: "25%", textAlign: "center" }}
                >
                  {t("Status")}
                </TableCell>{" "}
                <TableCell
                  sx={{ width: "25%", textAlign: "center" }}
                >
                  {t("With Return")}
                </TableCell>
                <TableCell 
                  sx={{ width: "35%", textAlign: "center" }}
                >
                  {t("Status Date")}
                </TableCell>
                <TableCell
                  sx={{ width: "40%", textAlign: "center" }}
                >
                  {t("Notes")}
                </TableCell>
               
              </TableRow>
            </TableHead>
            <TableBody>
              {statusdata.length >0  &&
                statusdata.map((row) => {
                  return (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ height: 1 }}
                    >
                      <TableCell sx={{ width: "25%", textAlign: "center" }}>
                        {row.new_value ? getOrderStatusLabel(row.new_value) :""}
                      </TableCell>
                      <TableCell sx={{ width: "25%", textAlign: "center" }}>
                        <Checkbox
                          checked={row.has_return == true ? true : false}
                          color="primary"
                          name="has_return"
                        />
                      </TableCell>

                      <TableCell sx={{ width: "35%", textAlign: "center" }}>
                        {format(DateApi.getDate(row.state_date || row.transaction_date), "dd-MM-yyyy  HH:mm")}
                      </TableCell>
                      <TableCell sx={{ width: "40%", textAlign: "center" }}>
                        {row.notes}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
                            </Box>
                        
                        </>
                      )}
                    </>
                  )}
              </Scrollbar>
            </Box>
          </Container>
        </Box>
      </Drawer>
    </>
  );
};

export default ContentSearch;
