import { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  Card,
  Grid,
  Autocomplete,
} from "@material-ui/core";
import { orderApi } from "../../../__fakeApi__/orderApi";
import { CustomPermissionApi } from "../../../__fakeApi__/CustomUserPermssionApi";
import useAuth from "../../../hooks/useAuth";
const OrderUpdateModel = (props) => {
  const {
    onApply,
    onClose,
    open,
    order,
    field,
    userpermession,
    handlefilterclicked,
    ...other
  } = props;
  const { user } = useAuth();
  // const [FieldName, setFieldName] = useState(field);
  const [FieldValue, setFieldValue] = useState(
    order[field]
  );
  // const [companyname, setcompanyname] = useState();
  // const [showCustomer, setshowCustomer] = useState(false);
  // const [showFieldValue, setshowFieldValue] = useState(true);

  const handleApply = async () => {
    if (!FieldValue){
      toast.error(
        "Invalid value"
      );
      return;
    }
    try {
      let data = {
        orders: [order.serial],
        FieldName: field,
        FieldValue: FieldValue,
      };
      if (field == "total_amount" && !user.is_superuser) {
        if (userpermession.max_order && FieldValue > userpermession.max_order) {
          toast.error(
            "You can't Add Order more than " + userpermession.max_order
          );
          return;
        }
      }

      let response = await orderApi.OrderbulkUpdate(data);
      if (response.ok) {
        response.json().then((result) => {
          let ExcludeOrders = result.ExcludeOrders;

          if (ExcludeOrders.length > 0) {
            let r = ExcludeOrders.join(",");
            toast.error("this orders not updated " + r);
          } else {
            toast.success("Saved Successfully");
          }
          onApply();
        });
      } else {
        response.json().then((result) => {
          toast.error(result.message);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = async () => {
    try {
      let data = {
        orders: [order],
        FieldName: field,
        FieldValue: FieldValue,
        // companyname:companyname?companyname:''
      };
      onApply(data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Dialog
      onKeyPress={(e) => {
        if (e.charCode == 13) {
          handleApply();
        }
      }}
      maxWidth="lg"
      onClose={onClose}
      open={open}
      {...other}
    >
      <Box sx={{ p: 3, minWidth: 700 }}>
        <Card>
          <Box sx={{ p: 1 }}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={6}>
                <Box
                  sx={{
                    mt: 3,
                    maxWidth: "100%",
                    // width: 200,
                  }}
                >
                  <TextField
                    fullWidth
                    label=""
                    name="ddlFieldName"
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    value={field}
                    readOnly={true}
                    disabled={true}
                    // onChange={(event) => {
                    // if (event) {
                    //     setFieldName(event.target.value) ;
                    //     if (event.target.value=="customer_id")
                    //       { setshowCustomer(true)
                    //         setshowFieldValue(false)
                    //       }
                    //       else
                    //       {
                    //         setshowCustomer(false)
                    //         setshowFieldValue(true)
                    //       }
                    // }
                    // }}
                  >
                    {/* {(userpermession.can_edit_price || user.is_superuser) && ( */}
                    <option key={0} value="total_amount">
                      Total Amount
                    </option>
                    {/* // )} */}
                    {/* {(userpermession.can_edit_price || user.is_superuser) && ( */}
                    <option key={1} value="total_fees">
                      Total Fees
                    </option>
                    {/* )} */}
                    {/* {(userpermession.can_edit_price || user.is_superuser) && ( */}
                    <option key={2} value="shipper_fees">
                    Mandoob Fees
                    </option>
                    {/* )} */}
                    {/* <option key={3} value="notes">
                      Notes
                    </option>
                    <option key={4} value="customer_id">
                      Customer
                    </option> */}
                  </TextField>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box sx={{ mt: 2 }}>
                  {/* {showFieldValue==true ? */}
                  <TextField
                    autoFocus
                    FormHelperTextProps={{
                      sx: {
                        textAlign: "right",
                        mr: 0,
                      },
                    }}
                    fullWidth
                    disabled={
                      !(userpermession.can_edit_price || user.is_superuser)
                    }
                    placeholder="Enter New Value"
                    defaultValue={order[field]}
                    required
                    onChange={(event) => setFieldValue(event.target.value)}
                    variant="outlined"
                  />
                  {/* // :''} */}
                  {/*                    
            {showCustomer==true ?
              <Autocomplete                    
                options={customers}
                getOptionLabel={(option) => option.company_name}   
                isOptionEqualToValue={(option,value)=>option.id===value.id}
                onChange={(event, value) => {            
                  setFieldValue(value.id)
                  setcompanyname(value.company_name)
                }}               
                renderInput={(params) => (
                  <TextField
                    fullWidth                
                    label="Customer"
                    name="ddlCustomerbulkUpdate"
                    variant="outlined"
                    {...params}
                  /> 
                )}
              />:''} */}
                </Box>
              </Grid>
              <Grid item md={2} xs={12}>
                <Box sx={{ mt: 2 }}>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={handleApply}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
              <Grid item md={2} xs={12}>
                <Box sx={{ mt: 2 }}>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={onClose}
                    variant="contained"
                  >
                    Close
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </Dialog>
  );
};

OrderUpdateModel.propTypes = {
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default OrderUpdateModel;
