import React, {useCallback, useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {Box, Breadcrumbs, Button, Container, Grid, Link, TextField, Typography,} from "@material-ui/core";
import useMounted from "../../../hooks/useMounted";
import useSettings from "../../../hooks/useSettings";
import ChevronRightIcon from "../../../icons/ChevronRight";
import RefreshIcon from "../../../icons/Refresh";
import {useTranslation} from "react-i18next";
import AgentCommissionsTable from "./AgentCommissionsTable";
import {agentCommissionsAPI} from "../../../__fakeApi__/AgentCommissionsAPI";
import toast from "react-hot-toast";
import {Autocomplete} from "@material-ui/lab";
import {ShipperUserApi} from "../../../__fakeApi__/ShipperUserApi";
import DownloadIcon from "../../../icons/Download";
import {ExportToExcelApis} from "../../../__fakeApi__/ExportToExcel";
import {format} from "date-fns";
import {DateApi} from "../../../__fakeApi__/DateApi";

const AgentsCommissions = () => {
    const mounted = useMounted();
    const { settings } = useSettings();
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();
    const [dataList, setDataList] = useState([]);
    const [query, setQuery] = useState({
        agent: "", no_of_orders: "", no_of_customers: "",
    });
    const months_list =[{id:"1",month:"يناير"},{id:"2",month:"فبراير"},{id:"3",month:"مارس"},{id:"4",month:"ابريل"},{id:"5",month:"مايو"},{id:"6",month:"يونيو"},{id:"7",month:"يوليو"},{id:"8",month:"اغسطس"},{id:"9",month:"سبتمبر"},{id:"10",month:"اكتوبر"},{id:"11",month:"نوفمبر"},{id:"12",month:"ديسمبر"}]
    const current_year = new Date().getFullYear();
    const { t } = useTranslation();
    const [year, setYear] = useState({id:current_year ,year:current_year});
    const today_date =  new Date()
    const current_month = today_date.getMonth() +1;
    const [month, setMonth] = useState(months_list.find((month) => month.id === current_month.toString()));
    const GetFormList = useCallback(async () => {
        try {
            const data = await ShipperUserApi.getUserList();
            setUsers(data.users || []);
        } catch (err) {
            console.error(err);
        }
    }, [mounted]);

    useEffect(() => {
        GetFormList();
    }, [GetFormList]);

    const handleGetCommissions = (event) => {
        event.preventDefault()
        const agent = user;
        const year = document.getElementById("year").value;
        const orders = document.getElementById("no-of-orders").value;
        const customers = document.getElementById("no-of-customers").value;
        if (!orders && !customers) {
            toast.error('You have to enter either the number of orders or the number of customers to get commissions!');
        } else {
            setQuery({ agent: agent, month: month.id, year: year, no_of_orders: orders, no_of_customers: customers })
        }
    };

    const GetDataList = useCallback(async () => {
        try {
            if (query.no_of_orders || query.no_of_customers) {
                const data = await agentCommissionsAPI.getAgentCommissionsAPI(query);
                setDataList(data || []);
            } else {
                setDataList([]);
            }
        } catch (err) {
            setDataList([]);
            console.error(err);
        }
    }, [mounted, query]);

    useEffect(() => {
        GetDataList();
    }, [GetDataList]);

    const years_list = Array.from({ length: 6 }, (_, index) => {
        return {id: current_year + index,year: current_year - index };
    });

    async function downloadExcel() {
        await ExportToExcelApis.downloadexcel(dataList, 'Agents Calculations Commissions');
    }

    return (
        <>
            <Helmet>
                <title>Dashboard: Agents Commissions Calculations</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "50%",
                    Height: "50%",
                    py: 8,
                }}
            >
                <Container maxWidth={settings.compact ? "xl" : false}>
                    <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item>
                            <Typography color="textPrimary" variant="h5">
                                {t("Agents Commissions Calculations")}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small"/>}
                                sx={{mt: 1}}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard/"
                                    variant="subtitle2"
                                >
                                    {t("Dashboard")}
                                </Link>
                                <Typography color="textSecondary" variant="subtitle2">
                                    {t("Agents Commissions Calculations")}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>

                        <Grid item>
                            <Box sx={{m: -1}}></Box>
                        </Grid>
                        <Grid item>
                            <Box sx={{m: -1}}>
                                <Button
                                    size="small"
                                    color="primary"
                                    startIcon={<RefreshIcon fontSize="small"/>}
                                    sx={{m: 1, backgroundColor: "#DF2F2F"}}
                                    variant="contained"
                                    onClick={(e) => {
                                        window.location.reload();
                                    }}
                                >
                                    {t("Refresh")}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <form onSubmit={handleGetCommissions}>
                        <Box
                            sx={{
                                alignItems: "center",
                                display: "flex",
                                flexWrap: "wrap",
                                m: -1,
                                p: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    minWidth: "300px"
                                    // m: 1,
                                    // maxWidth: "100%",
                                }}
                            >
                                <Autocomplete
                                    size="small"
                                    options={users}
                                    getOptionLabel={(option) =>
                                        `${option.username} | ${option.first_name} ${option.last_name}`
                                    }
                                    onChange={(event, value) => {
                                        if (value !== null) {
                                            setUser(value.id);
                                        } else {
                                            setUser(null);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            id="user"
                                            size="small"
                                            fullWidth
                                            label={t("Set Agent")}
                                            name="user"
                                            value={user}
                                            variant="outlined"
                                            {...params}
                                        />
                                    )}
                                />
                            </Box>
                            <Box
                                sx={{
                                    m: 1,
                                    maxWidth: "30%",
                                    width: 200,
                                    // width: 200,
                                }}
                            >
                                <Autocomplete
                                    id="year"
                                    size="small"
                                    getOptionLabel={(option) => option.year}
                                    options={years_list}
                                    value={year}
                                    onChange={(e, value) => {
                                        setYear(value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label={t("Year")}
                                            name="year"
                                            variant="outlined"
                                            {...params}
                                        />
                                    )}
                                />
                            </Box>
                            <Box
                                sx={{
                                    m: 1,
                                    maxWidth: "30%",
                                    width: 200,
                                    // width: 200,
                                }}
                            >
                                <Autocomplete
                                    id="month"
                                    size="small"
                                    getOptionLabel={(option) => option.month}
                                    options={months_list}
                                    value={month}
                                    onChange={(e, value) => {
                                        console.log(value)
                                        setMonth(value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label={t("Month")}
                                            name="month"
                                            variant="outlined"
                                            {...params}
                                        />
                                    )}
                                />
                            </Box>
                            <Box
                                sx={{
                                    m: 1,
                                    maxWidth: "100%",
                                }}
                            >
                                <TextField
                                    id="no-of-orders"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    label={t("Target No. of Orders")}
                                    variant="outlined"
                                />
                            </Box>
                            <Box
                                sx={{
                                    m: 1,
                                    maxWidth: "100%",
                                }}
                            >
                                <TextField
                                    id="no-of-customers"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    label={t("Target No. of Customers")}
                                    variant="outlined"
                                />
                            </Box>
                            <Box
                                sx={{
                                    m: 1,
                                    maxWidth: "100%",
                                    // width: 240,
                                }}
                            >
                                <Button
                                    type="submit"
                                    color="primary"
                                    title={t('Change Order Shipper to Hub')}
                                    variant="outlined"
                                >
                                    Calculate Commissions
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    my: "auto",
                                }}
                            >
                                <Button
                                    color="primary"
                                    startIcon={<DownloadIcon fontSize="small" />}
                                    sx={{ m: 2 }}
                                    variant="text"
                                    onClick={downloadExcel}
                                >
                                    {t("Export")}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                    <Box sx={{ mt: 3 }}>
                        <AgentCommissionsTable
                            dataList={dataList}
                            query={""}
                            fildata={{}}
                            GetDataList={[]}
                            Crudpermession={[]}
                        />
                    </Box>
                    <h4>Orders Commission Calculation</h4>
                    <h6>- Total Orders * Average Fees * Commission [ average_fees in commissions segment and Total Orders is greater than or equal to Target No. of Orders ]</h6>
                    <h4>Customers Commission Calculation</h4>
                    <h6>- Total Customers * Commission [ ((Total Customers / Target No. of Customers) * 100) in commission segment and Total Customers is greater than or equal to Target No. of Customers ]</h6>
                </Container>
            </Box>
        </>
    );
};

export default AgentsCommissions;