import toast from "react-hot-toast";
import { identifier } from "stylis";
import {
  ServerURL,
  getAccessToken,fetch_instance,
  UserCustomPermissionApiMethodUrl,
  FormDataUserCustomPermissionApiMethodUrl,
  BulkDeleteUserCustomPermissionApiMethodUrl
} from "./ServerConfig";

class custom_permssion_api {

  async get_all_user_permission_list() {
    let obj = {};


    await fetch_instance(`${ServerURL}${UserCustomPermissionApiMethodUrl}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(obj);
  }

  async field_permission_list(id) {
    let obj = {};
    let seeting={};


    await fetch_instance(`${ServerURL}custompermissions/field_permission?id=${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    await fetch_instance(`${ServerURL}settings/get_user_settings`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        seeting = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return {"obj":obj,"seeting":seeting}
  }
  async update_field_permissions(datalist,id) {
    let obj = {};

    await fetch_instance(`${ServerURL}custompermissions/field_permission/${datalist.id}/`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body:JSON.stringify(datalist)
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(obj);
  }
  async save_user_permission(body) {
    let response = {};

    await fetch_instance(`${ServerURL}${UserCustomPermissionApiMethodUrl}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        response = res
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(response);
  }
  async update_user_permission(body) {
    let response = {};

    await fetch_instance(`${ServerURL}${UserCustomPermissionApiMethodUrl}${body.id}/`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),

    })
      .then((res) => {
        response = res
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(response);
  }
  async delete_user_permission(body) {
    let response = {};

    await fetch_instance(`${ServerURL}${UserCustomPermissionApiMethodUrl}${body.id}/`, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),

    })
      .then((res) => {
        response = res
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(response);
  }
  async bulk_delete_user_permission(body) {
    let response = {};

    await fetch_instance(`${ServerURL}${BulkDeleteUserCustomPermissionApiMethodUrl}`, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),

    })
      .then((res) => {
        response = res
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(response);
  }
  async get_user_permission_list(user, codes = []) {
    let obj = {};
    
    await fetch_instance(`${ServerURL}${UserCustomPermissionApiMethodUrl}`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        user: user,
        codes: codes,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(obj);
  }
  async get_form_data() {
    let obj = {};

   
    await fetch_instance(`${ServerURL}${FormDataUserCustomPermissionApiMethodUrl}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString())
      });
    return Promise.resolve(obj);
  }
  
  async getPermitedOptions(user, options) {
    let filterOptions = options;

    try {
      let codes = options.map((e) => e.code);
      const data = await this.get_user_permission_list(user, codes);
      if (data && data.length != 0) {
        let perm_list = [];
        data.map((e) => e.enable && perm_list.push(e.code));
        filterOptions = options.filter((e) => perm_list.includes(e.code));
       
      }
    } catch (err) {
      console.error(err);
    }
    return filterOptions;
  }
  
}
export const CustomPermissionApi = new custom_permssion_api();
