import { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import CircularProgress from "../../CircularProgress";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import
import { useTranslation } from "react-i18next";
import UploadIcon from "../../../icons/Upload";

import Scrollbar from "../../Scrollbar";
import styles from "../Styles";
import toast from "react-hot-toast";
import wait from "../../../utils/wait";
import {archivedOrderApis} from "../../../__fakeApi__/ArchivedOrderApis";

const applyFilters = (row, query, filters) =>
    row.filter((row) => {
      let matches = true;

      if (query && !row.name.toLowerCase().includes(query.toLowerCase())) {
        matches = false;
      }
      return matches;
    });

const applyPagination = (dataList, page, limit) => {
  let result = [];
  if (dataList && dataList.length !== 0) {
    result = dataList.slice(page * limit, page * limit + limit);
  }
  return result;
};

const DatabaseTable = (props) => {
  const {
    dataList,
    setRefresh,
    FilterDate,
    setFilterDate,
    isLoading,
    setLoading,
    companyName,
    setCompanyName,
    setOrderSerial,
    orderSerial,
    ...other
  } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [Value, setValue] = useState(10);
  const [filters, setFilters] = useState({
    name: null,
  });

  const handleSelectAllProducts = (event) => {
    setSelectedRows(event.target.checked ? dataList.map((row) => row.order_serial) : []);
  };

  async function restoreOrder(orderSerial) {
    let response = await archivedOrderApis.restoreOrder(orderSerial);
    if (response.ok) {
      toast.success(response.data);
    } else {
      response.json().then((result) => {
        toast.error(result["detail"]);
      });
    }
  }

  async function restoreOrders() {
    let response = await archivedOrderApis.restoreOrders(selectedRows);
    if (response.ok) {
      toast.success(response.data);
    } else {
      response.json().then((result) => {
        toast.error(result["detail"]);
      });
    }
  }

  const handleSelectOneProduct = (event, rowId) => {
    if (!selectedRows.includes(rowId)) {
      setSelectedRows((prevSelected) => [...prevSelected, rowId]);
    } else {
      setSelectedRows((prevSelected) =>
          prevSelected.filter((order_serial) => order_serial !== rowId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setSelectedRows([]);
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const wait_process = useCallback(async () => {
    if (isLoading) {
      await wait(8);
      setValue((Value + 1) % 100);
    }
  });
  useEffect(() => {
    wait_process();
  }, [Value,isLoading]);

  // Usually query is done on backend with indexing solutions
  const filteredProducts = applyFilters(dataList, query, filters);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);
  const enableBulkActions = selectedRows.length > 0;
  const selectedSomeProducts =
      selectedRows.length > 0 && selectedRows.length < filteredProducts.length;
  const selectedAllProducts = selectedRows.length === filteredProducts.length;

  if (isLoading) {
    return (
        <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 20,
            }}
        >
          <CircularProgress value={Value} />
        </Box>
    );
  }
  return (
      <Card {...other}>
        {enableBulkActions && (
            <Box sx={{ position: "relative" }}>
              <Box
                  sx={{
                    backgroundColor: "background.paper",
                    mt: "6px",
                    pl: "4px",
                    position: "absolute",
                    pr: "4px",
                    width: "100%",
                    zIndex: 2,
                  }}
              >
                <Checkbox
                    checked={selectedAllProducts}
                    color="primary"
                    indeterminate={selectedSomeProducts}
                    onChange={handleSelectAllProducts}
                />
                <Button
                    color="success"
                    sx={{ ml: 2 }}
                    variant="outlined"
                    onClick={restoreOrders}
                >
                  {t("Restore")}
                </Button>
              </Box>
            </Box>
        )}
        <Scrollbar>
          <Box sx={{ minWidth: 1200 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={styles.table_head} padding="checkbox">
                    <Checkbox
                        checked={selectedAllProducts}
                        color="primary"
                        indeterminate={selectedSomeProducts}
                        onChange={handleSelectAllProducts}
                    />
                  </TableCell>
                  <TableCell style={styles.table_head} align="center">{t("Order Serial")}</TableCell>
                  <TableCell style={styles.table_head} align="center">{t("Company Name")}</TableCell>
                  <TableCell style={styles.table_head} align="center">{t("Deleted By")}</TableCell>
                  <TableCell style={styles.table_head} align="center">{t("Deleted At")}</TableCell>
                  <TableCell style={styles.table_head} align="center">{t("Delete Reason")}</TableCell>
                  <TableCell style={styles.table_head} align="center">{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedProducts.length !== 0 &&
                    paginatedProducts.map((row) => {
                      const isProductSelected = selectedRows.includes(row.order_serial);
                      return (
                          <TableRow
                              hover
                              key={row.order_serial}
                              selected={isProductSelected}
                              sx={{ height: 1 }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                  checked={isProductSelected}
                                  color="primary"
                                  onChange={(event) =>
                                      handleSelectOneProduct(event, row.order_serial)
                                  }
                                  value={isProductSelected}
                              />
                            </TableCell>
                            <TableCell align="center">{row.order_serial}</TableCell>
                            <TableCell align="center">{row.company_name}</TableCell>
                            <TableCell align="center">{row.deleted_by}</TableCell>
                            <TableCell align="center">{row.deleted_at}</TableCell>
                            <TableCell align="center">{row.notes}</TableCell>
                            <TableCell align="center">
                              <Button
                                  color="success"
                                  sx={{ ml: 2 }}
                                  variant="contained"
                                  onClick={() => restoreOrder(row.id)}
                                  startIcon={<UploadIcon fontSize="small" />}
                              >
                                {t("Restore")}
                              </Button>
                            </TableCell>
                          </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={filteredProducts.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </Scrollbar>
      </Card>
  );
};

DatabaseTable.propTypes = {
  dataList: PropTypes.array.isRequired,
};

export default DatabaseTable;