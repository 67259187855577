import {ServerURL, getMandoobDebtReportURL, getAccessToken, getAgentCommissionsCalculations} from "./ServerConfig";
import axios from "axios";

class AgentCommissionsAPI {
  async getAgentCommissionsAPI(query) {
    try {
      const response = await axios.get(`${ServerURL}${getAgentCommissionsCalculations}`, {
          headers: { Authorization: `Bearer ${getAccessToken()}` },
          params: query
        },
      );
      console.log('response.data', response.data);
      return response.data;
    } catch (error) {
      console.log('error --> ', error)
      return error;
    }
  }
}
export const agentCommissionsAPI = new AgentCommissionsAPI();