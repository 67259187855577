import React, { useEffect, useState, useCallback, useReducer } from "react";
import useMounted from "../hooks/useMounted";
import { DateApi } from "../__fakeApi__/DateApi";
// import useAuth from "../hooks/useAuth";
// import {reducer} from "./JWTContext";
import { SessionTimeinMinutes } from "../__fakeApi__/ServerConfig";

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    // console.log(action.payload)
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    // console.log("login user " , user)
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
const LogoutPopup = () => {
  const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [signoutTime, setSignoutTime] = useState(3 * 60 * 1000);
  const [warningTime, setWarningTime] = useState(5 * 60 * 1000);

  const [auth, stauth] = useState("");
  const mounted = useMounted();
  const setAuth = useCallback(async () => {
    let data = localStorage.getItem("accessToken");

    stauth(data);
  });

  let warnTimeout;
  let logoutTimeout;

  const warn = () => {
    // console.log("Warning");
  };
  const logout = () => {
    
    if (!localStorage.getItem("accessToken")) {
      window.location.reload();
    }

    // console.log("You have been loged out");
  };

  const destroy = () => {
    // console.log("Session destroyed");
  };

  const setTimeouts = () => {
    if (localStorage.getItem("accessToken")) {
      warnTimeout = setTimeout(warn, warningTime);
      logoutTimeout = setTimeout(logout, signoutTime);
    }
  };

  const clearTimeouts = () => {
    if (localStorage.getItem("accessToken") && localStorage.getItem("date")) {
      let timer = DateApi.getDate(localStorage.getItem("date"));
      //Date.parse(localStorage.getItem('date'))
      let now = DateApi.getDate();

      // if (timer >= now) {
        let time_now = DateApi.getDate();
        time_now.setMinutes(time_now.getMinutes() + SessionTimeinMinutes);
        let datestring = DateApi.getDate(time_now);
        localStorage.setItem("date", datestring);
      
      if (warnTimeout) clearTimeout(warnTimeout);
      if (logoutTimeout) clearTimeout(logoutTimeout);
    }
  };

  useEffect(() => {
    const events = [
      "load",
      "mousewheel",
      // "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
      "DOMMouseScroll",
      "wheel",
    ];
    // stauth(localStorage.getItem('accessToken'));
    // setAuth();
    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  return <div></div>;
};
export default LogoutPopup;
