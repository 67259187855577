import { useState, useEffect, useCallback, useRef } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
// import CustomerRevenueDetailGrid from "./CustomerRevenueDetailGrid";
import useMounted from "../../../hooks/useMounted";
import useSettings from "../../../hooks/useSettings";
import ChevronRightIcon from "../../../icons/ChevronRight";
import DownloadIcon from "../../../icons/Download";
import gtm from "../../../lib/gtm";
import { CustomerRevenueNewApi } from "../../../__fakeApi__/CustomerRevenueNewApi";
import RefreshIcon from "../../../icons/Refresh";
import EyeIcon from "../../../icons/Eye";
import { ExportToExcelApis } from "../../../__fakeApi__/ExportToExcel";
import { useReactToPrint } from "react-to-print";
// import { CustomerRevenueDetailPrint } from "./CustomerRevenueDetailPrint";
import MabdoobOrderDetailsReportTable from "./MabdoobOrderDetailsReportTable";
import { useTranslation } from "react-i18next";
import { DateApi } from "../../../__fakeApi__/DateApi";
import {orderApi} from "../../../__fakeApi__/orderApi";
const applyFilters = (row, query) =>
  row.filter((row) => {
    let matches = true;
    if (
      query &&
      !row.company_name.toLowerCase().includes(query.toLowerCase())
    ) {
      matches = false;
    }
    return matches;
  });

const MandoobOrderDetailsReport = () => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const mounted = useMounted();
  const { settings } = useSettings();
  const [dataList, setDataList] = useState([]);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const state = useParams();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  async function download_excel() {
    // toast.success("File to be downloaded");
    let filterdData = applyFilters(dataList, query);
    let status_dict = {
      1: t("Pending"),
      2: t("Out For Delivery"),
      3: t("Delivered"),
      4: t("Hold"),
      5: t("Undelivered"),
      6: t("Rejected"),
    };
    filterdData = filterdData.map((x) => {
      x.order_date = format(DateApi.getDate(x.order_date), "yyyy-MM-dd");
      x.Order_Delivery_Status = status_dict[x.Order_Delivery_Status];
      x.Collect_date = x.Collect_status_date;
      x.Return_date = x.returned_Client_date;
      // x.Status_Note=x.StatusNote

      delete x["customer_name"];
      delete x["shipper_id"];
      delete x["Order_Collect_Status"];
      delete x["Returned_Client"];
      delete x["MoneyCashed"];
      delete x["day_date"];
      delete x["Collect_status_date"];
      delete x["returned_Client_date"];
      // delete x["StatusNote"];

      return x;
    });
    await ExportToExcelApis.downloadexcel(
      filterdData,
      `Revenue Report${format(DateApi.getDate(), "yyMMddHHmmss").toString()}`
    );
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const GetDataList = useCallback(async () => {
    try {
      if (state.shipper_id && state.day_date) {
        const data = await orderApi.getShipperOrdersDetails(state.shipper_id, state.day_date);
        if (data) {
          setDataList(data || []);
        } else {
          setDataList([]);
        }
      } else {
        setDataList([]);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted, state]);

  useEffect(() => {
    GetDataList();
  }, [GetDataList]);
  console.log('dataList --> ', dataList)
  return (
    <>
      <Helmet>
        <title>
          {t("Dashboard")}: {t("Mandoob Orders Details")}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "50%",
          Height: "50%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("Mandoob Orders Details Report")}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/"
                  variant="subtitle2"
                >
                  {t("Dashboard")}
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  {t("Mandoob Orders Details Report")}
                </Typography>
              </Breadcrumbs>
              <Box
                sx={{
                  mb: -1,
                  mx: -1,
                  mt: 1,
                }}
              >
                {/*<Button*/}
                {/*  color="primary"*/}
                {/*  startIcon={<DownloadIcon fontSize="small" />}*/}
                {/*  sx={{ m: 2 }}*/}
                {/*  variant="text"*/}
                {/*  onClick={handlePrint}*/}
                {/*>*/}
                {/*  {t("Print")}*/}
                {/*</Button>*/}
                <Button
                  color="primary"
                  startIcon={<DownloadIcon fontSize="small" />}
                  sx={{ m: 2 }}
                  variant="text"
                  onClick={download_excel}
                >
                  {t("Export")}
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                <h3 style={{ "text-align": "center" }}>
                  {dataList?.[0]?.shipper} ({state.day_date})
                </h3>
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}></Box>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                {/*<Button*/}
                {/*  color="success"*/}
                {/*  startIcon={<EyeIcon fontSize="small" />}*/}
                {/*  sx={{ m: 1 }}*/}
                {/*  variant="contained"*/}
                {/*  // onClick={GetDataList}*/}
                {/*  onClick={(e) => {*/}
                {/*    let company_name = dataList.length*/}
                {/*      ? dataList[0].company_name*/}
                {/*      : "";*/}
                {/*    window.open(*/}
                {/*      `/CollectGallery/${state.customer}/${company_name}/${state.day_date}`,*/}
                {/*      "_blank",*/}
                {/*      "status=no,location=no"*/}
                {/*    );*/}
                {/*  }}*/}
                {/*>*/}
                {/*  {t("Show Attachment")}*/}
                {/*</Button>*/}
                <Button
                  color="primary"
                  //   component={RouterLink}
                  startIcon={<RefreshIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  // onClick={GetDataList}
                  onClick={(e) => window.location.reload()}
                >
                  {t("Refresh")}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <MabdoobOrderDetailsReportTable dataList={dataList} query={query} date={state.day_date} />
          </Box>
        </Container>
        <div hidden>
          {/*<CustomerRevenueDetailPrint ref={componentRef} dataList={dataList} />*/}
        </div>
      </Box>
    </>
  );
};

export default MandoobOrderDetailsReport;
