import {
  ServerURL,
  getAccessToken,
  fetch_instance,
  CustomerCategory,
  CustomerCategoryFilter,
} from "./ServerConfig";
import axiosInstance from "../components/qpexpress/axios";
import toast from "react-hot-toast";

class CustomerCategoryApis {
  async get_category_list() {
    let obj = [];
    await fetch_instance(`${ServerURL}${CustomerCategory}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async get_category_filter() {
    let obj = [];
    await fetch_instance(`${ServerURL}${CustomerCategoryFilter}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async get_category_obj(id) {
    let obj = {};
    await fetch_instance(`${ServerURL}${CustomerCategory}${id}/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async new_category(obj = {}) {
    await fetch_instance(`${ServerURL}${CustomerCategory}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  async update_category(obj = {}) {
    await fetch_instance(`${ServerURL}${CustomerCategory}${obj.id}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).catch((error) => {
      toast.error(error.toString());
    });
  }
  async delete_category(id) {
    await fetch_instance(`${ServerURL}${CustomerCategory}${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }).catch((error) => {
      toast.error(error.toString());
      console.log(error);
    });
  }
}
export const CustomerCategoryApi = new CustomerCategoryApis();
