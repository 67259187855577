import toast from "react-hot-toast";
import {
  getAccessToken,
  fetch_instance,
  ServerURL,
  WithdrawalUrl,
} from "./ServerConfig";

class withdrawal_api {
  async getById(id) {
    let response = [];
    await fetch_instance(`${ServerURL}${WithdrawalUrl}${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        response = data;
        return Promise.resolve(response);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(response);
  }

  async get(searchParams) {
    let response = [];
    await fetch_instance(
      `${ServerURL}${WithdrawalUrl}?fromDate=${searchParams.fromDate}&toDate=${searchParams.toDate}&serial=${searchParams.serial}&amount=${searchParams.amount}&treasury=${searchParams.treasury}&agent=${searchParams.agent}&receiverType=${searchParams.receiverType}&shipper=${searchParams.shipper}&employee=${searchParams.employee}&expense_type=${searchParams.expenseType}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        response = data;
        return Promise.resolve(response);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(response);
  }

  async create(payload) {
    let response = {};
    await fetch_instance(`${ServerURL}${WithdrawalUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((res) => {
      response = res;
    });
    return Promise.resolve(response);
  }
}

export const WithdrawalApi = new withdrawal_api();
