import toast from "react-hot-toast";
import {fetch_instance, getAccessToken, ServerURL,} from "./ServerConfig";

class TreasuryUserAPI {
    async userTreasuryList() {
        let obj = {};
        await fetch_instance(`${ServerURL}custompermissions/treasury_user_permission/user_treasury_list`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getAccessToken()}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                obj = data;
                // return Promise.resolve(obj)
            })
            .catch((error) => {
                toast.error(error.toString())
            });
        return Promise.resolve(obj);
    }

    async getUserTreasuryPermissions(user) {
        let obj = {};
        await fetch_instance(`${ServerURL}custompermissions/treasury_user_permission/user_permissions/?user=${user}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getAccessToken()}`,
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                obj = data;
            })
            .catch((error) => {
                toast.error(error.toString())
                console.log(error);
            });
        return Promise.resolve(obj);
    }

    async save_permissions(obj) {
        let result = {};
        await fetch_instance(`${ServerURL}custompermissions/treasury_user_permission/save_user_permissions/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getAccessToken()}`,
            },
            body: JSON.stringify(obj),
        }).then((response) => {
            result = response;
        });
        return Promise.resolve(result);
    }
}

export const treasuryUserAPI = new TreasuryUserAPI();