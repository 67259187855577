import toast from "react-hot-toast";
import { identifier } from "stylis";
import useAuth from "../hooks/useAuth";
import {
  ServerURL,
  getAccessToken,
  fetch_instance,
  getTop10CompanyOrdersApiMethodUrl,
  getTop10CitiesOrdersApiMethodUrl,
  getOrdersCountApiMethodUrl,
  getopenIssueCountApiMethodUrl,
  getTodaysOrderCountApiMethodUrl,
  getTopLessCustomerRevenueApiMethodUrl,
  getTopLessShipperEvaluationApiMethodUrl,
  getTopLessShipperRevenueApiMethodUrl,
  TIMEZONE,
} from "./ServerConfig";

class date_api {
  async getServerDate(format = "") {
    let obj = {};
    // await fetch_instance(`http://localhost:8000/customization/get_top_company_orders`, {
    await fetch_instance(
      `${ServerURL}customization/getServerDate?query=${format || ""}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;

        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }
  toISOString(s) {
    let months = {
      jan: "01",
      feb: "02",
      mar: "03",
      apr: "04",
      may: "05",
      jun: "06",
      jul: "07",
      aug: "08",
      sep: "09",
      oct: "10",
      nov: "11",
      dec: "12",
    };
    let b = s.split(" ");

    return (
      b[3] +
      "-" +
      months[b[1].toLowerCase()] +
      "-" +
      ("0" + b[2]).slice(-2) +
      "T" +
      b[4] +
      b[5].substr(3)
    );
  }
  getDate(incoming_date = new Date()) {
    let result = new Date(Date.parse(incoming_date))
    return result
  }
}
export const DateApi = new date_api();
