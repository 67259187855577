import { useState, useCallback, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import useMounted from "../../../hooks/useMounted";
import useSettings from "../../../hooks/useSettings";
import ChevronRightIcon from "../../../icons/ChevronRight";
import gtm from "../../../lib/gtm";
import { holdReasonApi } from "../../../__fakeApi__/HoldReasonApi";
import { CustomerCategoryApi } from "../../../__fakeApi__/CustomerCategoryApi";
import ShipperCategoryForm from "./ShipperCategoryForm";
import { useTranslation } from "react-i18next";
import {shipperCategoryApi} from "../../../__fakeApi__/ShipperCategoryApi";
const HoldNew = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [Data, setData] = useState({});
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Dashboard: New Mandoob Category</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("New Mandoob Group")}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/"
                  variant="subtitle2"
                >
                  {t("Dashboard")}
                </Link>

                <Typography color="textSecondary" variant="subtitle2">
                  {t("New Mandoob Group")}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box mt={3}>
            <ShipperCategoryForm
              data={Data}
              SaveFun={shipperCategoryApi.new_category}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default HoldNew;
