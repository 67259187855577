import {
  ServerURL,
  getAccessToken,
  GetOrderDeliverStatusMethodUrl,
  DeleteOrderDeliverStatusMethodUrl,
  GetOrderHistoryMethodUrl,
  GetOrderShipperHistoryMethodUrl,
  fetch_instance,
} from "./ServerConfig";
import toast from "react-hot-toast";

class order_status_api {
  async get_order_status_list(order_id) {
    let result = [];
    await fetch_instance(
      `${ServerURL}${GetOrderDeliverStatusMethodUrl}?id=${order_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        result = data;
        return Promise.resolve(result);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(result);
  }
  async delete_order_status(id) {
    let result = {};
    await fetch_instance(`${ServerURL}${DeleteOrderDeliverStatusMethodUrl}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => {
        result = response;
        return Promise.resolve(response);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(result);
  }
  async get_order_history(order_id) {
    let result = [];
    await fetch_instance(
      `${ServerURL}${GetOrderHistoryMethodUrl}?id=${order_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data)
        result = data;
        return Promise.resolve(result);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(result);
  }
  async get_order_shipper_history(order_id) {
    let result = [];
    await fetch_instance(
      `${ServerURL}${GetOrderShipperHistoryMethodUrl}?id=${order_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        result = data;
        return Promise.resolve(result);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(result);
  }
}
export const OrderStatusApi = new order_status_api();
