import { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import  ChatAlt from "../../icons/ChatAlt";
import ArrowRightIcon from "../../icons/ArrowRight";
import { SocketHost } from "../../__fakeApi__/ServerConfig";
import { w3cwebsocket as WebSocket } from 'websocket';
import { NotificationsApi } from "../../__fakeApi__/NotificationsApi";
import useMounted from "../../hooks/useMounted";
import useAuth from "../../hooks/useAuth";
import ScrollBar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";


const CustomerIssueNotifications = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(
    notifications.length
  );
  const { t } = useTranslation();
  const { user } = useAuth();

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [read_all, setReadAll] = useState(false);

  
  // notificationType for customer issues
  const notificationType = "2"
  const getIssuesNotifications = useCallback(async () => {
    // Using Fixed data for test cycle
    const data = await NotificationsApi.get_notifications(user.id, notificationType);
    console.log(">>>>>>>>>>>>>>>> getCustomerIssues", data)
    setNotifications(data.length ? data : []);
    setNotificationCount(data.filter((x) => !x.is_read).length);
    setCount(Math.ceil(parseFloat(notifications.length / 10, 10)));
  }, []);

    // Function to append data to notifications, update notificationCount, and increment count
    const updateNotification = (newNotification={}, action="Increment") => {
      if (action === "Increment" && newNotification) {
        console.log(">>>> Increment");
        setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
        setNotificationCount((prevCount) => prevCount + 1);
        setCount((prevCount) => prevCount + 1);
      } else if (action === "Decreased") {
        console.log(">>>> Decreased");
        setNotificationCount((prevCount) => prevCount - 1);
        setCount((prevCount) => prevCount - 1);
      } else {
        console.log(">>>> read all");
        setNotificationCount(0);
        setCount(0);
      }
    };

useEffect(() => {
    // Create a WebSocket connection
    // Replace with your actual WebSocket URL
    getIssuesNotifications();
    console.log(`>>>>>>room_group_name: operation_user_issues_${user.id}`)
    const ws = new WebSocket(`${SocketHost}/ws/issue/${user.id}/`);

    // Open WebSocket connection
    ws.onopen = () => {
        console.log('WebSocket connection opened.');
      };
    

    // Handle incoming WebSocket messages
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.message.issue) {
        const issue = JSON.parse(message.message.issue);
        console.log(">>>>>>> issue", issue)
        updateNotification(issue, "Increment")
     }
      // Process and display the notification message
    };

    ws.onclose = () => {
        console.log('WebSocket connection closed.');
    };

    // Close WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, []);
  

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

function mark_all_as_read(event) {
  const data = NotificationsApi.mark_all_as_read(user.id);
  setReadAll(true);
  updateNotification({}, "")
  handleClose();
}

async function mark_as_read(id) {
  const data = await NotificationsApi.mark_as_read(user.id, [id]);
  updateNotification({}, "Decreased")
}

const applyPagination = (data, page, limit) =>
  data.slice(page * limit, page * limit + limit);

const handlePageChange = (event) => {
  let value = parseInt(event.target.textContent, 10) - 1;
  setPage(value);
};

const paginated = applyPagination(notifications, page, 10);

  return (
    <>
      <Tooltip title={t("Customer Issue Notifications")}>
        <IconButton color="inherit" ref={anchorRef} onClick={handleOpen}>
          <Badge color="error" badgeContent={notificationCount}>
            {/* Chnage this ison */}
            <ChatAlt fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 500, maxHeight: 600 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            {t("Customer Issue Notifications")}
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              {t("There are no Customer Issue Notifications")}
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Pagination
                count={count}
                size="small"
                onChange={handlePageChange}
              />
            </Box>

            <ScrollBar>
              <List disablePadding sx={{ height: 400 }}>
                {paginated.map((notification) => {
                  // const Icon = iconsMap[notification.type];
                  const Icon = ChatAlt;

                  return (
                    <ListItem divider key={notification.id}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: "primary.main",
                            color: "primary.contrastText",
                          }}
                        >
                          <Icon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Link
                            color="textPrimary"
                            sx={{ cursor: "pointer" }}
                            underline="none"
                            variant="subtitle2"
                          >
                            {notification.subject}
                          </Link>
                        }
                        secondary={notification.content}
                      />

                      <IconButton
                        edge="end"
                        sx={{
                          backgroundColor: "primary.main",
                          color: "primary.contrastText",
                        }}
                        onClick={async () => {
                          await mark_as_read(notification.id);
                          navigate(notification.url || "", {
                            state: {
                              id: notification.realated_entity_id,
                            },
                          });
                          handleClose();
                        }}
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </ListItem>
                  );
                })}
              </List>
            </ScrollBar>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={mark_all_as_read}
              >
                {t("Mark all as read")}
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default CustomerIssueNotifications;
