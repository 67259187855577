import { subDays, subHours, subMinutes, subSeconds } from "date-fns";
import {
  ServerURL,
  getAccessToken,
  fetch_instance,
  TopCompaniesByOrderReportApiMethodUrl,
  CustomerCollectDelieverdApiMethodUrl,
  CustomerReturnUnDelieverdApiMethodUrl,
  getCustomerFilterListApiMethodUrl,
  CityCrudPermissions,
  ShipperCrudPermissions,
  UserCrudPermissions,
  ZoneCrudPermissions,
  AreaCrudPermissions,
  RemovePasswordForCustomer,
  UpdatePasswordForCustomer,
  CreatePasswordForCustomer,
  CheckCustomerPasswordForCustomer,
  CheckCustomerHasPasswordForCustomer,
  CustomerCollectDelieverdBulkApiMethodUrl,
  getCustomersFilterListApiMethodUrl,
  getAllUserCustomerPermissionMethodURL,
  getCustomerMaterialRequestMethodURL,
  getCustomerCollectMethodURL,
  getCustomerPickupsMethodURL,
  getCustomerCollectTodayMethodURL,
  GetCustomerHistoryMethodUrl,
  UnactiveCustomerReportDetailsMethodURL,
  ActiveCustomersMethodURL,
  GetPricePlanPermissionMethodURL
} from "./ServerConfig";
import toast from "react-hot-toast";
import { DateApi } from "./DateApi";
// import axiosInstance, {axiosAPI} from "../components/qpexpress/axios";
import axios from "axios";
const now = DateApi.getDate();


// const axiosAPI = axios.create({
//   baseURL:ServerURL,
//   timeout: 5000,
//   headers: {
//       Authorization: `Bearer ${getAccessToken()}`,
//       'Content-Type': 'application/json',
// 	},
// });
const axiosAPI = axios.create({
  baseURL: ServerURL,
});

axiosAPI.interceptors.request.use(
    async (request) => {
    request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    request.headers['Content-Type'] = 'application/json';
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

class CustomerApi {
  async get_customer_history(customer_id) {
    let result = [];
    await fetch_instance(
      `${ServerURL}${GetCustomerHistoryMethodUrl}?id=${customer_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        result = data;
        return Promise.resolve(result);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(result);
  }
  async getCustomers(
    page = 1,
    limit = 10,
    query = "",
    customer_id = "",
    CustomerCategory = "",
    autoCollect = "",
    autoPickup = "",
    HasReturnPrice = "",
    agent="",
    csagent="",
    from_date = "",
    to_date = "",
    hasGroupNumber = "",
    isActive = "",
  ) {
    let customer = [];
    const hr = window.location.href;
    // if (next == 1) {
    let next = `${ServerURL}customers/?page=${page}&page_size=${
      limit || 10
    }&query=${encodeURIComponent(query) || ""}&customer_id=${
      customer_id || ""
    }&CustomerCategory=${CustomerCategory || ""}&autoCollect=${
      autoCollect || ""
    }&autoPickup=${autoPickup || ""}&HasReturnPrice=${HasReturnPrice || ""}&agent=${
      agent || ""}&from_date=${from_date}&to_date=${to_date}&csagent=${csagent || ""}&hasGroupNumber=${
      hasGroupNumber || ""}&isActive=${isActive}`;
    // }


    await fetch_instance(next, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((customers) => {
        customer = customers;
      });

    return Promise.resolve(customer);
  }
  async getCustomersFilter() {
    let customer = [];
    // if (next == 1){
    let next = `${ServerURL}${getCustomerFilterListApiMethodUrl}`;
    // }

    // http://localhost:8000/customers/?limit=100&offset=0
    //  alert(next)
    await fetch_instance(next, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((customers) => {
        customer = customers;
      });
    return Promise.resolve(customer);
  }

  async getCustomer(id, is_form = true) {
    let customer = [];
    let areas = [];
    let government = [];
    let shippers = [];
    await fetch_instance(`${ServerURL}customer/get_update_customer?id=${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    .then((res) => res.json())
    .then((customers) => {
      customer = customers;
    }).catch((error) => {
      console.log("error", error);
    });

    if (is_form) {
      await fetch_instance(`${ServerURL}get-area/`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
        .then((res) => res.json())
        .then((city) => {
          areas = city;
        })
        .catch((error) => {
          console.log("error", error);
        });

      await fetch_instance(`${ServerURL}locations/city/`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
        .then((res) => res.json())
        .then((gov) => {
          government = gov;
        });
      await fetch_instance(`${ServerURL}shipper/`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
        .then((res) => res.json())
        .then((city) => {
          shippers = city;
        });
    }
    return {
      customer: customer,
      areas: areas,
      government: government,
      shippers: shippers,
    };
  }

  getCustomerEmails() {
    const emails = [
      {
        id: "5ece2ce3613486d95ffaea58",
        createdAt: subDays(subHours(subMinutes(now, 34), 5), 3).getTime(),
        description: "Order confirmation",
      },
      {
        id: "5ece2ce8cebf7ad1d100c0cd",
        createdAt: subDays(subHours(subMinutes(now, 49), 11), 4).getTime(),
        description: "Order confirmation",
      },
    ];

    return Promise.resolve(emails);
  }

  async getCustomerReportSummary() {
    const url = `${ServerURL}customers/customer-report-summary`
    let payload = {}

    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        payload = data;
      });

    return Promise.resolve(payload);
  }

  getCustomerInvoices() {
    const invoices = [
      {
        id: "5ece2cef3e562cbd61996259",
        currency: "$",
        description: "Freelancer Subscription (12/05/2019 - 11/06/2019)",
        issueDate: now.getTime(),
        paymentMethod: "Credit Card",
        status: "paid",
        value: 5.25,
      },
      {
        id: "5ece2cf461b9484866f2968c",
        currency: "$",
        description: "Freelancer Subscription (12/05/2019 - 11/06/2019)",
        issueDate: now.getTime(),
        paymentMethod: "Credit Card",
        status: "paid",
        value: 5.25,
      },
    ];

    return Promise.resolve(invoices);
  }

  getCustomerLogs() {
    const logs = [
      {
        id: "5ece2cfeb6e2ac847bba11ce",
        createdAt: subDays(subMinutes(subSeconds(now, 56), 2), 2).getTime(),
        description: "Purchase",
        ip: "84.234.243.42",
        method: "POST",
        route: "/__fakeApi__/purchase",
        status: 200,
      },
      {
        id: "5ece2d02510484b2952e1e05",
        createdAt: subDays(subMinutes(subSeconds(now, 56), 2), 2).getTime(),
        description: "Purchase",
        ip: "84.234.243.42",
        method: "POST",
        route: "/__fakeApi__/purchase",
        status: 522,
      },
      {
        id: "5ece2d08e2748e4e9788901a",
        createdAt: subDays(subMinutes(subSeconds(now, 23), 8), 2).getTime(),
        description: "Cart remove",
        ip: "84.234.243.42",
        method: "DELETE",
        route: "/__fakeApi__/products/d65654e/remove",
        status: 200,
      },
      {
        id: "5ece2d0c47214e342c2d7f28",
        createdAt: subDays(subMinutes(subSeconds(now, 54), 20), 2).getTime(),
        description: "Cart add",
        ip: "84.234.243.42",
        method: "GET",
        route: "/__fakeApi__/products/d65654e/add",
        status: 200,
      },
      {
        id: "5ece2d11e4060a97b2b57623",
        createdAt: subDays(subMinutes(subSeconds(now, 16), 34), 2).getTime(),
        description: "Cart add",
        ip: "84.234.243.42",
        method: "GET",
        route: "/__fakeApi__/products/c85727f/add",
        status: 200,
      },
      {
        id: "5ece2d16cf6d53d8e33656af",
        createdAt: subDays(subMinutes(subSeconds(now, 30), 54), 2).getTime(),
        description: "View product",
        ip: "84.234.243.42",
        method: "GET",
        route: "/__fakeApi__/products/c85727f",
        status: 200,
      },
      {
        id: "5ece2d1b2ec5071be9286a96",
        createdAt: subDays(subMinutes(subSeconds(now, 40), 56), 2).getTime(),
        description: "Get products",
        ip: "84.234.243.42",
        method: "GET",
        route: "/__fakeApi__/products",
        status: 200,
      },
      {
        id: "5ece2d22e68d5498917e47bc",
        createdAt: subDays(subMinutes(subSeconds(now, 5), 57), 2).getTime(),
        description: "Login",
        ip: "84.234.243.42",
        method: "POST",
        route: "/__fakeApi__/authentication/login",
        status: 200,
      },
    ];

    return Promise.resolve(logs);
  }
  CreateCustomer() {}
  async getCity() {
    let cities = [];
    await fetch_instance(`${ServerURL}locations/city/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        cities = city;
      });
    return cities;
  }
  // async getArea(){
  //      let cities=[]
  //     await fetch_instance('http://127.0.0.1:8000/get-area',{
  //       headers:{
  //         Authorization:`Bearer ${getAccessToken()}`
  //       }
  //       }).then((res) => res.json()).then((city)=>{ cities = city })
  //   return cities
  // }
  async getArea() {
    let areas = [];
    let cities = [];
    let customers = [];
    let shippers = [];
    await fetch_instance(`${ServerURL}get-area/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        areas = city;
      });

    await fetch_instance(`${ServerURL}locations/city/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        cities = city;
      });

    await fetch_instance(`${ServerURL}customer/get_customer`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        customers = city;
      })
      .catch((ex) => {});

    await fetch_instance(`${ServerURL}shipper/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        shippers = city;
      });
    return {
      areas: areas,
      cities: cities,
      customers: customers,
      shippers: shippers,
    };
  }
  async clientshipmentinfo(user_id = null, c = null, city_id=null) {
    let shipmentInfo = [];
    let cities = [];
    let shippers = [];
    //  const cusid=id;
    await fetch_instance(
      `${ServerURL}ShipmentInfoList/?q=${user_id}&c=${c || ""}&city_id=${city_id || ""}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((city) => {
        shipmentInfo = city;
      });

    await fetch_instance(`${ServerURL}shipper/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        shippers = city;
      });

    await fetch_instance(`${ServerURL}locations/city/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        cities = city;
      });

    let customer = this.getCustomer(user_id);

    return { shipmentInfo: shipmentInfo, shippers: shippers, cities: cities };
  }
  // async getCustomerWallet(userid){
  //     const orders=[];
  //     return orders
  // }
  async getCustomerOrders(id) {
    let orders = [];
    let client_wallet = 0;
    await fetch_instance(
      `${ServerURL}addorders/get_customer_orders/?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((city) => {
        orders = city;
      });
    for (let i = 0; i < orders.length; i++) {
      client_wallet +=
        parseFloat(orders[i].total_amount || 0) -
        parseFloat(orders[i].total_fees || 0);
    }
    if (isNaN(client_wallet)) client_wallet = 0;
    return { orders: orders, client_wallet: client_wallet };
  }
  async getagentorders(id) {
    let orders = [];
    let client_wallet = 0;
    await fetch_instance(
      `${ServerURL}customers/get_agent_commission?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((order) => {
        orders = order;
      });
    return orders;
  }

  async customer_collect_Deliverd(body) {
    let obj = {};
    await fetch_instance(
      `${ServerURL}${CustomerCollectDelieverdApiMethodUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response;
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async customer_collect_Deliverd_bulk(body) {
    let obj = {};
    await fetch_instance(
      `${ServerURL}${CustomerCollectDelieverdBulkApiMethodUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response;
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async getCustomersFilters() {
    // getCustomersFilterListApiMethodUrl
    let obj = {};
    await fetch_instance(`${ServerURL}${getCustomersFilterListApiMethodUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async MaterialRequest(body, id) {
    let obj = {};
    await fetch_instance(`${ServerURL}addorders/material_requst_detail/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async customer_return_unDeliverd(body) {
    let obj = {};
    await fetch_instance(
      `${ServerURL}${CustomerReturnUnDelieverdApiMethodUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response;
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async getCustomePickup(id) {
    let obj = {};
    await fetch_instance(`${ServerURL}addorders/today_pickup?id=${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        obj = city;
      });
    return Promise.resolve(obj);
  }
  async get_customer_returns_for_request() {
    let obj = {};
    await fetch_instance(
      `${ServerURL}addorders/get_customer_returns_for_request`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((city) => {
        obj = city;
      });
    return Promise.resolve(obj);
  }
  async get_customer_returns_for_request_details() {
    let obj = {};
    await fetch_instance(
      `${ServerURL}addorders/get_customer_returns_for_request_details`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((city) => {
        obj = city;
      });
    return Promise.resolve(obj);
  }
  async getTotalCollected(id, from_date = "", to_date = "") {
    let obj = {};
    await fetch_instance(
      `${ServerURL}addorders/get_total_collected?id=${id}&from_date=${from_date}&to_date=${to_date}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((city) => {
        obj = city;
      });
    return Promise.resolve(obj);
  }
  async get_top_cities(id, fromdate, todate) {
    let obj = {};
    let url = `addorders/get_top_cities?id=${id}`;
    if (fromdate && todate) {
      url = `addorders/get_top_cities?id=${id}&from_date=${fromdate}&to_date=${todate}`;
    }
    await fetch_instance(`${ServerURL}${url}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        obj = city;
      });
    return Promise.resolve(obj);
  }
  async get_order_staticts(id, fromdate, todate) {
    let obj = {};
    let url = `addorders/get_orders_status?id=${id}`;
    if (fromdate && todate) {
      url = `addorders/get_orders_status?id=${id}&from_date=${fromdate}&to_date=${todate}`;
    }
    await fetch_instance(`${ServerURL}${url}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        obj = city;
      });

    return Promise.resolve(obj);
  }
  async get_ustomer_yesterday_orders(id) {
    let obj = {};
    await fetch_instance(
      `${ServerURL}addorders/get_yesterday_orders?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((city) => {
        obj = city;
      });
    return Promise.resolve(obj);
  }
  async get_customers_whatsapp(id) {
    let obj = {};
    await fetch_instance(`${ServerURL}addorders/get_customers`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        obj = city;
      });
    return Promise.resolve(obj);
  }
  ///// crud permissions apis
  async getCrudPermission() {
    let contacts = [];
    await fetch_instance(`${ServerURL}customers/get_CrudPermission`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }
  async cityCrudPermission() {
    let contacts = [];
    await fetch_instance(`${ServerURL}${CityCrudPermissions}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }
  async zoneCrudPermission() {
    let contacts = [];
    await fetch_instance(`${ServerURL}${ZoneCrudPermissions}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }
  async AreaCrudPermission() {
    let contacts = [];
    await fetch_instance(`${ServerURL}${AreaCrudPermissions}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }
  async ShipperCrudPermission() {
    let contacts = [];
    await fetch_instance(`${ServerURL}${ShipperCrudPermissions}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }
  async UserCrudPermission() {
    let contacts = [];
    await fetch_instance(`${ServerURL}${UserCrudPermissions}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }

  async create_customer_password(body) {
    let obj = {};
    await fetch_instance(`${ServerURL}${CreatePasswordForCustomer}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        obj = response;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async unactive_customer_report(body) {
    let obj = {};
    await fetch_instance(
      `${ServerURL}${UnactiveCustomerReportDetailsMethodURL}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        // obj = response;
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async deleted_orders_report(body) {
    let obj = {};
    await fetch_instance(
      `${ServerURL}addorders/deleted_orders_report`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        }
      }
    )
      .then((response) => {
        // obj = response;
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async active_customers(body) {
    let obj = {};
    await fetch_instance(`${ServerURL}${ActiveCustomersMethodURL}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        obj = response;
        return response;
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async remove_customer_password(body) {
    let obj = {};
    await fetch_instance(`${ServerURL}${RemovePasswordForCustomer}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        obj = response;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async update_customer_password(body) {
    let obj = {};
    await fetch_instance(`${ServerURL}${UpdatePasswordForCustomer}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        obj = response;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async check_customer_password(body) {
    let obj = {};
    await fetch_instance(`${ServerURL}${CheckCustomerPasswordForCustomer}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        obj = response;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async check_customer_has_password() {
    let obj = {};
    await fetch_instance(`${ServerURL}${CheckCustomerHasPasswordForCustomer}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async getAllUserCustomerPermission(customers = [], user = "") {
    let obj = {};
    let customers_params = "";
    if (customers.length > 0) {
      customers_params = encodeURIComponent(JSON.stringify(customers || []));
    }

    // let parameters = `&customers=${customers_params || []}&user=${user || ''}`;
    let parameters = `&customers=${customers_params || []}&user=${user || ""}`;
    await fetch_instance(
      `${ServerURL}${getAllUserCustomerPermissionMethodURL}?${parameters}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          // "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async getCustomerPickups(from_date = "", to_date = "") {
    let obj = {};
    let parameters = "";
    parameters += `&from_date=${from_date}`;
    parameters += `&to_date=${to_date}`;
    await fetch_instance(
      `${ServerURL}${getCustomerPickupsMethodURL}?${parameters}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          // "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async getPaymentMethods() {
    let obj = {};
    await fetch_instance(
      `${ServerURL}customers/payment-method-choices/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          // "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async getbanks() {
    let obj = {};
    await fetch_instance(
      `${ServerURL}customers/bank`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          // "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async getCustomerCollect(from_date = "", to_date = "") {
    let obj = {};
    let parameters = "";
    parameters += `&from_date=${from_date}`;
    parameters += `&to_date=${to_date}`;
    await fetch_instance(
      `${ServerURL}${getCustomerCollectMethodURL}?${parameters}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          // "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async getCustomerCollectToday() {
    let obj = {};

    await fetch_instance(`${ServerURL}${getCustomerCollectTodayMethodURL}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        // "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async getCustomerMaterialRequest(from_date = "", to_date = "") {
    let obj = {};
    let parameters = "";
    parameters += `&from_date=${from_date}`;
    parameters += `&to_date=${to_date}`;
    await fetch_instance(
      `${ServerURL}${getCustomerMaterialRequestMethodURL}?${parameters}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          // "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async getcustomershipmenttype(id) {
    let obj = {};

    await fetch_instance(`${ServerURL}customers/get_customer_shipment_type?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        // "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async getcustomershipmentfees(id) {
    let obj = {};

    await fetch_instance(`${ServerURL}customers/get_customer_shipment_fees?customer_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        // "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async getCustomerDebtReport(companyid="",collectdays="",minimumvalue="",maximumvalue="",fromDate="", toDate="", paymentMethod="") {
    let obj = {};
    let url = `${ServerURL}customers/customerdebt_report?companyid=${companyid}&collectdays=${collectdays}&minimumvalue=${minimumvalue}&maximumvalue=${maximumvalue}&todate=${toDate}&fromdate=${fromDate}&payment_method=${paymentMethod}`
    await fetch_instance(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        // "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async getCustomerDebtOrdersDetailsReport(customerID) {
    let obj = {};
    let url = `${ServerURL}customers/customer_debt_orders_details_report?customer_id=${customerID}`
    await fetch_instance(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        // "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        console.log(obj)
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  // async applyCollectClient(body) {
  //     return axiosAPI.post('customers/apply_collected_clients_on_orders', body)
  // }

  async getPricePlanPermission() {
    let contacts = [];
    await fetch_instance(`${ServerURL}${GetPricePlanPermissionMethodURL}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }
}

export const customerApi = new CustomerApi();
