import { fetch_instance, getAccessToken, GetArchivedOrders, ServerURL } from "./ServerConfig";
import toast from "react-hot-toast";
import axios from "axios";

class ArchivedOrderApis {
  async getArchivedOrders(query) {
    try {
      const response = await axios.get(`${ServerURL}${GetArchivedOrders}`, {
          headers: { Authorization: `Bearer ${getAccessToken()}` },
          params: query
        },
      );
      console.log('response.data', response.data);
      return response.data;
    } catch (error) {
      console.log('error --> ', error)
      return error;
    }
  }

  async restoreOrder(orderSerial) {
    let response = {};
    await fetch_instance(`${ServerURL}${GetArchivedOrders}${orderSerial}/restore_order/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({'order_serial': orderSerial}),
    })
      .then((res) => {
        response = res;
        return response;
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return response;
  }

  async restoreOrders(ordersSerials) {
    let response = {};
    await fetch_instance(`${ServerURL}${GetArchivedOrders}restore_orders/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({'orders_serials': ordersSerials}),
    })
      .then((res) => {
        response = res;
        return response;
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return response;
  }
}

export const archivedOrderApis = new ArchivedOrderApis();