import { useState, useEffect, useCallback, useRef } from "react";
import {
  Link as RouterLink,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import useMounted from "../../../hooks/useMounted";
import useSettings from "../../../hooks/useSettings";
import ChevronRightIcon from "../../../icons/ChevronRight";
import gtm from "../../../lib/gtm";
import RefreshIcon from "../../../icons/Refresh";
import { useTranslation } from "react-i18next";
import CustomerDebtOrdersDetailsTable from "./CustomerDebtOrdersDetailsTable";
import {customerApi} from "../../../__fakeApi__/customerApi";
import {orderApi} from "../../../__fakeApi__/orderApi";
import useAuth from "../../../hooks/useAuth";

const applyFilters = (row, query) =>
    row.filter((row) => {
      let matches = true;
      if (
          query &&
          !row.company_name.toLowerCase().includes(query.toLowerCase())
      ) {
        matches = false;
      }
      return matches;
    });

const CustomerDebtOrdersDetails = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { settings } = useSettings();
  const [dataList, setDataList] = useState([]);
  const [query, setQuery] = useState("");
  const [customerInfo, setCustomerInfo] = useState({});
  const [customerDebtValue, setCustomerDebtValue] = useState(0);
  const [customerAccountNotes, setCAccountNotes] = useState("");
  const { user } = useAuth();
  const [userPermissions, setuUerPermissions] = useState([]);
  const state = useParams();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  const GetDataList = useCallback(async () => {
    try {
      if (state.customer_id) {
        const data = await customerApi.getCustomerDebtOrdersDetailsReport(state.customer_id);
        if (data) {
          let orders = data.orders.map(order => order.Order_Delivery_Status === '5' ? { ...order, total_amount: 0 } : order)
          setDataList(orders || []);
          if (orders.length > 0) {
            setCustomerInfo(data.orders[0])
          }
          setCustomerDebtValue(data.customer_debt_value)
          setCAccountNotes(data.customer_account_notes)
        } else {
          setDataList([]);
        }
      } else {
        setDataList([]);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted, state]);

  useEffect(() => {
    GetDataList();
  }, [GetDataList]);

  // const getCustomer = async () => {
  //   try {
  //     if (state.customer_id) {
  //       const data = await customerApi.getCustomers(state.customer_id);
  //       setCustomer(data || {})
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // useEffect(() => {
  //   getCustomer();
  // }, [getCustomer]);

  const getUserPermissions = async () => {
    try {
      const userPermissions = await orderApi.getUserPermession(user.id);
      if (mounted.current) {
        setuUerPermissions(userPermissions);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserPermissions();
  }, []);


  return (
      <>
        <Helmet>
          <title>
            {t("Dashboard")}: {t("Customer Debt Orders Details Report")}
          </title>
        </Helmet>
        <Box
            sx={{
              backgroundColor: "background.default",
              minHeight: "50%",
              Height: "50%",
              py: 8,
            }}
        >
          <Container maxWidth={settings.compact ? "xl" : false}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography color="textPrimary" variant="h5">
                  {t("Customer Debt Orders Details Report")}
                </Typography>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<ChevronRightIcon fontSize="small" />}
                    sx={{ mt: 1 }}
                >
                  <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="/dashboard/"
                      variant="subtitle2"
                  >
                    {t("Dashboard")}
                  </Link>
                  <Typography color="textSecondary" variant="subtitle2">
                    {t("Customer Debt Orders Details Report")}
                  </Typography>
                </Breadcrumbs>
                <Box
                    sx={{
                      mb: -1,
                      mx: -1,
                      mt: 1,
                    }}
                >
                </Box>
              </Grid>
<Grid container alignItems="center" justifyContent="center" spacing={4}>
  {/* First Row - 3 Items in a horizontal layout */}
  <Grid item xs={12} container spacing={2}>
    {/* First Grid Item - Customer Info */}
    <Grid item xs={6} md={4}>
      <Typography color="textPrimary" variant="h5" align="center">
        <h5>({customerInfo.customer}) {customerInfo.company_name}</h5>
      </Typography>
    </Grid>

    {/* Second Grid Item - Total Debt Value */}
    <Grid item xs={6} md={4}>
      <Typography color="textPrimary" variant="h5" align="center">
        <h5>{t('Total Debt Value')} = {customerDebtValue ? customerDebtValue : 0}</h5>
      </Typography>
    </Grid>

    {/* Third Grid Item - Payment Method */}
<Grid item xs={6} md={4}>
  {customerInfo.payment_method ? (
    <Typography color="textPrimary" variant="h5" align="center">
      <h5>({customerInfo.payment_method}) {customerInfo.bank_account_no || customerInfo.vodafone_cash}</h5>
    </Typography>
  ) : (
    <Typography color="textPrimary" variant="h5" align="center">
      <h5>No payment info available</h5>
    </Typography>
  )}
</Grid>

  </Grid>
</Grid>


              <Grid item>
                <Box sx={{ m: -1 }}></Box>
              </Grid>
              <Grid item>
                <Box sx={{ m: -1 }}>
                  <Button
                      color="primary"
                      //   component={RouterLink}
                      startIcon={<RefreshIcon fontSize="small" />}
                      sx={{ m: 1 }}
                      variant="contained"
                      // onClick={GetDataList}
                      onClick={(e) => window.location.reload()}
                  >
                    {t("Refresh")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
              <CustomerDebtOrdersDetailsTable
                  dataList={dataList}
                  userPermissions={userPermissions}
                  customerDebtValue={customerDebtValue}
                  query={query}
                  user={user}
                  GetDataList={GetDataList}
                  date={state.day_date}
                  customerID={state.customer_id}
                  accountNotes={customerAccountNotes}
              />
            </Box>
          </Container>
        </Box>
      </>
  );
};

export default CustomerDebtOrdersDetails;