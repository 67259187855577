import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  Autocomplete,
  Stack,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { TreasuryApi } from "../../../../__fakeApi__/treasury_apis";
import toast from "react-hot-toast";

import * as Yup from "yup";
import { contentTypeAPI } from "../../../../__fakeApi__/ContentTypeAPI";

const WithdrawalDialog = (props) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState("");
  const [treasury, setTreasury] = useState("");
  const [contentTypes, setContentTypes] = useState([]);
  const [contentTypeObjectList, setContentTypeObjectList] = useState([]);
  const [recipientsType, setRecipientsType] = useState("");
  const [recipient, setRecipient] = useState("");
  const [notes, setNotes] = useState("");
  const [searchValue, setSearchValue] = useState('');

  const {
    treasuries,
    selectedTreasury,
    listTreasury,
    handleClose,
    open,
    handlefilter,
  } = props;

  const closeDialgo = () => {
    setAmount("");
    setNotes("");
    setTreasury("");
    setRecipientsType("");
    setRecipient("");
    handleClose();
  };

  const getContentTypes = async () => {
    try {
      const contentTypeData = await contentTypeAPI.get();
      setContentTypes(contentTypeData);
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  };

  const getContentTypeObjectList = async (contentTypeID) => {
    try {
      const contentTypeObjectListData = await contentTypeAPI.getContentTypeObjectList(contentTypeID);
      setContentTypeObjectList(contentTypeObjectListData);
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  };

  const handleSelectContentType = (event, contentType) => {
    setContentTypeObjectList([])
    setRecipient("")
    if (contentType) {
      let contentTypeID = contentType.id
      setRecipientsType(contentTypeID)
      getContentTypeObjectList(contentTypeID);
    } else {
      setRecipientsType("")
    }
  }

  const handleSelectObject = (event, object) => {
    setRecipient(object ? object.id : '');
  }

  const displayErrors = (errorResponse) => {
    let errorMessages = [];
    Object.entries(errorResponse).forEach(([key, value]) => {
      const fieldErrors = `${value}`;
      errorMessages.push(fieldErrors);
    });
    return errorMessages;
  };

  const handleCreate = async (event) => {
    event.preventDefault()
    try {
      const payload = {
        treasury: listTreasury ? treasury :selectedTreasury.id,
        amount: amount,
        note: notes,
        content_type: recipientsType,
        object_id: recipient
      };
      const response = await TreasuryApi.withdraw(payload);
      if (response.ok) {
        response.json().then((result) => {
          toast.success(t("The transaction was applied successfully"));
          handlefilter();
        });
      } else {
        response.json().then((result) => {
          toast.error(displayErrors(result));
        });
      }
    } catch (err) {
      toast.error(t("An error occurred during the transaction"));
      console.error(err);
    }
    closeDialgo();
  };

  useEffect(() => {
    getContentTypes();
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    handleCreate(values);
    setSubmitting(false);
    closeDialgo();
  };

  // Apply validation using Yup package
  const validationSchema = Yup.object().shape({
    treasury: Yup.string().required("Treasury is required"),
    recipient: Yup.string().required("Recipient is required"),
    amount: Yup.number()
        .required("Amount is required")
        .positive("Amount must be a positive number"),
    // Add more validation rules as needed for other fields
  });

  return (
      <div>
        <Dialog
            open={open}
            onClose={() => {
              closeDialgo();
            }}
        >
          <form onSubmit={handleCreate}>
            <DialogTitle>{t("Withdrawal Transaction")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("Please fill in the details below:")}
              </DialogContentText>
              <Stack spacing={4}>
                {listTreasury &&
                    <Autocomplete
                        disablePortal
                        id="treasury"
                        options={treasuries}
                        getOptionLabel={(option) => `${option.id} ${option.name}`} // Customize display
                        renderInput={(params) => (
                            <TextField {...params} label={t("Treasury")} required/>
                        )}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setTreasury(newValue.id);

                          } else {
                            setTreasury("");
                          }
                        }}
                    />}
                {!listTreasury &&
                    <Autocomplete
                        disablePortal
                        id="treasury"
                        disabled={true}
                        value={selectedTreasury}
                        options={treasuries}
                        getOptionLabel={(option) => `${option.id} ${option.name}`} // Customize display
                        renderInput={(params) => (
                            <TextField {...params} label={t("Treasury")} required/>
                        )}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setTreasury(newValue.id);

                          } else {
                            setTreasury("");
                          }
                        }}
                    />}
                <Autocomplete
                    options={contentTypes}
                    getOptionLabel={(option) => option.model.charAt(0).toUpperCase() + option.model.slice(1)}
                    onChange={handleSelectContentType}
                    value={contentTypes.find((option) => option.id === recipientsType) || null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Recipient Type")}
                            required
                        />
                    )}
                />
                <Autocomplete
                    options={contentTypeObjectList}
                    getOptionLabel={(option) => option.name}
                    onChange={handleSelectObject}
                    value={contentTypeObjectList.find((option) => option.id === recipient) || null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Recipient")}
                            required
                        />
                    )}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="amount"
                    label="Amount"
                    type="number"
                    fullWidth
                    value={amount}
                    required
                    onChange={(e) => setAmount(e.target.value)}
                />

                <TextField
                    margin="dense"
                    id="notes"
                    label="Notes"
                    type="text"
                    fullWidth
                    value={notes}
                    multiline
                    onChange={(e) => setNotes(e.target.value)}
                />
              </Stack>
            </DialogContent>
            <Stack direction={"row"} spacing={3} mx={12} my={2}>
            <Button
                type="submit"
                color="success"
                size="large"
                variant="contained"
            >
              {t("Create")}
            </Button>

            <Button
                onClick={() => {
                  closeDialgo();
                }}
                color="error"
                size="large"
                variant="contained"
            >
              {t("Cancel")}
            </Button>
          </Stack>
          </form>
        </Dialog>
      </div>
  );
};

export default WithdrawalDialog;