import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core";
import Scrollbar from "../../../Scrollbar";
import styles from "../../Styles";
import { useTranslation } from "react-i18next";
import {Pagination} from "@material-ui/lab";

// const { settings } = useSettings();

const applyPagination = (treasuries, page, limit) => {
    let result = [];
    if (treasuries && treasuries.length !== 0) {
        result = treasuries.slice(page * limit, page * limit + limit);
    }
    return result;
};

const groupBy = (array, key) => {
    // Return the reduced array
    return array.reduce((result, currentItem) => {
        currentItem[key] = currentItem[key].toString().slice(0, 10);
        (result[currentItem[key]] = result[currentItem[key]] || []).push(
            currentItem
        );
        return result;
    }, {}); // Empty object is the initial value for result object
};

const TransactionSummaryTable = (props) => {
    const {
        transactions,
        totalTotalIn,
        totalTotalOut,
        totalCurrentBalance,
        ...other
    } = props;
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const { t } = useTranslation();

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value, 10));
    };

    const paginatedProducts = applyPagination(transactions, page, limit);
    const groupData = groupBy(paginatedProducts, "created_date");

    return (
        <>
            <Card {...other}>
                <Scrollbar>
                    <Box sx={{ minWidth: 1150, height: "100%" }}>
                        <Table size="small">
                            <TableHead
                                sx={{
                                    minWidth: 1150,
                                    height: "100%",
                                    fontSize: "2rem",
                                    lineHeight: 2.5,
                                    textTransform: "uppercase",
                                    fontWeight: "fontWeightBold",
                                }}
                            >
                                <TableRow style={styles.red}>
                                    <TableCell align="center">Date</TableCell>
                                    <TableCell align="center">Treasury</TableCell>
                                    <TableCell align="center">Total In ({totalTotalIn})</TableCell>
                                    <TableCell align="center">Total Out ({totalTotalOut})</TableCell>
                                    <TableCell align="center">Total Current Balance ({totalCurrentBalance})</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedProducts &&
                                    Object.keys(groupData).map(function (key, data) {
                                        return (
                                            <>
                                                <TableRow style={styles.table_head_light}>
                                                    <TableCell
                                                        align="center"
                                                        colSpan="20"
                                                        sx={{ textAlign: "left", color: "#fff" }}
                                                        style={styles.table_head_light}
                                                    >
                                                        {key}
                                                    </TableCell>
                                                </TableRow>
                                                {groupData[key].map((treasury) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={treasury.treasury__name}
                                                        >
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center">{treasury.treasury__name}</TableCell>
                                                            <TableCell align="center">{treasury.total_in}</TableCell>
                                                            <TableCell align="center">{treasury.total_out}</TableCell>
                                                            <TableCell align="center">{treasury.current_balance}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                p: 1,
                            }}
                        ></Box>
                    </Box>

                </Scrollbar>
            </Card>

            <Box
                item
                md={6}
                xs={12}
                sx={{
                    display: {
                        md: "flex",
                        xs: "flex",
                    },
                    justifyContent: "left",
                }}
            >
                <TablePagination
                    component="div"
                    count={transactions.length / limit} //{orders.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[10, 25, 50]}
                />
                <Pagination
                    sx={{
                        justifyContent: "center",
                        ml: 20,
                        mt: 1,
                    }}
                    count={Math.ceil(transactions.length / limit)}
                    size="xlarge"
                    onChange={(event, newPage) => {
                        // if (event.target.type){
                        newPage--;
                        // }
                        handlePageChange(event, newPage);
                    }}
                    onRowsPerPageChange={handleLimitChange}
                    rowsPerPage={limit}
                    page={page + 1}
                />
            </Box>
        </>
    );
};

TransactionSummaryTable.propTypes = {
    treasuries: PropTypes.array.isRequired,
};

export default TransactionSummaryTable;