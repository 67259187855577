import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Button,
    IconButton,
    DialogTitle,
    Dialog,
    Typography,
    Stack,
    Autocomplete,
} from "@material-ui/core";
import Scrollbar from "../../../Scrollbar";

import styles from "../../Styles";
import { useTranslation } from "react-i18next";
import { DateApi } from "../../../../__fakeApi__/DateApi";
import { TreasuryApi } from "../../../../__fakeApi__/treasury_apis";

import format from "date-fns/format";
import DownloadIcon from "../../../../icons/Download";
import {ExportToExcelApis} from "../../../../__fakeApi__/ExportToExcel";

const applyPagination = (treasuries, page, limit) => {
    let result = [];
    if (treasuries && treasuries.length !== 0) {
        result = treasuries.slice(page * limit, page * limit + limit);
    }
    return result;
};

const transactionListTable = (props) => {
    const {
        setWithdrawals,
        searchParams,
        setSearchParams,
        getWithdrawalsList,
        resetSearchParams,
        recipientTypes,
        recipients,
        shippers,
        employees,
        transactions,
        contentTypes,
        getContentTypeObjectList,
        getTransactionsList,
        setTransactions,
        contentTypeObjectList,
        treasuries,
        initialState,
        totalNetAmount,
        openBalance,
        closeBalance,
        setContentTypeObjectList,
        ...other
    } = props;
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(25);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [openConfirmModel, setOpenConfirmModel] = useState(false);
    const [deleteTreasury, setDeleteTreasury] = useState(0);
    const handleClose = () => setOpenConfirmModel(false);
    const [ServerDate, setServerDate] = useState(DateApi.getDate());
    const [fromDateField, setFromDateField] = useState(searchParams.fromDate);
    const [toDateField, setToDateField] = useState(searchParams.toDate);

    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    const handlefilter = async () => {
        getTransactionsList();
    };

    const transactionTypes = [
        { key: 'W', value: t('Withdraw') },
        { key: 'D', value: t('Deposit') },
    ]

    const resetTransactionList = async () => {
        resetSearchParams()
        try {
            const data = await TreasuryApi.get(initialState);
            setTransactions(data);
        } catch (err) {
            console.error(err);
        }
    };

    const handleSelectTransactionType = (event, newValue) => {
        if (newValue) {
            setSearchParams((prevData) => ({
                ...prevData,
                transaction_type: newValue.key,
            }));
        } else {
            setSearchParams((prevData) => ({
                ...prevData,
                transaction_type: "",
            }));
        }
    }

    const handleSelectContentType = (event, newValue) => {
        setContentTypeObjectList([])
        if (newValue) {
            setSearchParams((prevData) => ({
                ...prevData,
                content_type: newValue.id,
            }));
            getContentTypeObjectList(newValue.id)
        } else {
            setSearchParams((prevData) => ({
                ...prevData,
                content_type: "",
                object_id: "",
            }));
        }
    }

    const handlereset = async () => {
        setToDateField(format(DateApi.getDate(ServerDate), "yyyy-MM-dd"));
        setFromDateField(format(DateApi.getDate(ServerDate), "yyyy-MM-dd"));
        resetSearchParams();
        resetTransactionList();
    };

    async function download_excel() {
        let selectedColumnsData = transactions.map(transaction => ({
            'serial': transaction['serial'],
            'treasury': transaction['treasury_name'],
            'recipient type': transaction['content_type_title'],
            'recipient': transaction['content_object'],
            'transaction_type': transaction['transaction_type'],
            'amount': transaction['amount'],
            'note': transaction['note'],
            'date': transaction['created_at'],
            'created_by': transaction['created_by'],
        }));
        let today = DateApi.getDate(ServerDate);
        await ExportToExcelApis.downloadexcel(
            selectedColumnsData, `Treasuries Transactions Report`,
        );
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value, 10));
    };

    const paginatedProducts = applyPagination(transactions, page, limit);

    return (
        <Card {...other}>
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexWrap: "wrap",
                    m: -1,
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        p: 1,
                        maxWidth: "100%",
                        width: "25%",
                    }}
                >
                    <Autocomplete
                        disablePortal
                        id="treasury"
                        options={treasuries}
                        getOptionLabel={(option) => `${option.name}`}
                        renderInput={(params) => (
                            <TextField {...params} label={t("Treasury")} required />
                        )}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setSearchParams((prevData) => ({
                                    ...prevData,
                                    treasury: newValue.id,
                                }));
                            } else {
                                setSearchParams((prevData) => ({
                                    ...prevData,
                                    treasury: "",
                                }));
                            }
                        }}
                    />
                </Box>

                <Box
                    sx={{
                        p: 1,
                        maxWidth: "100%",
                        width: "25%",
                    }}
                >
                    <Autocomplete
                        disablePortal
                        id="contentType"
                        options={contentTypes}
                        getOptionLabel={(option) => `${option.model.toUpperCase()}`} // Customize display
                        renderInput={(params) => (
                            <TextField {...params} label={t("Recipient Type")} />
                        )}
                        onChange={handleSelectContentType}
                    />
                </Box>
                <Box
                    sx={{
                        p: 1,
                        maxWidth: "100%",
                        width: "25%",
                    }}
                >
                    <Autocomplete
                        disablePortal
                        id="contentTypeObjectList"
                        options={contentTypeObjectList}
                        getOptionLabel={(option) => `${option.name}`} // Customize display
                        renderInput={(params) => (
                            <TextField {...params} label={t("Recipient")} />
                        )}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setSearchParams((prevData) => ({
                                    ...prevData,
                                    object_id: newValue.id,
                                }));
                            } else {
                                setSearchParams((prevData) => ({
                                    ...prevData,
                                    object_id: "",
                                }));
                            }
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        p: 1,
                        maxWidth: "100%",
                        width: "25%",
                    }}
                >
                    <Autocomplete
                        disablePortal
                        id="transactionType"
                        options={transactionTypes}
                        getOptionLabel={(option) => `${option.value}`} // Customize display
                        renderInput={(params) => (
                            <TextField {...params} label={t("Transaction Type")} />
                        )}
                        onChange={handleSelectTransactionType}
                    />
                </Box>
                <Box
                    sx={{
                        p: 1,
                        maxWidth: "100%",
                        width: "25%",
                    }}
                >
                    <TextField
                        size="small"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        autoComplete="nope"
                        label={t("Note")}
                        name="note"
                        onChange={(data) => {
                            setSearchParams((prevData) => ({
                                ...prevData,
                                note: data.target.value || "",
                            }));
                        }}
                        value={searchParams.note}
                        variant="outlined"
                    />
                </Box>
                <Box
                    sx={{
                        p: 1,
                        maxWidth: "100%",
                        width: "25%",
                    }}
                >
                    <TextField
                        size="small"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        autoComplete="nope"
                        label={t('Amount')}
                        name="amount"
                        onChange={(data) => {
                            setSearchParams((prevData) => ({
                                ...prevData,
                                amount: data.target.value || "",
                            }));
                        }}
                        value={searchParams.amount}
                        variant="outlined"
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexWrap: "wrap",
                    m: -1,
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        m: 1,
                        maxWidth: "100%",
                    }}
                >
                    <TextField
                        size="small"
                        type="date"
                        value={fromDateField}
                        label={t("From Date")}
                        defaultValue={fromDateField}
                        InputLabelProps={{ shrink: true }}
                        onChange={(date) => {
                            setFromDateField(date.target.value || "");
                        }}
                        onBlur={() => {
                            setFromDateField(fromDateField || "");
                            setSearchParams((prevData) => ({
                                ...prevData,
                                fromDate: fromDateField || "",
                            }));
                        }}
                        onKeyPress={(event) => {
                            if (event.charCode == 13) {
                                setFromDateField(fromDateField || "");
                                setSearchParams((prevData) => ({
                                    ...prevData,
                                    fromDate: fromDateField || "",
                                }));
                            }
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        m: 1,
                        maxWidth: "100%",
                    }}
                >
                    <TextField
                        size="small"
                        type="date"
                        value={toDateField}
                        label={t("To Date")}
                        defaultValue={toDateField}
                        InputLabelProps={{ shrink: true }}
                        onChange={(date) => {
                            setToDateField(date.target.value || "");
                        }}
                        onBlur={() => {
                            setSearchParams((prevData) => ({
                                ...prevData,
                                toDate: toDateField || "",
                            }));
                        }}
                        onKeyPress={(event) => {
                            if (event.charCode == 13) {
                                setSearchParams((prevData) => ({
                                    ...prevData,
                                    toDate: toDateField || "",
                                }));
                            }
                        }}
                    />
                </Box>

                <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" spacing={3}>
                    <Stack direction={"row"} spacing={2}>
                        <Button
                            color="primary"
                            fullWidth
                            size="small"
                            sx={{ backgroundColor: "#DF2F2F" }}
                            variant="contained"
                            onClick={() => {
                                handlefilter();
                            }}
                        >
                            {t("Filter")}
                        </Button>

                        <Button
                            color="primary"
                            size="small"
                            sx={{ backgroundColor: "#DF2F2F" }}
                            variant="contained"
                            onClick={(event) => {
                                handlereset();
                            }}
                        >
                            {t("Reset")}
                        </Button>

                        <Button
                          color="primary"
                          startIcon={<DownloadIcon fontSize="small" />}
                          sx={{ m: 2 }}
                          variant="text"
                          onClick={download_excel}
                        >
                          {t("Export")}
                        </Button>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                        <Box>
                            {t("Opening Balance")}: {openBalance}
                        </Box>
                        <Box>
                            {t("Closing Balance")}: {closeBalance}
                        </Box>
                    </Stack>
                </Stack>


            </Box>

            <Scrollbar>
                <Box sx={{ minWidth: 1200 }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={styles.table_head}>{t("Serial")}</TableCell>
                                <TableCell style={styles.table_head}>{t("Treasury")}</TableCell>
                                <TableCell style={styles.table_head}>{t("Recipient Type")}</TableCell>
                                <TableCell style={styles.table_head}>{t("Recipient")}</TableCell>
                                <TableCell style={styles.table_head}>{t("Transaction Type")}</TableCell>
                                <TableCell style={styles.table_head}>{t("Amount")} ({totalNetAmount})</TableCell>
                                <TableCell style={styles.table_head}>{t("Historical Balance")}</TableCell>
                                <TableCell style={styles.table_head}>{t("Note")}</TableCell>
                                <TableCell style={styles.table_head}>{t("Date")}</TableCell>
                                <TableCell style={styles.table_head}>{t("Created by")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedProducts.length !== 0 &&
                                paginatedProducts.map((row) => {
                                    const isProductSelected = selectedRows.includes(row.id);
                                    return (
                                        <TableRow
                                            hover
                                            key={row.id}
                                            selected={isProductSelected}
                                            sx={{ height: 1 }}
                                        >
                                            <TableCell align="center">{row.serial}</TableCell>
                                            <TableCell align="center">{row.treasury_name}</TableCell>
                                            <TableCell align="center">{row.content_type_title}</TableCell>
                                            <TableCell align="center">{row.content_object}</TableCell>
                                            <TableCell align="center">{row.transaction_type}</TableCell>
                                            <TableCell align="center">{row.amount}</TableCell>
                                            <TableCell align="center">{row.historical_balance}</TableCell>
                                            <TableCell align="center">{row.note}</TableCell>
                                            <TableCell align="center">{row.created_at}</TableCell>
                                            <TableCell align="center">{row.created_by}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>

                    <TablePagination
                        component="div"
                        count={transactions.length}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[25, 50, 100]}
                    />

                    <Dialog onClose={handleClose} open={openConfirmModel}>
                        <DialogTitle>{t("Delete Confirmation")}</DialogTitle>
                        <Typography
                            variant="subtitle1"
                            component="div"
                            style={{ margin: "5px", marginLeft: "5px" }}
                        >
                            {t("Are you sure you wish to delete this treasury ?")}
                        </Typography>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button
                                size="large"
                                color="error"
                                onClick={() => { }}
                                variant="contained"
                            >
                                {t("Yes")}
                            </Button>
                            <Button
                                size="large"
                                color="success"
                                onClick={handleClose}
                                variant="contained"
                            >
                                {t("No")}
                            </Button>
                        </Stack>
                    </Dialog>
                </Box>
            </Scrollbar>
        </Card>
    );
};

transactionListTable.propTypes = {
    treasuries: PropTypes.array.isRequired,
};

export default transactionListTable;
