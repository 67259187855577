import PropTypes from "prop-types";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";
import {Box, Button, Card, Grid, TextField} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ShipperCategoryForm = (props) => {
  const { data, SaveFun } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{
        id: data.id || "",
        name: data.name || "",
        notes: data.notes || "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(200).required("Name is required"),
        // index: Yup.number().required("Sort is required"),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          // NOTE: Make API request

          await SaveFun(values);

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success("Setting updated!");
          navigate("/dashboard/mandoob_categories");
        } catch (err) {
          console.error(err);
          toast.error("Something went wrong!");
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label={t("Name")}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.notes && errors.notes)}
                    fullWidth
                    helperText={touched.notes && errors.notes}
                    label={t("Notes")}
                    name="notes"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.notes}
                    variant="outlined"
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 2 }}>
                <Button
                  color="primary"
                  sx={{ backgroundColor:"#DF2F2F"}}
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {t("Save")}
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ShipperCategoryForm.propTypes = {
  data: PropTypes.object.isRequired,
  SaveFun: PropTypes.func.isRequired,
};

export default ShipperCategoryForm;