import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import BriefcaseIcon from "../../icons/Briefcase";
import ChartPieIcon from "../../icons/ChartPie";
import ChatAltIcon from "../../icons/ChatAlt";
import FolderOpenIcon from "../../icons/FolderOpen";
import MailIcon from "../../icons/Mail";
import Cash from "../../icons/Cash";
import UserIcon from "../../icons/User";
import CogIcon from "../../icons/Cog";
import UsersIcon from "../../icons/Users";
import StarIcon from "../../icons/Star";
import PencilAltIcon from "../../icons/PencilAlt";
// import Cash from '../../icons/Cash';
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import DocumentText from "../../icons/DocumentText";
import CurrencyDollarIcon from "../../icons/CurrencyDollar";

const sections = [
  {
    title: "Operation",
    items: [
      // {
      //   title: 'Overview',
      //   path: '/dashboard',
      //   icon: <ChartSquareBarIcon fontSize="small" />
      // },
      {
        title: "Analytics",
        path: "/dashboard/analytics",
        icon: <ChartPieIcon fontSize="small" />,
      },
      {
        title: "Customers",
        path: "/dashboard/customers",
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/dashboard/customers",
          },
          // {
          //   title: 'Details',
          //   path: '/dashboard/customers/1'
          // },
          // {
          //   title: 'Edit',
          //   path: '/dashboard/customers/2/edit'
          // },
          {
            title: "New",
            path: "/dashboard/customers/new",
          },
          //   {
          //   title: 'Create Client Shipment',
          //   path: '/dashboard/Customers/CustomerShipmentInfoCreate'
          // },
          {
            title: "Income",
            path: "/dashboard/customers/revenue/",
          },
        ],
      },
      {
        title: "Mandoob",
        path: "/dashboard/shipper",
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/dashboard/shipper",
          },
          {
            title: "Create",
            path: "/dashboard/shipper/new",
          },
          {
            title: "Revenue",
            path: "/dashboard/shipper/revenue/",
          },
        ],
      },
      {
        title: "All Orders",
        icon: <FolderOpenIcon fontSize="small" />,
        path: "/dashboard/adminorders",
        children: [
          {
            title: "List",
            path: "/dashboard/adminorders",
          },

          {
            title: "Print Orders",
            path: "/dashboard/orders/printorders",
          },
        ],
      },
      {
        title: "Hub Orders",
        icon: <FolderOpenIcon fontSize="small" />,
        path: "/dashboard/adminorders/shipper/1",
      },
      {
        title: "Shipment Request",
        path: "/dashboard/shipmentrequest",
        icon: <BriefcaseIcon fontSize="small" />,
        // children: [
        //   //   {
        //   //   title: 'Client Price List',
        //   //   path: '/dashboard/Shipment'
        //   // },
        //   //   {
        //   //   title: 'Client Price List',
        //   //   path: '/dashboard/Shipment/customerpricelist'
        //   // },
        //   // {
        //   //   title: 'Create Client Shipment',
        //   //   path: '/dashboard/Shipment/create'
        //   // },
        //     {
        //     title: 'Shipment Request',
        //     path: '/dashboard/shipmentrequest'
        //   }
        // ]
      },
      {
        title: "Follow UP Orders",
        path: "/dashboard/followorders",
        icon: <FolderOpenIcon fontSize="small" />,
      },
      {
        title: "Mandoob Orders Review",
        path: "/dashboard/shipperOrdersReview",
        icon: <FolderOpenIcon fontSize="small" />,
      },
      {
        title: "Fast Request",
        path: "/dashboard/hurryorder",
        icon: <FolderOpenIcon fontSize="small" />,
      },

      // {
      //   title: 'Finance',
      //   path: '/dashboard/finance',
      //   icon: <ShoppingBagIcon fontSize="small" />
      // },
      // {
      //   title: 'Account',
      //   path: '/dashboard/account',
      //   icon: <UserIcon fontSize="small" />
      // },
      //  {
      //   title: 'Issue',
      //   path: '/dashboard/issue',
      //   icon: <ChatAltIcon fontSize="small" />
      // },
      //   {
      //   title: 'Pickup',
      //   path: '/dashboard/backup',
      //   icon: <UserIcon fontSize="small" />
      // },
      //   {
      //   title: 'Collect List',
      //   path: '/dashboard/CollectRequest/collectlist',
      //   icon: <UserIcon fontSize="small" />
      // },
    ],
  },
  // {
  //   title: 'Management',
  //   items: [

  //     // {
  //     //   title: 'Products',
  //     //   path: '/dashboard/products',
  //     //   icon: <ShoppingCartIcon fontSize="small" />,
  //     //   children: [
  //     //     {
  //     //       title: 'List',
  //     //       path: '/dashboard/products'
  //     //     },
  //     //     {
  //     //       title: 'Create',
  //     //       path: '/dashboard/products/new'
  //     //     }
  //     //   ]
  //     // },

  //     // {
  //     //   title: 'Orders',
  //     //   icon: <FolderOpenIcon fontSize="small" />,
  //     //   path: '/dashboard/orders',
  //     //   children: [
  //     //     {
  //     //       title: 'List',
  //     //       path: '/dashboard/orders'
  //     //     },
  //     //     // {
  //     //     //   title: 'Details',
  //     //     //   path: '/dashboard/orders/1'
  //     //     // },
  //     //       {
  //     //       title: 'Create',
  //     //       path: '/dashboard/orders/new'
  //     //     }
  //     //     ,
  //     //       {
  //     //       title: 'Print Orders',
  //     //       path: '/dashboard/orders/printorders'
  //     //     }
  //     //   ]
  //     // }
  //     // ,
  //     // {
  //     //   title: 'Invoices',
  //     //   path: '/dashboard/invoices',
  //     //   icon: <ReceiptIcon fontSize="small" />,
  //     //   children: [
  //     //     {
  //     //       title: 'List',
  //     //       path: '/dashboard/invoices'
  //     //     },
  //     //     {
  //     //       title: 'Details',
  //     //       path: '/dashboard/invoices/1'
  //     //     }
  //     //   ]
  //     // }
  //   ]
  // },
  {
    title: "Transactions",
    items: [
      {
        title: "Mandoob Closing",
        path: "/dashboard/shipperclosing",
        icon: <CurrencyDollarIcon fontSize="small" />,
        children: [
          // {
          //   title: 'Browse',
          //   path: '/dashboard/projects/browse'
          // },
          // {
          //   title: 'Details',
          //   path: '/dashboard/projects/1'
          // },
          {
            title: "Create",
            path: "/dashboard/shipperclosing/new",
          },
          {
            title: "View",
            path: "/dashboard/shipperclosing/view",
          },
        ],
      },
      {
        title: "Edit Approval",
        path: "/dashboard/approvallist",
        icon: <PencilAltIcon fontSize="small" />,
      },
      {
        title: "Customer Edit Approval",
        path: "/dashboard/editapprovallist",
        icon: <PencilAltIcon fontSize="small" />,
      },
      {
        title: "Mandoob Edit Approval",
        path: "/dashboard/shipperapprovallist",
        icon: <PencilAltIcon fontSize="small" />,
      },

      {
        title: "Chat",
        path: "/dashboard/issue",
        icon: <ChatAltIcon fontSize="small" />,
      },
      {
        title: "Pickup Request",
        path: "/dashboard/backup",
        icon: <FolderOpenIcon fontSize="small" />,
      },
      {
        title: "Collect Request List",
        path: "/dashboard/CollectRequest/collectlist",
        icon: <FolderOpenIcon fontSize="small" />,
      },
      {
        title: "Material Request List",
        path: "/dashboard/materialrequestlist",
        icon: <FolderOpenIcon fontSize="small" />,
      },
      {
        title: "SMS Hold Orders",
        icon: <MailIcon fontSize="small" />,
        path: "/dashboard/SmsHoldOrders",
      },
      {
        path: "/dashboard/UserEvaluation",
        title: "User Evaluation",
        icon: <StarIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Reports",
    items: [
      {
        title: "Mandoob Evaluations",
        path: "/dashboard/shipperevaluationreport",
        icon: <StarIcon fontSize="small" />,
        children: [
          // {
          //   title: 'Browse',
          //   path: '/dashboard/projects/browse'
          // },
          {
            title: "Mandoob Evaluation Details",
            path: "/dashboard/shipperevaluationreport/detail",
          },
          {
            title: "Summary",
            path: "/dashboard/shipperevaluationreport/view",
          },
        ],
      },
      {
        title: "User Evaluations",
        path: "/dashboard/shipperevaluationreport",
        icon: <StarIcon fontSize="small" />,
        children: [
          // {
          //   title: 'Browse',
          //   path: '/dashboard/projects/browse'
          // },
          {
            title: "User Evaluation Details",
            path: "/dashboard/userevalation/detail",
          },
          {
            title: "Summary",
            path: "/dashboard/userevalation/view",
          },
        ],
      },
      {
        title: "Chat Report",
        path: "/dashboard/issuereport",
        icon: <StarIcon fontSize="small" />,
        children: [
          {
            title: "View",
            path: "/dashboard/issuereport",
          },
        ],
      },
      //   {
      //   title : 'Extra Expenses',
      //   path: '/dashboard/expensesreport',
      //   icon: <StarIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: 'View',
      //       path: '/dashboard/expensesreport'
      //     }
      //   ]
      // },
      {
        title: "Orders Statistics",
        path: "/dashboard/OrdersStatistics",
        icon: <DocumentText fontSize="small" />,
        children: [
          {
            title: "Top Companies By Order",
            path: "/dashboard/OrdersStatistics/TopCompaniesByOrderReport",
          },
          {
            title: "Companies Orders by Shipper",
            path: "/dashboard/OrdersStatistics/CompaniesOrdersByShipperReport",
          },
          {
            title: "Companies Orders by City",
            path: "/dashboard/OrdersStatistics/CompaniesOrdersByCityReport",
          },
        ],
      },
      {
        title: "Repeated Orders",
        path: "/dashboard/OrdersStatistics/repeatedorders",
        icon: <FolderOpenIcon fontSize="small" />,
      },
      {
        title: "Cancelled Orders",
        path: "/dashboard/adminorders/cancelledorders",
        icon: <FolderOpenIcon fontSize="small" />,
      },
      {
        title: "Follow UP Orders Report",
        path: "/dashboard/followordersreport",
        icon: <FolderOpenIcon fontSize="small" />,
      },
      {
        title: "Mandoob Tracking",
        path: "/dashboard/shippertracking",
        icon: <FolderOpenIcon fontSize="small" />,
      },
      {
        title: "SMS Report",
        icon: <MailIcon fontSize="small" />,
        path: "/dashboard/SmsReport",
      },
      {
        title: "Mandoob Transfer Report",
        icon: <FolderOpenIcon fontSize="small" />,
        path: "/dashboard/shippertransferreport",
      },
    ],
  },
  // {
  //   title: 'Platforms',
  //   items: [
  //     {
  //       title: 'Projects',
  //       path: '/dashboard/projects',
  //       icon: <BriefcaseIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'Browse',
  //           path: '/dashboard/projects/browse'
  //         },
  //         {
  //           title: 'Details',
  //           path: '/dashboard/projects/1'
  //         },
  //         {
  //           title: 'Create',
  //           path: '/dashboard/projects/new'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social',
  //       path: '/dashboard/social',
  //       icon: <ShareIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'Profile',
  //           path: '/dashboard/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           path: '/dashboard/social/feed'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: 'Apps',
  //   items: [
  //     {
  //       title: 'Kanban',
  //       path: '/dashboard/kanban',
  //       icon: <ClipboardListIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Mail',
  //       path: '/dashboard/mail',
  //       icon: <MailIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Chat',
  //       path: '/dashboard/chat',
  //       icon: <ChatAltIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Calendar',
  //       path: '/dashboard/calendar',
  //       icon: <CalendarIcon fontSize="small" />
  //     }
  //   ]
  // },

  {
    title: "Settings",
    items: [
      {
        title: "City",
        path: "/dashboard/City",
        icon: <CogIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/dashboard/City",
          },
          {
            title: "Create",
            path: "/dashboard/City/new",
          },
        ],
      },

      {
        title: "Zone",
        path: "/dashboard/Zone",
        icon: <CogIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/dashboard/Zone",
          },
          {
            title: "Create",
            path: "/dashboard/Zone/new",
          },
        ],
      },
      {
        title: "Area",
        path: "/dashboard/area",
        icon: <CogIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/dashboard/area",
          },
          {
            title: "Create",
            path: "/dashboard/area/new",
          },
        ],
      },

      {
        title: "Status Note",
        path: "/dashboard/holdreason",
        icon: <CogIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/dashboard/holdreason",
          },
          {
            title: "Create",
            path: "/dashboard/holdreason/new",
          },
        ],
      },
      {
        title: "WhatsApp",
        path: "/dashboard/WhatsAppGroup",
        icon: <CogIcon fontSize="small" />,
        children: [
          {
            title: "WhatsApp Group",
            path: "/dashboard/WhatsAppGroup",
          },
        ],
      },
      // {
      //   title: "Permissions",
      //   path: "/dashboard/userprivient",
      //   icon: <CogIcon fontSize="small" />,
      //   children: [
      //     // {
      //     //   title: "List",
      //     //   path: "/dashboard/userprivient",
      //     // },
      //     // {
      //     //   title: "Create",
      //     //   path: "/dashboard/userprivient/new",
      //     // },
      //     {
      //       title: "R/C User Permissions",
      //       path: "/dashboard/userpermissionfieldValue",
      //     },
      //     {
      //       title:"User Shipper Permissions",
      //       path:"/dashboard/userprivient/ShipperUserPermission"
      //     },
      //     {
      //         title:"Shipper Transfer Permissions",
      //         path:"/dashboard/userprivient/shipperswappermission"
      //     },
      //       {
      //       title:"User Evalation Permissions",
      //       path:"/dashboard/userprivient/grouppermission"
      //     },
      //     {
      //       title:"Follow up Seeting",
      //       path:"/dashboard/userprivient/followupperm"
      //     },
      //     {
      //       title:"User Customer Permissions",
      //       path:"/dashboard/userprivient/CustomerUserPermission"
      //     }

      //   ]
      // },
      // {
      //   title: 'HangOn Reason',
      //   path: '/dashboard/HangonReason',
      //   icon: <ShoppingCartIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: 'List',
      //       path: '/dashboard/HangonReason'
      //     }
      //     ,
      //     {
      //       title: 'Create',
      //       path: '/dashboard/HangonReason/new'
      //     }
      //
      //   ]
      // },
      {
        title: "Site Settings",
        path: "/dashboard/SiteSettings",
        icon: <CogIcon fontSize="small" />,
      },
      {
        title: "System Configuration",
        path: "/dashboard/Configurations",
        icon: <CogIcon fontSize="small" />,
      },
      {
        title: "Pricing Setting",
        path: "/dashboard/Pricing",
        icon: <CogIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/dashboard/Pricing",
          },
          {
            title: "Create",
            path: "/dashboard/Pricing/new",
          },
          // ,
          // {
          //   title: 'Edit',
          //   path: '/dashboard/pricing/edit/:id'
          // }
        ],
      },
    ],
  },
  {
    title: "Permissions",
    items: [
      // {
      //   title: "List",
      //   path: "/dashboard/userprivient",
      // },
      // {
      //   title: "Create",
      //   path: "/dashboard/userprivient/new",
      // },
      {
        title: "Users",
        path: "/dashboard/useraccount",
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/dashboard/useraccount",
          },
          {
            title: "Create",
            path: "/dashboard/useraccount/new",
          },
        ],
      },
      {
        title: "R/C User Permissions",
        icon: <CogIcon fontSize="small" />,
        path: "/dashboard/userpermissionfieldValue",
      },
      {
        title: "User Customer Permissions",
        icon: <CogIcon fontSize="small" />,
        path: "/dashboard/userprivient/CustomerUserPermission",
      },
      {
        title: "User Mandoob Permissions",
        icon: <CogIcon fontSize="small" />,
        path: "/dashboard/userprivient/ShipperUserPermission",
      },
      {
        title: "Mandoob Transfer Permissions",
        icon: <CogIcon fontSize="small" />,
        path: "/dashboard/userprivient/shipperswappermission",
      },
      {
        title: "User Evaluation Permissions",
        icon: <CogIcon fontSize="small" />,
        path: "/dashboard/userprivient/grouppermission",
      },
      {
        title: "Follow up Setting",
        icon: <CogIcon fontSize="small" />,
        path: "/dashboard/userprivient/followupperm",
      },
    ],
  },
];

const customer_sections = [
  {
    title: "",
    items: [
      // {
      //   title: 'Account',
      //   path: '/dashboard/account',
      //   icon: <UserIcon fontSize="small" />
      // },
      // {
      //   title: 'Issue',
      //   path: '/dashboard/issue',
      //   icon: <UserIcon fontSize="small" />
      // },
      {
        title: "Dashboard",
        path: "/customerdashboard/customeranalytics",
        icon: <UserIcon fontSize="small" />,
      },
      {
        title: "Orders",
        icon: <FolderOpenIcon fontSize="small" />,
        path: "/customerdashboard/orders",
        children: [
          {
            title: "List",
            path: "/customerdashboard/orders",
          },
          // {
          //   title: 'Details',
          //   path: '/customerdashboard/orders/1'
          // },
          {
            title: "Create",
            path: "/customerdashboard/orders/new",
          },
          {
            title: "Print Orders",
            path: "/customerdashboard/orders/printorders",
          },
        ],
      },
      {
        title: "Rejected Orders",
        path: "/customerdashboard/rejectedorders",
        icon: <FolderOpenIcon fontSize="small" />,
      },
      {
        title: "Income",
        path: "/customerdashboard/revenue",
        icon: <Cash fontSize="small" />,
        children: [
          {
            title: "List",
            path: "/customerdashboard/revenue",
          },
        ],
      },
      {
        title: "Pickup Request",
        path: "/customerdashboard/backup/requestbackup",
        icon: <UserIcon fontSize="small" />,
      },
      {
        title: "Material Request",
        path: "/customerdashboard/materialrequest",
        icon: <UserIcon fontSize="small" />,
      },
      {
        title: "Settle Request",
        path: "/customerdashboard/CollectRequest",
        icon: <UserIcon fontSize="small" />,
      },
      {
        title: "Client Price List",
        path: "/customerdashboard/Shipment/customerpricelist",
        icon: <Cash fontSize="small" />,
      },
      // {
      //   title: "Todays Order",
      //   path: "/customerdashboard/todays_orders",
      //   icon: <Cash fontSize="small" />,
      // },

      //   {
      //   title: 'Issue',
      //   path: '/customerdashboard/issue',
      //   icon: <UserIcon fontSize="small" />
      // },
      {
        title: "Chat",
        path: "/customerdashboard/customerissue",
        icon: <ChatAltIcon fontSize="small" />,
      },
    ],
  },
  // {
  //   title: 'Management',
  //   items: [
  //
  //       {
  //       title: 'Shipment',
  //       path: '/dashboard/Shipment',
  //       icon: <UsersIcon fontSize="small" />,
  //       children: [
  //
  //           {
  //           title: 'Client Price List',
  //           path: '/dashboard/Shipment/customerpricelist'
  //         },
  //
  //       ]
  //     },
  //
  //
  //
  //
  //
  //   ]
  // },
];

const CustomerDashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  // console.log('useruser',user)
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const navigate = useNavigate();

  function handleHubOrderButton(customer) {
    navigate("/dashboard/adminorders", {
      state: {
        id: 3,
        Shipper_name: "shipper3",
      },
    });
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            {/* <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            /> */}
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            {user.is_staff || user.is_superuser ? (
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            ) : (
              <RouterLink to="/customerdashboard/account">
                <Avatar
                  src={user.avatar}
                  sx={{
                    cursor: "pointer",
                    height: 48,
                    width: 48,
                  }}
                />
              </RouterLink>
            )}

            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="h5">
                {user.first_name? `${user.first_name} ${user.last_name || ""}` : user.name}
              </Typography>
              {/* <Typography
                color="textSecondary"
                variant="body2"
              >
                Your plan:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.plan}
                </Link>
              </Typography> */}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {user.is_superuser == true || user.is_staff == true
            ? sections.map((section) => (
                <NavSection
                  // to={{
                  //   pathname: location.pathname,
                  //   state: {
                  //     id: 3,
                  //     Shipper_name: "shipper3",
                  //   }
                  // }}
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    "& + &": {
                      mt: 3,
                    },
                  }}
                  {...section}
                />
              ))
            : customer_sections.map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    "& + &": {
                      mt: 3,
                    },
                  }}
                  {...section}
                />
              ))}
        </Box>
        {/*<Divider />*/}
        {/*<Box sx={{ p: 2 }}>*/}
        {/*  <Button*/}
        {/*    color="primary"*/}
        {/*    component={RouterLink}*/}
        {/*    fullWidth*/}
        {/*    sx={{ mt: 2 }}*/}
        {/*    to="/dashboard/backup/requestbackup"*/}
        {/*    variant="contained"*/}
        {/*  >*/}
        {/*    Request Pickup*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    color="primary"*/}
        {/*    component={RouterLink}*/}
        {/*    fullWidth*/}
        {/*    sx={{ mt: 2 }}*/}
        {/*    to="/dashboard/CollectRequest"*/}
        {/*    variant="contained"*/}
        {/*  >*/}
        {/*     Collect Request*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

CustomerDashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default CustomerDashboardSidebar;
