import toast from "react-hot-toast";
import {
  getAccessToken,
  fetch_instance,
  ServerURL,
  EmployeeUrl,
} from "./ServerConfig";

class Employee_api {
  async getById(id) {
    let response = [];
    await fetch_instance(`${ServerURL}${EmployeeUrl}${id}/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        response = data;
        return Promise.resolve(response);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(response);
  }

  async get(code, name) {
    let response = [];
    await fetch_instance(`${ServerURL}${EmployeeUrl}?code=${code || ""}&name=${name || ""}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        response = data;
        return Promise.resolve(response);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(response);
  }

  async create(payload) {
    let response = {};
    await fetch_instance(`${ServerURL}${EmployeeUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((res) => {
      response = res;
    });
    return Promise.resolve(response);
  }

  async update(Employee) {
    let response = {};
    await fetch_instance(`${ServerURL}${EmployeeUrl}${Employee.id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(Employee),
    }).then((res) => {
      response = res;
    });
    return Promise.resolve(response);
  }

  async delete(id) {
    let response = {};
    await fetch_instance(`${ServerURL}${EmployeeUrl}${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }).then((res) => {
      response = res;
    });
    return Promise.resolve(response);
  }
}

export const EmployeeApi = new Employee_api();
