import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { CustomPermissionApi } from "../../../__fakeApi__/CustomUserPermssionApi";
import { format } from "date-fns";
import useAuth from "../../../hooks/useAuth";
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  Autocomplete, Stack,
} from "@material-ui/core";
import useMounted from "../../../hooks/useMounted";
import CurrencyDollarIcon from "../../../icons/CurrencyDollar";
import { DateApi } from "../../../__fakeApi__/DateApi";
import {useTranslation} from "react-i18next";
const SelectDateModal = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { onApply, onClose, open, QuickActionflag, treasures, netTotal, collectedClient, ...other } = props;
  // const navigate = useNavigate();
  const mounted = useMounted();
  const [ServerDate, setServerDate] = useState(DateApi.getDate());
  // const [orders,setOrders] = useState([])
  const [valueOptions, setValueOptions] = useState({
    today: format(DateApi.getDate(ServerDate), "yyyy-MM-dd"),
    tomorrow: format(
      DateApi.getDate(ServerDate).setDate(DateApi.getDate(ServerDate).getDate() + 1),
      "yyyy-MM-dd"
    ),
  });
  const Collect = ["2", "4"];
  const Return = ["3", "5"];
  const [options, setOptions] = useState(
    Collect.includes(QuickActionflag)
      ? [
        {
          text: "Today",
          value: "today",
          code: 1,
        },
        {
          text: "Tomorrow",
          value: "tomorrow",
          code: 2,
        },
      ]
      : [
        {
          text: "Today",
          value: "today",
          code: 3,
        },
        {
          text: "Tomorrow",
          value: "tomorrow",
          code: 4,
        },
      ]
  );
  const [values, setValue] = useState({
    return_date: null,
  });
  const handleChange = (key, value, current_options = valueOptions) => {
    setValue({
      return_date: current_options[value],
    });
  };
  const [permissions, setPermissions] = useState();
  const getServerDate = useCallback(async () => {
    try {
      if (open) {
        let data = await DateApi.getServerDate();
        if (data && data.server_date) {
          setServerDate(DateApi.getDate(data.server_date));
          let  current_options = {
            today: format(DateApi.getDate(data.today), "yyyy-MM-dd"),
            tomorrow: format(DateApi.getDate(data.tomorrow), "yyyy-MM-dd"),
          }
          setValueOptions(current_options);
          if (options.length > 0) {
            handleChange("return_date", options[0].value ,current_options );
          }

        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [open]);


  useEffect(() => {
    getServerDate();
  }, [getServerDate]);

  const CheckPermssions = useCallback(async () => {
    try {
      if (open) {

        if (!user.is_superuser && user.is_staff) {
          const data = await CustomPermissionApi.getPermitedOptions(
            user.id,
            options
          );
          setOptions(data);
          if (data.length > 0) {
            handleChange("return_date", data[0].value);
          }
        } else if (options.length > 0) {
          handleChange("return_date", options[0].value);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted, open]);

  useEffect(() => {
    CheckPermssions();
  }, [CheckPermssions]);

  // const handleChange = (key, value) => {
  //   setValue({
  //     return_date: valueOptions[value],
  //   });
  // };
  const setChangeDate = (value) => {
    setValue({
      return_date: value,
    });
  };

  const setTreasury = (treasuryID) => {
    setValue({
      ...values,
      selectedTreasury: treasuryID
    });
  };

  const handleApply = async (event) => {
    event.preventDefault()
    if (netTotal > 0 && !values.selectedTreasury) {
      toast.error("You must select Treasury to deposit in the mandoob closing amount");
      return;
    }

    // toast.success('Request sent!');
    if (options.length == 0) {
      toast.error("You don't have Permission to this Feild");
      return;
    }
    // alert(values.return_date)
    if (!values.return_date) {
      toast.error("Please Select Time");
      return;
    }
    onApply(values.return_date, values.selectedTreasury);
    setChangeDate("return_date", "");
    // try {
    //   let response = await customerApi.customer_return_unDeliverd(values);

    //   if (response.ok) {
    //     response.json().then((result) => {
    //       toast.success(result.message);
    //     });
    //     setValue({});
    //     onApply();
    //   } else {
    //     response.json().then((result) => {
    //       toast.error(result.message);
    //     });
    //   }
    // } catch (err) {
    //   console.error(err);
    // }
  };

  return (
    <Dialog width="lg" onClose={onClose} open={open} {...other}>
      <Box sx={{ p: 3 }}>
        <form onSubmit={handleApply}>
          <Box sx={{ mt: 2, width: 500 }}>
          <Autocomplete
            freesolo
            id="free-solo-2-demo"
            disableClearable
            getOptionLabel={(option) => option.text}
            options={options}
            defaultValue={options.length && options[0]}
            endIcon={<CurrencyDollarIcon fontSize="small" />}
            onChange={(event, value) => {
              // console.log('Order value')
              // console.log(value)
              if (value) {
                handleChange("return_date", value.value);
              } else {
                handleChange("return_date", value);
              }
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                label="Select Time"
                name="order"
                variant="outlined"
                value={values.return_date}
                {...params}
              />
            )}
          />
        </Box>
          <Box sx={{ mt: 2, width: 500 }}>
            <TextField
              size="small"
              type="date"
              value={values.return_date}
              disabled={!user.is_superuser}
              fullWidth
              label="Date/Time"
              // defaultValue={values.return_date}
              InputLabelProps={{ shrink: true }}
              onChange={(date) => {
                let value = date.target.value ? date.target.value : "";
                // if ( date.target.value ) {
                setChangeDate(value);
                // }
              }}
            />
          </Box>
          {(netTotal > 0 || collectedClient) && (
            <>
              <Box sx={{ mt: 2, mb: 2, width: 500 }}>
                <hr/>
              </Box>
              <Box sx={{ mt: 2, mb: 2, width: 500 }}>
                <Autocomplete
                  disablePortal
                  id="treasury"
                  helperText="Treasury"
                  aria-label="Treasury"
                  size="small"
                  fullWidth
                  options={treasures}
                  getOptionLabel={(option) => `${option.id} ${option.name}`} // Customize display
                  renderInput={(params) => (
                    <TextField required {...params} label={t("Treasury")} />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setTreasury(newValue.id);
                    } else {
                      setTreasury("");
                    }
                  }}
                />
              </Box>
            </>
          )}

          <Box
            sx={{
              mt: 3,
              // p: 3,
            }}
          >
            <Button
              color="primary"
              fullWidth
              // onClick={handleApply}
              variant="contained"
              type="submit"
            >
              Submit
            </Button>
          </Box>
          <Box
            sx={{
              mt: 1,
              // p: 3,
            }}
          >
            <Button color="error" fullWidth onClick={onClose} variant="contained">
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

SelectDateModal.propTypes = {
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  // customer: PropTypes.number,
  // cod: PropTypes.number,
};

export default SelectDateModal;
