import { useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import useMounted from "../../../../hooks/useMounted";
import useSettings from "../../../../hooks/useSettings";
import ChevronRightIcon from "../../../../icons/ChevronRight";
import gtm from "../../../../lib/gtm";
import { WithdrawalApi } from "../../../../__fakeApi__/WithdrawApi";
import RefreshIcon from "../../../../icons/Refresh";
import { useTranslation } from "react-i18next";
import { DateApi } from "../../../../__fakeApi__/DateApi";
import WithdrawListTable from "../withdraw/ListWithdrawTable";
// import WithdrawalDialog from "./WithdrawalDialog";
import toast from "react-hot-toast";
import { UserApi } from "../../../../__fakeApi__/UserApi";
import { TreasuryApi } from "../../../../__fakeApi__/treasury_apis";
import { ShipperApi } from "../../../../__fakeApi__/Shipper_apis";
import { EmployeeApi } from "../../../../__fakeApi__/EmployeeApi";
import { orderApi } from "../../../../__fakeApi__/orderApi";
import WithdrawalDialog from "../withdraw/WithdrawalDialog";
import {transaction_api} from "../../../../__fakeApi__/TransactionApi";
import ListTransactionTable from "./ListTransactionTable";
import {contentTypeAPI} from "../../../../__fakeApi__/ContentTypeAPI";
("./ListWithdrawTable");

const TransactionList = () => {
  const [ServerDate, setServerDate] = useState(DateApi.getDate());
  const mounted = useMounted();
  const { settings } = useSettings();
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [totalNetAmount, setTotalNetAmount] = useState([]);
  const [openBalance, setOpenBalance] = useState([]);
  const [closeBalance, setCloseBalance] = useState([]);
  const [treasuries, setTreasuries] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [contentTypeObjectList, setContentTypeObjectList] = useState([]);

  const initialState = {
    note: "",
    fromDate: format(ServerDate, "yyyy-MM-dd"),
    toDate: format(ServerDate, "yyyy-MM-dd"),
    amount: "",
    treasury: "",
    content_type: "",
    object_id: "",
    transaction_type: "",
    createdBy: "",
  };
  const [searchParams, setSearchParams] = useState(initialState);
  const getTreasuriesList = useCallback(async () => {
    try {
      let data = await TreasuryApi.get();
      setTreasuries(data);
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);
  const resetSearchParams = () => {
    setSearchParams(initialState);
  };

  const getServerDate = useCallback(async () => {
    try {
      let date = await DateApi.getServerDate();
      if (date && date.server_date) {
        setServerDate(DateApi.getDate(date.server_date));
        DateApi.getDate(date.server_date);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getTransactionList = useCallback(async () => {
    try {
      let data = await transaction_api.get(searchParams);
      let transactions = data.transactions.map(transaction => {
        if (transaction.transaction_type === "Withdrawal") {
            return { ...transaction, amount: parseFloat(transaction.amount * -1).toFixed(2) }; // 10% raise
        } else {
            return transaction; // No change
        }
      });
      setTransactions(transactions);
      setTotalNetAmount(data.total_net_amount);
      setOpenBalance(data.open_balance);
      setCloseBalance(data.close_balance);
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  }, [mounted, searchParams]);

  const getContentTypes = async () => {
    try {
      const contentTypeData = await contentTypeAPI.get();
      setContentTypes(contentTypeData);
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  };

  const getContentTypeObjectList = async (contentTypeID) => {
    try {
      const contentTypeObjectListData = await contentTypeAPI.getContentTypeObjectList(contentTypeID);
      setContentTypeObjectList(contentTypeObjectListData);
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  };

  useEffect(() => {
    getServerDate();
    getTransactionList();
    getTreasuriesList();
    getContentTypes();
  }, [getTransactionList]);

  return (
      <>
        <Helmet>
          <title>Dashboard: Withdrawal List</title>
        </Helmet>
        <Box
            sx={{
              backgroundColor: "background.default",
              minHeight: "50%",
              Height: "50%",
              py: 8,
            }}
        >
          <Container maxWidth={settings.compact ? "xl" : false}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography color="textPrimary" variant="h5">
                  {t("Transaction List")}
                </Typography>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<ChevronRightIcon fontSize="small" />}
                    sx={{ mt: 1 }}
                >
                  <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="/dashboard/"
                      variant="subtitle2"
                  >
                    {t("Dashboard")}
                  </Link>
                  <Typography color="textSecondary" variant="subtitle2">
                    {t("Transaction List")}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item>
                <Stack direction={"row"} spacing={2}>
                  <Button
                      color="primary"
                      startIcon={<RefreshIcon fontSize="small" />}
                      sx={{ m: 1, backgroundColor: "#DF2F2F" }}
                      onClick={(e) => window.location.reload()}
                      variant="contained"
                  >
                    {t("Refresh")}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
              <ListTransactionTable
                  treasuries={treasuries}
                  transactions={transactions}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  resetSearchParams={resetSearchParams}
                  getTransactionList={getTransactionList}
                  setTransactions={setTransactions}
                  contentTypes={contentTypes}
                  getContentTypeObjectList={getContentTypeObjectList}
                  contentTypeObjectList={contentTypeObjectList}
                  initialState={initialState}
                  setContentTypeObjectList={setContentTypeObjectList}
                  totalNetAmount={totalNetAmount}
                  openBalance={openBalance}
                  closeBalance={closeBalance}
              />
            </Box>
          </Container>
        </Box>
      </>
  );
};

export default TransactionList;