import toast from "react-hot-toast";
import {
  getAccessToken,
  fetch_instance,
  ServerURL,
  TreasuryUrl,
} from "./ServerConfig";

class treasury_api {
  async getById(id) {
    let response = [];
    await fetch_instance(`${ServerURL}${TreasuryUrl}${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        response = data;
        return Promise.resolve(response);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(response);
  }

  async get(name, openBalance) {
    let response = [];
    await fetch_instance(
      `${ServerURL}${TreasuryUrl}?name=${name || ""}&open_balance=${openBalance || ""}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        response = data;
        return Promise.resolve(response);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(response);
  }

  async create(payload) {
    let response = {};
    await fetch_instance(`${ServerURL}${TreasuryUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((res) => {
      response = res;
    });
    return Promise.resolve(response);
  }

  async update(treasury) {
    let response = {};
    await fetch_instance(`${ServerURL}${TreasuryUrl}${treasury.id}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(treasury),
    }).then((res) => {
      response = res;
    });
    return Promise.resolve(response);
  }

  async delete(id) {
    let response = {};
    await fetch_instance(`${ServerURL}${TreasuryUrl}${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }).then((res) => {
      response = res;
    });
    return Promise.resolve(response);
  }

  async withdraw(payload) {
    let response = {};
    let treasury_id = payload.treasury
    await fetch_instance(`${ServerURL}${TreasuryUrl}${treasury_id}/withdraw/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((res) => {
      response = res;
    });
    return Promise.resolve(response);
  }

  async deposit(payload) {
    let response = {};
    let treasury_id = payload.treasury
    console.log('deposit action --> ', payload)
    await fetch_instance(`${ServerURL}${TreasuryUrl}${treasury_id}/deposit/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((res) => {
      console.log(res)
      response = res;
    });
    return Promise.resolve(response);
  }

  async transfer(payload) {
    let response = {};
    let treasury_id = payload.from_treasury
    await fetch_instance(`${ServerURL}${TreasuryUrl}${treasury_id}/transfer/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(payload),
    }).then((res) => {
      response = res;
    });
    return Promise.resolve(response);
  }

  async getTrasactions(searchParams) {
    let response = {};
    if (searchParams.treasury) {
      await fetch_instance(`${ServerURL}${TreasuryUrl}${searchParams.treasury}/transactions?from_date=${searchParams.fromDate || ""}&to_date=${searchParams.toDate || ""}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }).then((res) => {
        response = res.json();
      });
      return Promise.resolve(response);
    } else {
      return response
    }
  }

  async getCurrentBalance() {
    let response = {};
      await fetch_instance(`${ServerURL}${TreasuryUrl}current-balance`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }).then((res) => {
        response = res.json();
      });
      return Promise.resolve(response);
  }

  async exportTreasuriesBalance() {
      toast.success("File to be downloaded");
      fetch_instance(`${ServerURL}${TreasuryUrl}export-current-balance-sheet`, {
        method: "GET",
        headers: {
          "Content-Type": "application/vnd.ms-excel",
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          if (blob.size > 0) {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `treasuires_balance${Date.now().toString()}.xls`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();
            toast.success("Download is Done");

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          } else {
            toast.error("Empty Data");
          }
        })
        .catch((e) => {
          toast.error("Something wrong");
        });
    }

  async treasuryListUserCanDeposit() {
    let response = [];
    await fetch_instance(
      `${ServerURL}${TreasuryUrl}treasury_list_user_can_deposit/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        response = data;
        return Promise.resolve(response);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(response);
  }
}

export const TreasuryApi = new treasury_api();
