import { useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Scrollbar from "../../Scrollbar";

import styles from "../Styles";
// import ChangeStatusModel from "./ChangeStatusModel";
import useAuth from "../../../hooks/useAuth";

const sortOptions = [
  {
    label: "Name Descending",
    value: "name|desc",
  },
  {
    label: "Name Ascending",
    value: "name|asc",
  },
];

const applyFilters = (row, query, filters) =>
  row.filter((row) => {
    let matches = true;
    if (
      query &&
      !row.company_name.toLowerCase().includes(query.toLowerCase())
    ) {
      matches = false;
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && row[key] !== value) {
        matches = false;
      }
    });
    return matches;
  });

const applyPagination = (dataList, page, limit) => {
  let result = [];
  if (dataList && dataList.length !== 0) {
    result = dataList.slice(page * limit, page * limit + limit);
  }
  return result;
};

const AgentCommissionsTable = (props) => {
  const { dataList, fildata, query, setFilterdData, GetDataList, Crudpermession, ...other } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [filters, setFilters] = useState({});
  const { t } = useTranslation();
  const { user } = useAuth();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const filteredProducts = applyFilters(dataList, query, filters);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 1200 }}>
          <Table id="data-table" size="small">
            <TableHead>
              <TableRow>
                <TableCell style={styles.table_head} align="center">{t("Agent")}</TableCell>
                <TableCell style={styles.table_head} align="center">{t("Month")}</TableCell>
                <TableCell style={styles.table_head} align="center">{t("Total Orders")}</TableCell>
                <TableCell style={styles.table_head} align="center">{t("Average Orders Fess")}</TableCell>
                <TableCell style={styles.table_head} align="center">{t("Orders Commissions")}</TableCell>
                <TableCell style={styles.table_head} align="center">{t("Total Customers")}</TableCell>
                <TableCell style={styles.table_head} align="center">{t("Customers Commissions")}</TableCell>
                <TableCell style={styles.table_head} align="center">{t("Total Commissions")}</TableCell>
                <TableCell style={styles.table_head} align="center">{t("Manager Orders Commissions")}</TableCell>
                <TableCell style={styles.table_head} align="center">{t("Manager Customers Commissions")}</TableCell>
                <TableCell style={styles.table_head} align="center">{t("Total Manager Commissions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProducts.length !== 0 &&
                paginatedProducts.map((row) => {
                  const isProductSelected = selectedRows.includes(row.id);
                  return (
                    <TableRow
                      hover
                      selected={isProductSelected}
                      sx={{ height: 1 }}
                    >
                      <TableCell align="center">{row.agent}</TableCell>
                      <TableCell align="center">{row.month}</TableCell>
                      <TableCell align="center">{row.total_orders}</TableCell>
                      <TableCell align="center">{row.average_fees}</TableCell>
                      <TableCell align="center">{row.orders_commission}</TableCell>
                      <TableCell align="center">{row.total_customers}</TableCell>
                      <TableCell align="center">{row.customers_commission}</TableCell>
                      <TableCell align="center">{row.total_commission}</TableCell>
                      <TableCell align="center">{row.manager_orders_commission}</TableCell>
                      <TableCell align="center">{row.manager_customers_commission}</TableCell>
                      <TableCell align="center">{row.total_manager_commission}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredProducts.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[50, 100, 500]}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

AgentCommissionsTable.propTypes = {
  dataList: PropTypes.array.isRequired,
  query: PropTypes.string.isRequired,
  setFilterdData: PropTypes.func.isRequired,
};

export default AgentCommissionsTable;