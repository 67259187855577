import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useState,
} from "react";
import PropTypes from "prop-types";
import { authApi } from "../__fakeApi__/authApi";
import LogoutPopup from "./timer";
import {
  getAccessToken,
  SessionTimeinMinutes,
} from "../__fakeApi__/ServerConfig";
import { ShipperApi } from "../__fakeApi__/Shipper_apis";
import { customerApi } from "../__fakeApi__/customerApi";
import useAuth from "../hooks/useAuth";
import { orderApi } from "../__fakeApi__/orderApi";
const customer_field_map = {
  phone: {
    all_perm: "all_customer_phone",
  },
  address: {
    all_perm: "all_customer_address",
  },
  pickup_request: {
    all_perm: "all_customer_pickup_request",
  },
  collect_request: {
    all_perm: "all_customer_collect_request",
  },
  material_request: {
    all_perm: "all_customer_material_request",
  },
  addOrder: {
    all_perm: "all_addOrder",
  },
  updateOrder: {
    all_perm: "all_updateOrder",
  },
  deleteOrder: {
    all_perm: "all_deleteOrder",
  },
  returnclient: {
    all_perm: "all_returnclient",
  },
  collectclient: {
    all_perm: "all_collectclient",
  },
  revnue: {
    all_perm: "all_revnue",
  },
  view: {
    all_perm: "all_customer_view",
  },
  customer_view_report: {
    all_perm: "all_customer_view_report",
  },
};

const shipper_field_map = {
  phone: {
    // list_name: "PhoneBlocked",
    // all_list_name: "ShipperUserPermissions",
    all_perm: "all_shipper_phone",
  },
  address: {
    // list_name: "AddressBlocked",
    // all_list_name: "ShipperUserPermissions",
    all_perm: "all_shipper_address",
  },
};
const initialState = {
  hasPermission: () => 0,
  showAllField: () => 0,
  hasOrderPermission : () => 0 ,
  allCustomerPermission: {},
  allShipperPermission: {},
  userpermession: {},
};

const handlers = {
  REGISTER: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const PermissionContext = createContext({
  ...initialState,
});

export const PermissionProvider = (props) => {
  const { children } = props;

  const [allCustomerPermission, setAllCustomerPermission] = useState({});
  const [allShipperPermission, setAllShipperPermission] = useState({});
  const [userpermession, setUserPermission] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated } = useAuth();

  const getShipperPermission = useCallback(async () => {
    try {
      if (!isAuthenticated) return;
      const data = (await ShipperApi.getAllUserShipperPermission()) || {};
      let allShipperPermission = data || {};
      setAllShipperPermission(allShipperPermission);
      //   dispatch({
      //     type: "REGISTER",
      //     payload: {
      //       allShipperPermission,
      //     },
      //   });
    } catch (err) {
      console.error(err);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    getShipperPermission();
  }, [getShipperPermission]);
  const getCustomerPermission = useCallback(async () => {
    try {
      if (!isAuthenticated) return;
      const data =
        (await customerApi.getAllUserCustomerPermission()) || {};
      let allCustomerPermission = data || {};
      setAllCustomerPermission(allCustomerPermission);
     
    } catch (err) {
      console.error(err);
    }
  }, [isAuthenticated]);

  const getUserPermessions = useCallback(async () => {
    try {
       
      if (!isAuthenticated) return;
      

      const userpermession = await orderApi.getUserPermession();
      // console.log("userpermession userpermession " , userpermession)

      setUserPermission(userpermession || {});
    } catch (err) {
      console.error(err);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    getUserPermessions();
  }, [getUserPermessions]);
  useEffect(() => {
    getCustomerPermission();
  }, [getCustomerPermission]);

  const hasPermission = (field, id, type = "shipper", user = null) => {
    if (!user) {
      const temp = useAuth();
      user = temp.user;
    }

    if (!user) return 0;

    if (user.is_superuser) {
      return 1;
    }
    if (!id) {
      return 1;
    }

    let allPermissionList =
      type == "customer"
        ? allCustomerPermission || {}
        : allShipperPermission || {};

    let field_map = type == "customer" ? customer_field_map : shipper_field_map;
    let fieldname = type == "customer" ? "customer_id" : "shipper_id";
    // let permissionList = allPermissionList[field_map[field].all_list_name] || [];

    let permissionList =
      (type == "customer"
        ? allPermissionList.CustomerUserPermission
        : allPermissionList.ShipperUserPermissions) || [];

    let all_perm = (allPermissionList.fieldpermission || {})[
      field_map[field].all_perm
    ];
    if (permissionList.length == 0) {
      return 0;
    }
    if (all_perm) {
      return 1;
    }
    let row = permissionList.find((x) => x[fieldname] == id && x["user_id"] === user.id);
    if (row) {
      // console.log(field, " ===> ", row[field]);
    }
    return row ? row[field] : 0;
  };

  const showAllField = (field, type = "shipper", user = null) => {
    if (!user) {
      const temp = useAuth();
      user = temp.user;
    }

    if (!user) return 0;

    if (user.is_superuser) {
      return 1;
    }
    let field_map = type == "customer" ? customer_field_map : shipper_field_map;

    let allPermissionList =
      type == "customer"
        ? allCustomerPermission || {}
        : allShipperPermission || {};
    let permissionList =
      (type == "customer"
        ? allPermissionList.CustomerUserPermission
        : allPermissionList.ShipperUserPermissions) || [];
    let all_perm = (allPermissionList.fieldpermission || {})[
      field_map[field].all_perm
    ];

    let BlockedList = permissionList.filter((x) => !x[field]);
    if (BlockedList.length == 0) {
      return 1;
    }
    if (all_perm) {
      return 1;
    }
    return (
      permissionList.length != BlockedList.length || BlockedList.length == 0
    );
  };

  const hasOrderPermission = (field, user = null) => {

    if (!user) {
      const temp = useAuth();
      user = temp.user;
    }
    if (!user) return 0;
    if (user.is_superuser) {
      return 1;
    }
    return userpermession ? userpermession[field] : 0;
  };

  const setHasPermission = useCallback(async () => {
    try {
      dispatch({
        type: "REGISTER",
        payload: {
          hasPermission: hasPermission,
          showAllField: showAllField,
          hasOrderPermission:hasOrderPermission
        },
      });
    } catch (err) {
      console.error(err);
    }
  }, [isAuthenticated, allCustomerPermission, allShipperPermission]);

  useEffect(() => {
    setHasPermission();
  }, [setHasPermission]);

  return (
    <>
      <PermissionContext.Provider
        value={{
          ...state,
        }}
      >
        {children}
      </PermissionContext.Provider>
    </>
  );
};

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PermissionContext;
