import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import numeral from "numeral";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import ArrowRightIcon from "../../../icons/ArrowRight";
import ImageIcon from "../../../icons/Image";
import PencilAltIcon from "../../../icons/PencilAlt";
import SearchIcon from "../../../icons/Search";
import Scrollbar from "../../Scrollbar";
import { holdReasonApi } from "../../../__fakeApi__/HoldReasonApi";
import { CustomerCategoryApi } from "../../../__fakeApi__/CustomerCategoryApi";
import { useTranslation } from "react-i18next";
import styles from "../Styles";
import useAuth from "../../../hooks/useAuth";
import {shipperCategoryApi} from "../../../__fakeApi__/ShipperCategoryApi";
const sortOptions = [
  {
    label: "Name Descending",
    value: "name|desc",
  },
  {
    label: "Name Ascending",
    value: "name|asc",
  },
];

const applyFilters = (row, query, filters) =>
  row.filter((row) => {
    let matches = true;

    if (
      query &&
      !row.name.toLowerCase().includes(query.toLowerCase()) &&
      !row.notes.toLowerCase().includes(query.toLowerCase())
    ) {
      matches = false;
    }
    return matches;
  });

const applyPagination = (dataList, page, limit) => {
  let result = [];
  if (dataList && dataList.length !== 0) {
    result = dataList.slice(page * limit, page * limit + limit);
  }
  return result;
};

const ShipperCategoryTable = (props) => {
  const { dataList, ...other } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const { user } = useAuth();
  const { t } = useTranslation();
  const [sort, setSort] = useState(sortOptions[0].value);
  const navigate = useNavigate();
  // let urls=localStorage.getItem("permitted_url") || ''
  let urls = user.permitted_url || ["dashboard"];
  // urls=urls.split(',')
  const [filters, setFilters] = useState({
    name: null,
  });

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSelectAllProducts = (event) => {
    setSelectedRows(event.target.checked ? dataList.map((row) => row.code) : []);
  };

  const handleSelectOneProduct = (event, rowId) => {
    console.log(rowId)
    if (!selectedRows.includes(rowId)) {
      setSelectedRows((prevSelected) => [...prevSelected, rowId]);
    } else {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => id !== rowId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };
  async function deleteAction(event) {
    if (selectedRows.length > 0) {
      selectedRows.forEach(async (e) => {
        let result = await shipperCategoryApi.delete_category(e);
      });
      window.location.reload();
    }
  }

  // Usually query is done on backend with indexing solutions
  const filteredProducts = applyFilters(dataList, query, filters);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);
  const enableBulkActions = selectedRows.length > 0;
  const selectedSomeProducts =
    selectedRows.length > 0 && selectedRows.length < filteredProducts.length;
  const selectedAllProducts = selectedRows.length === filteredProducts.length;

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 500,
          }}
        >
          <TextField
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder={t("Search")}
            value={query}
            variant="outlined"
          />
        </Box>
      </Box>

      {enableBulkActions && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              backgroundColor: "background.paper",
              mt: "6px",
              pl: "4px",
              position: "absolute",
              pr: "4px",
              width: "100%",
              zIndex: 2,
            }}
          >
            <Checkbox
              checked={selectedAllProducts}
              color="primary"
              indeterminate={selectedSomeProducts}
              onChange={handleSelectAllProducts}
            />
            {user.is_superuser ||
            urls.includes("/dashboard/mandoob_categories/delete/") == true ? (
              <Button
                color="primary"
                sx={{ ml: 2 }}
                variant="outlined"
                onClick={deleteAction}
              >
                {t("Delete")}
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Box>
      )}
      <Scrollbar>
        <Box sx={{ minWidth: 1200 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={styles.table_head} padding="checkbox">
                  <Checkbox
                    checked={selectedAllProducts}
                    color="primary"
                    indeterminate={selectedSomeProducts}
                    onChange={handleSelectAllProducts}
                  />
                </TableCell>
                {/* <TableCell style={styles.table_head}>Code</TableCell> */}
                <TableCell style={styles.table_head}>{t("Code")}</TableCell>
                <TableCell style={styles.table_head}>{t("Name")}</TableCell>
                <TableCell style={styles.table_head}>{t("Notes")}</TableCell>
                <TableCell style={styles.table_head} align="right">
                  {t("Actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProducts.length !== 0 &&
                paginatedProducts.map((row) => {
                  const isProductSelected = selectedRows.includes(row.code);

                  return (
                    <TableRow
                      hover
                      key={row.id}
                      selected={isProductSelected}
                      sx={{ height: 1 }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isProductSelected}
                          color="primary"
                          onChange={(event) =>
                            handleSelectOneProduct(event, row.code)
                          }
                          value={isProductSelected}
                        />
                      </TableCell>
                      {/* <TableCell align="center">{row.code}</TableCell> */}
                      <TableCell align="center">{row.code}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.notes}</TableCell>

                      <TableCell align="center">
                        {user.is_superuser ||
                        urls.includes("/dashboard/mandoob_categories/edit/") ==
                          true ? (
                          <IconButton
                            onClick={() => {
                              navigate(
                                `/dashboard/mandoob_categories/edit/${row.code}`,
                                {
                                  state: {
                                    id: row.id,
                                  },
                                }
                              );
                            }}
                            // component={RouterLink}
                            // to= {`/dashboard/pricing/edit/${product.id}`}
                          >
                            <PencilAltIcon fontSize="small" />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredProducts.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

ShipperCategoryTable.propTypes = {
  dataList: PropTypes.array.isRequired,
};

export default ShipperCategoryTable;
