import toast from "react-hot-toast";
import { identifier } from "stylis";
import useAuth from "../hooks/useAuth";
import {
  ServerURL,
  getAccessToken,
  fetch_instance,
  getNotificationListMethodURL,
  CreateCustomersNotificationsMethodURL,
  MarkAllAsReadApiMethodUrl,
  MarkCustomerAllAsReadApiMethodUrl,
  MarkCustomerAsReadApiMethodUrl,
  getCustomerNotificationsApiMethodUrl,
  MarkAllRepeatedOrderAsReadApiMethodUrl,
  MarkAsReadApiMethodUrl,
  getOrderAlertListMethodURL,
  UserNotificationPermissionApiMethodUrl,
  getRepeatedOrdersListMethodURL,
} from "./ServerConfig";

class notifications_api {
  async get_user_permission(user_id = 0) {
    let obj = [];
    await fetch_instance(
      `${ServerURL}${UserNotificationPermissionApiMethodUrl}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          user: user_id,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }
  async save_user_permission(permissions = []) {
    let response;

    await fetch_instance(
      `${ServerURL}${UserNotificationPermissionApiMethodUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          permissions: permissions || [],
        }),
      }
    )
      .then((response) => {
        return response;
      })
      .then((data) => {
        response = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        // toast.error(error.toString());
      });
    return Promise.resolve(response);
  }

  async get_notifications(user_id=0, notification_type="") {
    let obj = [];
    await fetch_instance(
      `${ServerURL}${getNotificationListMethodURL}?id=${user_id}&notification_type=${notification_type || ""}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length) {
          obj = data;
        }

        // return Promise.resolve(obj)
      })
      .catch((error) => {
        let err = error.toString();
        if (err.includes("Failed to fetch") || err.includes("NetworkError")) {
          // if (err == "TypeError: Failed to fetch" || err == "TypeError: NetworkError when attempting to fetch resource.") {
          toast.error(`Something went wrong Please Reload and try again`);
        }
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async get_order_alerts(user_id = 0) {
    let obj = [];
    await fetch_instance(
      `${ServerURL}${getOrderAlertListMethodURL}?id=${user_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length) {
          obj = data;
        }

        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async getRepeatedOrdersList(user_id = 0) {
    let obj = [];
    await fetch_instance(
      `${ServerURL}${getRepeatedOrdersListMethodURL}?id=${user_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length) {
          obj = data;
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async mark_as_read(user_id = 0, notifications = []) {
    let response;

    await fetch_instance(`${ServerURL}${MarkAsReadApiMethodUrl}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        for_user: parseInt(user_id, 10),
        notifications: notifications || [],
      }),
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        response = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        // toast.error(error.toString());
      });
    return Promise.resolve(response);
  }
  async mark_all_as_read(user_id = 0) {
    let response;
    await fetch_instance(`${ServerURL}${MarkAllAsReadApiMethodUrl}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        for_user: user_id,
      }),
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        response = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        // toast.error(error.toString());
      });
    return Promise.resolve(response);
  }

  async mark_allRepeatedOrder_as_read(user_id = 0) {
    let response;
    await fetch_instance(
      `${ServerURL}${MarkAllRepeatedOrderAsReadApiMethodUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          for_user: user_id,
        }),
      }
    )
      .then((response) => {
        return response;
      })
      .then((data) => {
        response = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        // toast.error(error.toString());
      });
    return Promise.resolve(response);
  }


  async mark_customer_as_read(id) {
    let response;

    await fetch_instance(`${ServerURL}${MarkCustomerAsReadApiMethodUrl}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type":"application/json"
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        response = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        // toast.error(error.toString());
      });
    return Promise.resolve(response);
  }
  async mark_all_customer_as_read() {
    let response;
    await fetch_instance(`${ServerURL}${MarkCustomerAllAsReadApiMethodUrl}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        response = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        // toast.error(error.toString());
      });
    return Promise.resolve(response);
  }

  async create_customer_notifications(body) {
    let response;
    await fetch_instance(
      `${ServerURL}${CreateCustomersNotificationsMethodURL}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response;
      })
      .then((data) => {
        response = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        // toast.error(error.toString());
      });
    return Promise.resolve(response);
  }

  async get_customer_notifications() {
    let obj = {};
    await fetch_instance(
      `${ServerURL}${getCustomerNotificationsApiMethodUrl}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
          obj = data;

        return Promise.resolve(obj)
      })
      .catch((error) => {
        let err = error.toString();
        if (err.includes("Failed to fetch") || err.includes("NetworkError")) {
          toast.error(`Something went wrong Please Reload and try again`);
        }
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }
}
export const NotificationsApi = new notifications_api();
