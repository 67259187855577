import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import { getOrderStatusLabel } from "../../../__fakeApi__/OrderStatus";

import {
  Box,
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Stack, Link,
} from "@material-ui/core";
import Scrollbar from "../../Scrollbar";
import styles from "../Styles";
import { useTranslation } from "react-i18next";
import SelectDateModal from "../adminorder/SelectDateModal";
import {orderApi} from "../../../__fakeApi__/orderApi";
import toast from "react-hot-toast";
import OrderUpdateModel from "../adminorder/OrderUpdateModel";
import {Link as RouterLink} from "react-router-dom";
import {TreasuryApi} from "../../../__fakeApi__/treasury_apis";

const applyFilters = (row, query, filters) =>
    row.filter((row) => {
      let matches = true;
      if (query && !row.full_name.toLowerCase().includes(query.toLowerCase())) {
        matches = false;
      }

      Object.keys(filters).forEach((key) => {
        const value = filters[key];

        if (value && row[key] !== value) {
          matches = false;
        }
      });
      return matches;
    });

const applyPagination = (dataList, page, limit) => {
  let result = [];
  if (dataList && dataList.length !== 0) {
    result = dataList.slice(page * limit, page * limit + limit);
  }
  return result;
};

const CustomerDebtOrdersDetailsTable = (props) => {
  const { dataList, GetDataList, query, setFilterdData, userPermissions, user, customerID,accountNotes, ...other } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [totalSelectCod, sefTotalSelectCod] = useState(0);
  const [limit, setLimit] = useState(100);
  const [filters, setFilters] = useState({});
  const { t } = useTranslation();
  const [selectedDateModelIsOpen, setSelectedDateModelIsOpen] = useState(false);
  const [OrderUpdateModelIsOpen, setOrderUpdateModelIsOpen] = useState(false);
  const [OrderUpdateModelSelectedOrder, setOrderUpdateModelSelectedOrder] = useState();
  const [OrderUpdateModelSelectedField, setOrderUpdateModelSelectedField] = useState();
  const [treasuries, setTreasuries] = useState([]);

  const handleSelectAllProducts = (event) => {
    setSelectedRows(event.target.checked ? dataList.map((row) => row.serial) : []);
    if (event.target.checked) {
      const totalCOD = dataList.reduce((accumulator, order) => {
        return accumulator + order.calc_cod;
      }, 0);
      sefTotalSelectCod(totalCOD);
    } else {
      sefTotalSelectCod(0);
    }
  };

  const handleSelectOneProduct = (event, rowId, cod) => {
    if (!selectedRows.includes(rowId)) {
      setSelectedRows((prevSelected) => [...prevSelected, rowId]);
      sefTotalSelectCod(totalSelectCod + cod)
    } else {
      setSelectedRows((prevSelected) =>
          prevSelected.filter((serial) => serial !== rowId)
      );
      sefTotalSelectCod(totalSelectCod - cod)
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  // Usually query is done on backend with indexing solutions
  const filteredProducts = applyFilters(dataList, query, filters);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);
  const selectedSomeProducts = selectedRows.length > 0 && selectedRows.length < filteredProducts.length;
  const selectedAllProducts = selectedRows.length === filteredProducts.length;

  const handleApplySelectedDateModel = async (date, treasury) => {
    let obj = {
      orders: selectedRows,
      Flag: "4",
      SelectedDate: date,
      treasury: treasury
    };
    let response = await orderApi.UpdateQuickAction(obj);
    try {
      if (response.ok) {
        response.json().then((result) => {
          setSelectedRows([])
          sefTotalSelectCod(0);
          toast.success(`[${selectedRows}] Orders have been collected Successfully`);
          GetDataList();
        });
      } else {
        response.json().then((result) => {
          if (result.error_list && result.error_list.length) {
            // handlesAlertModalOpen(result.error_list || []);
          }
          toast.error(result.message);
        });
      }
    } catch (err) {
      // alert(err)
      console.error(err);
    }
    // SelectedDate = date;
    // UpdateQuickActionFunc(null, true);
    setSelectedDateModelIsOpen(false);
  };
  const handleCloseSelectedDateModel = () => {
    setSelectedDateModelIsOpen(false);
  };
  const handleOpenSelectedDateModel = () => {
    // selectedDate = null;
    setSelectedDateModelIsOpen(true);
  };

  const handleUpdateOrderModalOpen_event = (
      open = false,
      order = null,
      field = null
  ) => {
    setOrderUpdateModelIsOpen(open);
    setOrderUpdateModelSelectedOrder(order);
    setOrderUpdateModelSelectedField(field)
  };

  const handleOpenUpdateTotalFessModel= async (row) => {
    if (user.is_superuser) {
      handleUpdateOrderModalOpen_event(true, row, 'total_fees');
    } else if (userPermissions.can_edit_price) {
      if (userPermissions.all_updateOrder) {
        handleUpdateOrderModalOpen_event(true, row, 'total_fees');
      } else {
        const result = await orderApi.get_hasOrderPermession(row.customer, row.shipper);
        if (result.CustomerUserPermission.updateOrder) {
          handleUpdateOrderModalOpen_event(true, row, 'total_fees')
        } else {
          toast.error("You don't have permission to update this Customer order");
        }
      }
    } else {
      toast.error("You don't have permission to update this order")
    }
  };

  const handleUpdateOrderModalApply = () => {
    setOrderUpdateModelIsOpen(false);
    GetDataList();
  };

  const handleUpdateOrderModalClose = () => {
    setOrderUpdateModelIsOpen(false);
  };

  const getTreasuriesList = useCallback(async () => {
    try {
      let data = await TreasuryApi.get(null, null);
      setTreasuries(data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getTreasuriesList()
  }, []);

  return (
      <Card {...other}>
        <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              m: -1,
              p: 2,
            }}
        ></Box>

        <Scrollbar>
          <Stack
              direction="row"
              justifyContent="space-between"
          >
            <div></div>
            <div>
              <p>
                Total COD = {totalSelectCod}, Account Notes = {accountNotes}
              </p>
              <SelectDateModal
                  open={selectedDateModelIsOpen}
                  onClose={handleCloseSelectedDateModel}
                  onApply={handleApplySelectedDateModel}
                  // treasures={treasuries}
                  // collectedClient={true}
              />
            </div>
            <div>
              <Link
                component={RouterLink}
                to={`/dashboard/ReturnPriceList/${customerID}`}
                target="_blank"
                state={{
                  id: {customerID},
                }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  sx={{ mt: 1, ml: 1 }}
                  onClick={() => {}}
                >
                  {t("Return Price List")}
                </Button>
              </Link>
              <Button
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  disabled={!(selectedRows.length > 0)}
                  onClick={handleOpenSelectedDateModel}
              >
                {selectedRows.length ? ( `(${selectedRows.length})` ): ''} {t("Collected Client")}
              </Button>
            </div>
            {OrderUpdateModelIsOpen &&
            OrderUpdateModelSelectedOrder &&
            OrderUpdateModelSelectedField ? (
                <OrderUpdateModel
                    onApply={handleUpdateOrderModalApply}
                    onClose={handleUpdateOrderModalClose}
                    open={OrderUpdateModelIsOpen}
                    order={OrderUpdateModelSelectedOrder}
                    field={OrderUpdateModelSelectedField}
                    userpermession={userPermissions}
                />
            ) : (
                ""
            )}
          </Stack>
          <Box sx={{ minWidth: 1200 }}>

            <Table id="data-table" sx={{ height: "100%", width: "100%" }}>
              <TableHead>
                <TableRow style={styles.h4}>
                  <TableCell padding="checkbox">
                    <Checkbox
                        checked={selectedAllProducts}
                        color="primary"
                        indeterminate={selectedSomeProducts}
                        onChange={handleSelectAllProducts}
                    />
                  </TableCell>
                  <TableCell style={styles.table_head}>{t("Serial")}</TableCell>
                  <TableCell style={styles.table_head}>{t("City")}</TableCell>
                  <TableCell style={styles.table_head}>{t("STATUS")}</TableCell>
                  <TableCell style={styles.table_head}>{t("STATUS NOTE")}</TableCell>
                  <TableCell style={styles.table_head}>{t("OPERATION NOTES")}</TableCell>
                  <TableCell style={styles.table_head}>{t("Delivery Date")}</TableCell>
                  <TableCell style={styles.table_head}>{t("Shipper Collect Date")}</TableCell>
                  <TableCell style={styles.table_head}>{t("Amount")}</TableCell>
                  <TableCell style={styles.table_head}>{t("Fees")}</TableCell>
                  <TableCell style={styles.table_head}>{t("Original Fees")}</TableCell>
                  <TableCell style={styles.table_head}>{t("Mandoob Fees")}</TableCell>
                  <TableCell style={styles.table_head}>{t("COD")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedProducts.length !== 0 &&
                    paginatedProducts.map((row) => {
                      const isProductSelected = selectedRows.includes(row.serial);

                      return (
                          <TableRow
                              hover
                              // key={row.id}
                              selected={isProductSelected}
                              sx={{ height: 1 }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                  checked={isProductSelected}
                                  color="primary"
                                  onChange={(event) => handleSelectOneProduct(
                                      event, row.serial, row.calc_cod
                                  )}
                                  value={isProductSelected}
                              />
                            </TableCell>
                            <TableCell align="center">{row.serial}</TableCell>
                            <TableCell align="center">{row.city}</TableCell>
                            <TableCell align="center">{getOrderStatusLabel(row.Order_Delivery_Status)}</TableCell>
                            <TableCell align="center">{row.StatusNote}</TableCell>
                            <TableCell align="center">{row.operation_notes}</TableCell>
                            <TableCell align="center">{row.delivery_status_date}</TableCell>
                            <TableCell align="center">{row.collected_shipper_date}</TableCell>
                            <TableCell align="center">{row.total_amount}</TableCell>
                            <TableCell
                                align="center"
                                onDoubleClick={() => handleOpenUpdateTotalFessModel(row)}
                            >
                              {row.total_fees}
                            </TableCell>
                            <TableCell align="center">{row.city_total_fees}</TableCell>
                            <TableCell align="center">{row.shipper_fees}</TableCell>
                            <TableCell align="center">{row.calc_cod}</TableCell>
                          </TableRow>
                      );
                    })}
              </TableBody>
            </Table>

            <TablePagination
                component="div"
                count={filteredProducts.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[100, 200, 300]}
            />
          </Box>
        </Scrollbar>
      </Card>
  );
};

CustomerDebtOrdersDetailsTable.propTypes = {
  dataList: PropTypes.array.isRequired,
  query: PropTypes.string.isRequired,
  setFilterdData: PropTypes.func.isRequired,
};

export default CustomerDebtOrdersDetailsTable;