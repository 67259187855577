import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { user } = useAuth();
  // const permitted_url = (localStorage.getItem("permitted_url") || '').split(',') || []
  if (isAuthenticated && user) {
    const permitted_url = user.permitted_url || ["/dashboard"];
    if (user.is_staff == false && user.is_superuser == false) {
      return <Navigate to="/customerdashboard/customeranalytics" />;
    } else if (
      permitted_url &&
      permitted_url.find((x) =>
        x.toLowerCase().includes("/dashboard/analytics")
      )
    ) {
      return <Navigate to="/dashboard/analytics" />;
    } else {
      return <Navigate to="/dashboard/" />;
    }
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
