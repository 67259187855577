import React, { useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Container,
    Grid,
    Link,
    Stack, TextField,
    Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import useMounted from "../../../../hooks/useMounted";
import useSettings from "../../../../hooks/useSettings";
import ChevronRightIcon from "../../../../icons/ChevronRight";
import gtm from "../../../../lib/gtm";
import RefreshIcon from "../../../../icons/Refresh";
import { useTranslation } from "react-i18next";
import { DateApi } from "../../../../__fakeApi__/DateApi";
import toast from "react-hot-toast";
import { TreasuryApi } from "../../../../__fakeApi__/treasury_apis";
import {transaction_api} from "../../../../__fakeApi__/TransactionApi";
import TransactionSummaryTable from "./TransactionSummaryTable";
import DownloadIcon from "../../../../icons/Download";
import {ExportToExcelApis} from "../../../../__fakeApi__/ExportToExcel";
import {treasuryUserAPI} from "../../../../__fakeApi__/TreasuryUserApi";
("./ListWithdrawTable");

const TransactionSummary = () => {
    const [ServerDate, setServerDate] = useState(DateApi.getDate());
    const mounted = useMounted();
    const { settings } = useSettings();
    const { t } = useTranslation();
    const [transactionsSummaries, setTransactionsSummaries] = useState([]);
    const [treasuries, setTreasuries] = useState([]);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();
    const [totalTotalIn, setTotalTotalIn] = useState("");
    const [totalTotalOut, setTotalTotalOut] = useState("");
    const [totalCurrentBalance, setTotalCurrentBalance] = useState("");

    const initialState = {
        note: "",
        fromDate: format(ServerDate, "yyyy-MM-dd"),
        toDate: format(ServerDate, "yyyy-MM-dd"),
        amount: "",
        treasury: "",
        content_type: "",
        object_id: "",
        transaction_type: "",
        createdBy: "",
    };
    const [searchParams, setSearchParams] = useState(initialState);
    const [fromDateField, setFromDateField] = useState(searchParams.fromDate);
    const [toDateField, setToDateField] = useState(searchParams.toDate);

    useEffect(() => {
        gtm.push({ event: "page_view" });
    }, []);
    const resetSearchParams = () => {
        setSearchParams(initialState);
    };

    const GetFormList = useCallback(async () => {
        try {
            const data = await treasuryUserAPI.userTreasuryList();
            setUsers(data.user_data || []);
            setTreasuries(data.treasury_data || []);
        } catch (err) {
            console.error(err);
        }
    }, [mounted]);

    const getServerDate = useCallback(async () => {
        try {
            let date = await DateApi.getServerDate();
            if (date && date.server_date) {
                setServerDate(DateApi.getDate(date.server_date));
                DateApi.getDate(date.server_date);
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    const getTransactionList = useCallback(async () => {
        try {
            let data = await transaction_api.get(searchParams);
            let transactions = data.transactions.map(transaction => {
                if (transaction.transaction_type === "Withdrawal") {
                    return { ...transaction, amount: parseFloat(transaction.amount * -1).toFixed(2) }; // 10% raise
                } else {
                    return transaction; // No change
                }
            });
        } catch (err) {
            toast.error(err);
            console.error(err);
        }
    }, [mounted, searchParams]);

    const getTransactionsSummary = async () => {
        try {
            const data = await transaction_api.getTransactionsSummary({
                fromDate: searchParams.fromDate,
                toDate: searchParams.toDate,
                treasury: searchParams.treasury,
                // content_type: searchParams.content_type,
                // object_id: searchParams.object_id,
                // transaction_type: searchParams.transaction_type,
                createdBy: searchParams.createdBy,
            });
            setTransactionsSummaries(data.transactions_summary_queryset);
            setTotalTotalIn(data.total_total_in);
            setTotalTotalOut(data.total_total_out);
            setTotalCurrentBalance(data.total_current_balance);
        } catch (err) {
            toast.error(err);
            console.error(err);
        }
    };

    useEffect(() => {
        getServerDate();
        getTransactionList();
        getTransactionsSummary();
        GetFormList();
    }, [getTransactionList]);

    const resetTransactionList = async () => {
        resetSearchParams()
        try {
            const data = await TreasuryApi.get(initialState);
            // setTransactions(data);
        } catch (err) {
            console.error(err);
        }
    };

    const handlereset = async () => {
        setToDateField(format(DateApi.getDate(ServerDate), "yyyy-MM-dd"));
        setFromDateField(format(DateApi.getDate(ServerDate), "yyyy-MM-dd"));
        resetSearchParams();
        resetTransactionList();
    };

    async function download_excel() {
        let selectedColumnsData = transactionsSummaries.map(transaction => ({
            'serial': transaction['serial'],
            'treasury': transaction['treasury_name'],
            'recipient type': transaction['content_type_title'],
            'recipient': transaction['content_object'],
            'transaction_type': transaction['transaction_type'],
            'amount': transaction['amount'],
            'note': transaction['note'],
            'date': transaction['created_at'],
            'created_by': transaction['created_by'],
        }));
        let today = DateApi.getDate(ServerDate);
        await ExportToExcelApis.downloadexcel(
            selectedColumnsData, `Treasuries Transactions Report`,
        );
    }

    return (
        <>
            <Helmet>
                <title>Dashboard: Transaction Summary Report</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "50%",
                    Height: "50%",
                    py: 8,
                }}
            >
                <Container maxWidth={settings.compact ? "xl" : false}>
                    <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item>
                            <Typography color="textPrimary" variant="h5">
                                {t("Transaction Summary Report")}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard/"
                                    variant="subtitle2"
                                >
                                    {t("Dashboard")}
                                </Link>
                                <Typography color="textSecondary" variant="subtitle2">
                                    {t("Transaction Summary Report")}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Stack direction={"row"} spacing={2}>
                                <Button
                                    color="primary"
                                    startIcon={<RefreshIcon fontSize="small" />}
                                    sx={{ m: 1, backgroundColor: "#DF2F2F" }}
                                    onClick={(e) => window.location.reload()}
                                    variant="contained"
                                >
                                    {t("Refresh")}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Box sx={{ mt: 3 }}>
                            <Box
                                sx={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    m: -1,
                                    p: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        p: 1,
                                        maxWidth: "100%",
                                        width: "25%",
                                    }}
                                >
                                    <Autocomplete
                                        disablePortal
                                        size="small"
                                        id="treasury"
                                        options={treasuries}
                                        getOptionLabel={(option) => `${option.name}`}
                                        renderInput={(params) => (
                                            <TextField {...params} label={t("Treasury")} />
                                        )}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSearchParams((prevData) => ({
                                                    ...prevData,
                                                    treasury: newValue.id,
                                                }));
                                            } else {
                                                setSearchParams((prevData) => ({
                                                    ...prevData,
                                                    treasury: "",
                                                }));
                                            }
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        p: 1,
                                        maxWidth: "100%",
                                        width: "25%",
                                    }}
                                >
                                    <Autocomplete
                                        size="small"
                                        options={users}
                                        getOptionLabel={(option) =>
                                            `${option.username} | ${option.first_name} ${option.last_name}`
                                        }
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSearchParams((prevData) => ({
                                                    ...prevData,
                                                    createdBy: newValue.id,
                                                }));
                                            } else {
                                                setSearchParams((prevData) => ({
                                                    ...prevData,
                                                    createdBy: "",
                                                }));
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                label={t("User")}
                                                name="user"
                                                // onChange={(e)=>setUser(e.target.value)}
                                                value={user}
                                                variant="outlined"
                                                {...params}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        m: 1,
                                        maxWidth: "100%",
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        type="date"
                                        value={fromDateField}
                                        label={t("From Date")}
                                        defaultValue={fromDateField}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(date) => {
                                            setFromDateField(date.target.value || "");
                                        }}
                                        onBlur={() => {
                                            setFromDateField(fromDateField || "");
                                            setSearchParams((prevData) => ({
                                                ...prevData,
                                                fromDate: fromDateField || "",
                                            }));
                                        }}
                                        onKeyPress={(event) => {
                                            if (event.charCode == 13) {
                                                setFromDateField(fromDateField || "");
                                                setSearchParams((prevData) => ({
                                                    ...prevData,
                                                    fromDate: fromDateField || "",
                                                }));
                                            }
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        m: 1,
                                        maxWidth: "100%",
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        type="date"
                                        value={toDateField}
                                        label={t("To Date")}
                                        defaultValue={toDateField}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(date) => {
                                            setToDateField(date.target.value || "");
                                        }}
                                        onBlur={() => {
                                            setSearchParams((prevData) => ({
                                                ...prevData,
                                                toDate: toDateField || "",
                                            }));
                                        }}
                                        onKeyPress={(event) => {
                                            if (event.charCode == 13) {
                                                setSearchParams((prevData) => ({
                                                    ...prevData,
                                                    toDate: toDateField || "",
                                                }));
                                            }
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        m: -1,
                                        p: 2,
                                    }}
                                >
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" spacing={3}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Button
                                                color="primary"
                                                size="small"
                                                sx={{ backgroundColor: "#DF2F2F" }}
                                                variant="contained"
                                                onClick={(event) => {
                                                    handlereset();
                                                }}
                                            >
                                                {t("Reset")}
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Button
                                    color="primary"
                                    startIcon={<DownloadIcon fontSize="small" />}
                                    sx={{ m: 2 }}
                                    variant="text"
                                    onClick={download_excel}
                                >
                                    {t("Export")}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                    <TransactionSummaryTable
                        transactions={transactionsSummaries}
                        totalTotalIn={totalTotalIn}
                        totalTotalOut={totalTotalOut}
                        totalCurrentBalance={totalCurrentBalance}
                    />
                </Container>
            </Box>
        </>
    );
};

export default TransactionSummary;