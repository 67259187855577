import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Button,
  IconButton,
  DialogTitle,
  Dialog,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Popper,
} from "@material-ui/core";
import Scrollbar from "../../../Scrollbar";

import styles from "../../Styles";
import { useTranslation } from "react-i18next";
import { DateApi } from "../../../../__fakeApi__/DateApi";
import { TreasuryApi } from "../../../../__fakeApi__/treasury_apis";

import format from "date-fns/format";
import toast from "react-hot-toast";
import { WithdrawalApi } from "../../../../__fakeApi__/WithdrawApi";

const applyPagination = (treasuries, page, limit) => {
  let result = [];
  if (treasuries && treasuries.length !== 0) {
    result = treasuries.slice(page * limit, page * limit + limit);
  }
  return result;
};

const WithdrawListTable = (props) => {
  const {
    withdrawals,
    setWithdrawals,
    searchParams,
    setSearchParams,
    getWithdrawalsList,
    resetSearchParams,
    receiverTypes,
    receivers,
    shippers,
    expenseTypes,
    employees,

    treasuries,
    ...other
  } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const [deleteTreasury, setDeleteTreasury] = useState(0);
  const handleClose = () => setOpenConfirmModel(false);
  const [ServerDate, setServerDate] = useState(DateApi.getDate());

  const [fromDateField, setFromDateField] = useState(searchParams.fromDate);
  const [toDateField, setToDateField] = useState(searchParams.toDate);
  const [agent, setAgent] = useState("");
  const [shipper, setShipper] = useState("");
  const [employee, setEmployee] = useState("");
  const [expenseType, setExpenseType] = useState("");



  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handlefilter = async () => {
    getWithdrawalsList();
  };

  const handleAgentChange = (event, newValue) => {
    setSearchParams((prevData) => ({
      ...prevData,
      agent: newValue ? newValue.id : "",
    }));
  };

  const handleShipperChange = (event, newValue) => {
    setSearchParams((prevData) => ({
      ...prevData,
      shipper: newValue ? newValue.id : "",
    }));
  };

  const handleEmployeeChange = (event, newValue) => {
    setSearchParams((prevData) => ({
      ...prevData,
      employee: newValue ? newValue.id : "",
    }));
  };

  const resetWithdrawalList = async () => {
    getWithdrawalsList([]);
    try {
      const data = await WithdrawalApi.get({
        serial: "",
        fromDate: format(ServerDate, "yyyy-MM-dd"),
        toDate: format(ServerDate, "yyyy-MM-dd"),
        amount: "",
        treasury: "",
        agent: "",
        shipper: "",
        employee: "",
        expenseType: "",
        receiverType: "",
        createdBy: "",
      });
      setWithdrawals(data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleReceiverChange = (event, newValue) => {
    if (newValue) {
      setSearchParams((prevData) => ({
        ...prevData,
        receiver: newValue.id,
      }));
    } else {
      setSearchParams((prevData) => ({
        ...prevData,
        receiver: "",
      }));
    }
  };

  const handlereset = async () => {
    setToDateField(format(DateApi.getDate(ServerDate), "yyyy-MM-dd"));
    setFromDateField(format(DateApi.getDate(ServerDate), "yyyy-MM-dd"));
    resetSearchParams();
    resetWithdrawalList();
  };

  const getReceiver = (row) => {
    if (row.receiver_type === "1") {
      return `Agent - ${row.agent}`;
    } else if (row.receiver_type === "2") {
      return `Shipper - ${row.shipper}`;
    } else if (row.receiver_type === "4") {
      return `Employee - ${row.employee}`;
    } else {
      return "";
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedProducts = applyPagination(withdrawals, page, limit);

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 300,
          }}
        >
          <TextField
            size="small"
            type="text"
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoComplete="nope"
            label={t("Serial")}
            name="serial"
            onChange={(data) => {
              setSearchParams((prevData) => ({
                ...prevData,
                serial: data.target.value || "",
              }));
            }}
            value={searchParams.serial}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 300,
          }}
        >
          <Autocomplete
            disablePortal
            id="treasury"
            options={treasuries}
            getOptionLabel={(option) => `${option.id} - ${option.name}`} // Customize display
            renderInput={(params) => (
              <TextField {...params} label={t("Treasury")} required />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                setSearchParams((prevData) => ({
                  ...prevData,
                  treasury: newValue.id,
                }));
              } else {
                setSearchParams((prevData) => ({
                  ...prevData,
                  treasury: "",
                }));
              }
            }}
          />
        </Box>

        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 300,
          }}
        >
          <TextField
            size="small"
            type="text"
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoComplete="nope"
            label={t("Amount")}
            name="amount"
            onChange={(data) => {
              setSearchParams((prevData) => ({
                ...prevData,
                amount: data.target.value || "",
              }));
            }}
            value={searchParams.amount}
            variant="outlined"
          />
        </Box>

        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 300,
          }}
        >
          <FormControl>
            <InputLabel id="dropdown-label">{t("Receiver Type")}</InputLabel>
            <Select
              style={{ width: "200px" }}
              labelId="dropdown-label"
              id="dropdown"
              label={t("Receiver Type")}
              value={searchParams.receiverType}
              onChange={(e) => {
                setSearchParams((prevData) => ({
                  ...prevData,
                  receiverType: e.target.value,
                }));
              }}
            >
              <MenuItem key={"id"} value={""} style={{ height: "20px" }}>
                { }
              </MenuItem>
              {receiverTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {`${type.name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 300,
          }}
        >
          {searchParams.receiverType === "1" && (
            <Autocomplete
              disablePortal
              id="receiver"
              options={receivers}
              getOptionLabel={(option) => `${option.id} - ${option.company_name}`} // Customize display
              renderInput={(params) => (
                <TextField {...params} label={t("Receiver")} />
              )}
              onChange={handleAgentChange}
              PopperComponent={(props) => (
                <Popper {...props} placement="top-start">
                  {props.children}
                </Popper>
              )}
            />
          )}

          {searchParams.receiverType === "2" && (
            <Autocomplete
              disablePortal
              id="shippers"
              options={shippers}
              getOptionLabel={(option) =>
                `${option.id} - ${option.Shipper_name}`
              } // Customize display
              renderInput={(params) => (
                <TextField {...params} label={t("Shipper")} />
              )}
              onChange={handleShipperChange}
              PopperComponent={(props) => (
                <Popper {...props} placement="top-start">
                  {props.children}
                </Popper>
              )}
            />
          )}

          {searchParams.receiverType === "3" && (
            <FormControl style={{ width: '250px' }}>
              <InputLabel id="dropdown-label">{t("Expense Type")}</InputLabel>
              <Select
                labelId="dropdown-label"
                id="dropdown"
                value={expenseType}
                label={t("Expense Type")}
                onChange={(e) => setExpenseType(e.target.value)}
              >
                {expenseTypes.map((expense) => (
                  <MenuItem key={expense.id} value={expense.id}>
                    {`${expense.code}-${expense.name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {searchParams.receiverType === "4" && (
            <Autocomplete
              disablePortal
              id="employees"
              options={employees}
              getOptionLabel={(option) => `${option.code} - ${option.name}`} // Customize display
              renderInput={(params) => (
                <TextField {...params} label={t("Employee")} />
              )}
              onChange={handleEmployeeChange}
              PopperComponent={(props) => (
                <Popper {...props} placement="top-start">
                  {props.children}
                </Popper>
              )}
            />
          )}
        </Box>

      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
          }}
        >
          <TextField
            size="small"
            type="date"
            value={fromDateField}
            label={t("From Date")}
            defaultValue={fromDateField}
            InputLabelProps={{ shrink: true }}
            onChange={(date) => {
              setFromDateField(date.target.value || "");
            }}
            onBlur={() => {
              setFromDateField(fromDateField || "");
              setSearchParams((prevData) => ({
                ...prevData,
                fromDate: fromDateField || "",
              }));
            }}
            onKeyPress={(event) => {
              if (event.charCode == 13) {
                setFromDateField(fromDateField || "");
                setSearchParams((prevData) => ({
                  ...prevData,
                  fromDate: fromDateField || "",
                }));
              }
            }}
          />
        </Box>
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
          }}
        >
          <TextField
            size="small"
            type="date"
            value={toDateField}
            label={t("To Date")}
            defaultValue={toDateField}
            InputLabelProps={{ shrink: true }}
            onChange={(date) => {
              setToDateField(date.target.value || "");
            }}
            onBlur={() => {
              setSearchParams((prevData) => ({
                ...prevData,
                toDate: fromDateField || "",
              }));
            }}
            onKeyPress={(event) => {
              if (event.charCode == 13) {
                setSearchParams((prevData) => ({
                  ...prevData,
                  toDate: fromDateField || "",
                }));
              }
            }}
          />
        </Box>

        <Stack direction={"row"} spacing={2}>
          <Button
            color="primary"
            fullWidth
            size="small"
            sx={{ backgroundColor: "#DF2F2F" }}
            variant="contained"
            onClick={() => {
              handlefilter();
            }}
          >
            {t("Filter")}
          </Button>

          <Button
            color="primary"
            size="small"
            sx={{ backgroundColor: "#DF2F2F" }}
            variant="contained"
            onClick={(event) => {
              handlereset();
            }}
          >
            {t("Reset")}
          </Button>
        </Stack>
      </Box>

      <Scrollbar>
        <Box sx={{ minWidth: 1200 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={styles.table_head}>{t("Serial")}</TableCell>
                <TableCell style={styles.table_head}>{t("Treasury")}</TableCell>
                <TableCell style={styles.table_head}>{t("Receiver")}</TableCell>
                <TableCell style={styles.table_head}>{t("Amount")}</TableCell>
                <TableCell style={styles.table_head}>
                  {t("Expense Type")}
                </TableCell>
                <TableCell style={styles.table_head}>{t("Note")}</TableCell>
                <TableCell style={styles.table_head}>{t("Date")}</TableCell>
                <TableCell style={styles.table_head}>
                  {t("Created by")}
                </TableCell>
                {/* <TableCell style={styles.table_head}>{t("Actions")}</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProducts.length !== 0 &&
                paginatedProducts.map((row) => {
                  const isProductSelected = selectedRows.includes(row.id);

                  return (
                    <TableRow
                      hover
                      key={row.id}
                      selected={isProductSelected}
                      sx={{ height: 1 }}
                    >
                      <TableCell align="center">{row.serial}</TableCell>
                      <TableCell align="center">{row.treasury}</TableCell>
                      <TableCell align="center" style={{ width: "250px" }}>
                        {getReceiver(row)}
                      </TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center" style={{ width: "150px" }}>
                        {row.expense_type}
                      </TableCell>
                      <TableCell align="center">{row.note}</TableCell>
                      <TableCell align="center">
                        {row.date.toString().slice(0, 10)}
                      </TableCell>
                      <TableCell align="center">{row.created_by}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={withdrawals.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 100]}
          />

          <Dialog onClose={handleClose} open={openConfirmModel}>
            <DialogTitle>{t("Delete Confirmation")}</DialogTitle>
            <Typography
              variant="subtitle1"
              component="div"
              style={{ margin: "5px", marginLeft: "5px" }}
            >
              {t("Are you sure you wish to delete this treasury ?")}
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                size="large"
                color="error"
                onClick={() => { }}
                variant="contained"
              >
                {t("Yes")}
              </Button>
              <Button
                size="large"
                color="success"
                onClick={handleClose}
                variant="contained"
              >
                {t("No")}
              </Button>
            </Stack>
          </Dialog>
        </Box>
      </Scrollbar>
    </Card>
  );
};

WithdrawListTable.propTypes = {
  treasuries: PropTypes.array.isRequired,
};

export default WithdrawListTable;
