import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import Login from "../pages/authentication/Login";
import toast from "react-hot-toast";

function checkInput(input, words) {
  return words.some((word) => input.toLowerCase().includes(word.toLowerCase()));
}

function checkUrl(input, words) {
  let special_words = [
    "/edit/",
    "ShipperClosingRequestDetails",
    "/TodayOrders",
    "/todayorders",
    "/detail/",
    "/details/",
    "/revenue/",
    "/newrevenue/",
    "/revenue/details/",
    "/newrevenue/details/",
    "adminorders/repeatedorder/",
    "adminorders/order/",
    "adminorders/order/modified_admin_orders/",
    "/revenue",
    "/topcdetails",
    "/adminorders/shipper/",
    "adminorders/new",
    "/adminorders/customer/",
    "/adminorders/newwindow",
    "/hurryorder",
    "/shipperstatusfollow",
    "/shipment",
    "/ReturnPriceList",
    "shipperclosing/view",
    // "ShipperClosingRequestDetails",
    // "adminorders/new",
    "/issue",
    "/CustomerMaterialDifference"
    // "/shipment",
    // "/adminorders/new/"
  ];

  return words.some((word) => {
    // alert(word.toLowerCase())

    // console.log(word)
    // check if current url has special urls with parameters
    for (let i = 0; i < special_words.length; i++) {
      if (word.toLowerCase().includes(special_words[i].toLowerCase())) {
        // alert(input.toLowerCase())
        return input.toLowerCase().includes(word.toLowerCase());
      }
    }
     // else Normal Case must be equal
    return input.toLowerCase() == word.toLowerCase();
  });
}
const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  // console.log("auth", auth);
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  let permitted_url = ["/dashboard/"];
  if (auth && auth.user && auth.user.permitted_url) {
    // permitted_url = localStorage.getItem("permitted_url").split(",") || [];
    permitted_url = auth.user.permitted_url || ["/dashboard/"];
  }

  const customerpath = [
    "adminorders",
    "City",
    "userprivient",
    "SiteSettings",
    "Configurations",
    "useraccount",
    "shipper",
    "customers/revenue",
  ];
  const pathes = [
    "/dashboard/shipper/revenue",
    "/dashboard/shipper",
    "/dashboard/analytics",
  ];
  if (!auth.isAuthenticated || !auth.user) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }
  if (
    auth.user.is_staff === false &&
    (checkInput(location.pathname, customerpath) == true ||
      checkInput(location.pathname, ["customerdashboard"]) == false)
  ) {
    return <Navigate to="/customerdashboard/customeranalytics" />;
  }

  if (
    auth.user.is_staff === true &&
    auth.user.is_superuser === false &&
    checkUrl(location.pathname, permitted_url || []) == false
  ) {
    toast.error("You Don't have Permission in this Page");
    return <Navigate to="/dashboard/" />;
  }
  if (
    auth.user.is_staff == false &&
    auth.user.is_superuser == false &&
    location.pathname.toLowerCase().includes("/dashboard/analytics")
  ) {
    toast.error("You Don't have Permission in this Page");
    return <Navigate to="/customerdashboard/customeranalytics" />;
  }
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
