import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
    },
  },
  ar: {
    translation: {
      "Order List": "قائمه الاوردرات",
      Import: "استيراد",
      Export: "تصدير",
      Collect: "نحصيل",
      search: "بحث",
      "Select Month": "اختيار الشهر",
      Wallet: "المحفظه",
      "New Order": "اوردر جديد",
      "Request Material": "طلب مواد",
      Settled: "تم التسوية",
      "Request Collect": "طلب تحصيل",
      "Status Date": "تاريخ الحاله",
      "Ready To Return": "مرتجعات جاهزة للطلب",
      Revenue: "الربح",
      Income: "الربح",
      "Order Status": "حاله الاوردرات",
      "Top Cities": "اعلي المحافظات",
      "Yesterday Orders": "اوردرات اليوم السابق",
      Pending: "تحت المراجعه",
      "no orders that attaches to this return request":
        "لا توجد أوامر تعلق على طلب العودة هذا",
      "Status note required": "ملاحظة الحالة مطلوبة",
      "you don't have permission to do this action":
        "ليس لديك الإذن للقيام بهذا الإجراء",
      "Out For Deliver": "خارج للتوصيل",
      Deliverd: "تم التوصيل",
      Hold: "معلق",
      Undelivered: "لم يتم التوصيل",
      "Return request status updated successfully":
        "تم تحديث حالة طلب الإرجاع بنجاح",
      Undeliverd: "لم يتم التوصيل",
      Rejected: "مرفوض",
      Serial: "مسلسل",
      "Order Date": "تاريخ الطلب",
      Name: "الاسم",
      Address: "العنوان",
      Phone: "موبايل",
      "Shipment Contents": "محتوي الشحنه",
      "Shipment Content": "محتوي الشحنه",
      Status: "الحاله",
      "Status Note": "ملاحظات الحاله",
      Return: "مرتجع",
      Collected: "تم التحصيل",
      Weigth: "الوزن",
      Total: "الاجمالي",
      "Total Fees": "اجمالي الرسوم",
      "total Fees": "اجمالي الرسوم",
      "total fees": "اجمالي الرسوم",
      Note: "ملاحظات",
      "From Date": "من تاريخ",
      "To Date": "الي تاريخ",
      "Search Order": "بحث",
      City: "مدينه",
      "Rejected Order List": "قائمه الطلبات المرفوضه",
      Analytics: "تحليلات",
      "Full Name": "الاسم",
      "Shippment Content": "محتوي الشحنه",
      "Total Amount": "اجمالي القيمه",
      Notes: "ملاحظات",
      "Operation Notes": "ملاحظات إدارية",
      "OPERATION NOTES": "ملاحظات إدارية",
      Download: "تحميل",
      "Client Name": "اسم العميل",
      "Total Shipments": "اجمالي الشحنات",
      Day: "اليوم",
      Details: "تفاصيل",
      All: "الكل",
      Refresh: "تحديث",
      Print: "طباعه",
      "Material Request": "طلب مواد",
      Date: "التاريخ",
      "Settle Request": "طلب تحصيل",
      "Customer Price List": "قائمه اسعار العميل",
      "Todays Order": "اوردرات اليوم",
      "Create a new Order": "انشاء طلب جديد",
      "Create Order": "انشاء طلب",
      Orders: "الاوردرات",
      List: "القائمه",
      Create: "انشاء",
      "Print Orders": "طباعه الاوردرات",
      "Rejected Orders": "قائمه الاوردرات المرفوضه",
      Revenus: "الارباح",
      "Client Price List": "قائمه اسعار العميل",
      "Customer Issue": "مشاكل العميل",
      "Request Pickup": "طلب استلام الشحنات",
      "Pickup Request": "طلب استلام الشحنات",
      Dashboard: "القائمه الرئيسيه",
      Management: "اداره النظام",
      "Customer Income Report": "تقرير التحصيلات",
      Pickup: "شحنه",
      "No Pickup Today": "يوجد شحنات اليوم",
      "View Order": "عرض الاوردرات",
      "view order": "عرض الاوردرات",
      "Shipper Status Follow Up": "متابعه حالات المندوب",
      "Shipper Revenue": "تحصيلات المندوب",
      // 'No Pickup Today':'يوجد شحنات اليوم',
      "There Exist Pickup Today": "يوجد شحنات اليوم",
      "There is Pickup": "يوجد شحنات اليوم",
      "There is Collect Today": "يوجد تحصيل اليوم",
      "No Collect Today": "لا يوجد تحصيل اليوم",
      Add: "اضافه",
      "Copy From Another Client": "نسخ من عميل أخر",
      "Customer Name": "اسم العميل",
      "Company Name": "اسم العميل",
      Delete: "حذف",
      Shipper: "المندوب",
      Actions: "الوظائف",
      "Set Period": "تعديل الفتره",
      "Unclosed Customers Issues": "شكاوي غير مغلقه",
      "Order Delivery Status": "حالات توصيل الاوردرات",
      "Top Companies Orders": "اكثر الشركات اوردرات",
      "Less Companies Orders": "اقل الشركات اوردرات",
      "Top Clients Orders": "اكثر الشركات اوردرات",
      "Less Clients Orders": "اقل الشركات اوردرات",
      "Top Shippers Orders": "اكثر المندوبين اوردرات",
      "Show More": "رؤيه المزيد",
      "Less Shippers Orders": "اقل المندوبين اوردرات",
      "Today Out For Deliver Orders": "خارج للتوصيل اليوم",
      "Company Credit": "دائن الشركه",
      "Company Debit": "مدين الشركه",
      "Top Shippers Evaluation": "أعلى المندوبين تقييما",
      "Less Shippers Evaluation": "أقل المندوبين تقييما",
      "Money With Shippers [Uncollected Shipper]":
        "نقود مع المندوبين [غير محصله من المندوب]",
      "Customers Money [Uncollected Client]":
        "نقود العملاء [غير محصله من العميل]",
      "CC & Uncollected Shipper": "محصله من العميل ولم تحصل من المندوب",
      "Uncollected Client & Collected Shipper":
        "غير محصله من العميل ومحصله من المندوب",
      "Uncollected Client & Uncollected Shipper":
        "غير محصله من العميل و غير محصله من المندوب",
      Rated: "مقيم",
      Rating: "تقييم",
      From: "من",
      Users: "المستخدمين",
      User: "مستخدم",
      EGP: "ج.م",
      "Orders by City": "أكثر المدن أوردرات",
      "See All": "مشاهدة الكل",
      "Reset Password": "تغيير الباسورد",
      Logout: "تسجيل الخروج",
      Notifications: "أشعارات",
      "There are no notifications": "لايوجد أشعارات",
      "Mark all as read": "قراءة الكل",
      "There are no alerts": "لايوجد تذكيرات اوردرات",
      "Repeated Orders": "أوردرات متكرره",
      "Order Alerts": "تذكيرات اوردرات",
      "Order Alert": "تذكير اوردر",
      "Order alert": "تذكير اوردر",
      "order alert": "تذكير اوردر",
      "There are no Repeated Orders": "لايوجد اوردرات مكرره",
      Repeated: "متكرر",
      Show: "عرض",
      "Database Backup List": "قاعده البيانات (النسخ الاحتياطيه)",
      Operation: "اداره العمليات",
      Customers: "العملاء",
      Edit: "تعديل",
      New: "جديد",
      Shippers: "المندوبين",
      "All Orders": "قائمه الاوردرات",
      "Hub Orders": "اوردرات بالمخزن",
      "Shipment Request": "طلبات الشحن",
      "Follow UP Orders": "متابعه الاوردرات",
      "Shipper Orders Review": "مراجعة اوردرات المندوب",
      "Speed Up": "الاستعجالات",
      Transactions: "الحركات",
      "Shipper Closing": "محاسبه المندوب",
      View: "عرض",
      "Edit Approval": "الموافقه علي التعديلات",
      "Customer Edit Approval": "الموافقه علي تعديلات الشركات",
      "Shipper Edit Approval": "الموافقه علي تعديلات المندوب",
      Issue: "شكوى",
      Area: "منطقه",
      "Collect Request List": "قائمه طلبات التحصيل",
      "Material Request List": "قائمه طلب المواد",
      "SMS Hold Orders": "مراسله الاوردرات المعلقه",
      "User Evaluation": "تقييم المستخدمين",
      "Rate Mandoob": "تقييم المندوب",
      "Request Settle": "طلب تحصيل",
      Reports: "تقارير",
      "Shipper Evaluations": "تقييم المندوب",
      "Shipper Evaluation Details": "تفاصيل تقييم المندوب",
      Summary: "ملخص",
      "User Evaluations": "تقييمات المستخدم",
      "User Evaluation Details": "تفاصيل تقييمات المستخدم",
      "Issue Report": "تقرير الشكاوي",
      Chat: "الشكاوي",
      "Orders Statistics": "احصائيات الاوردرات",
      "Top Companies By Order": "اعلي الشركات اوردرات",
      "Companies Orders by Shipper": "اوردرات الشركات مجمعه بالمندوب",
      "Companies Orders by City": "اوردرات الشركات مجمعه بالمدينة",
      "Cancelled Orders": "الاوردرات المرفوضه",
      "Follow UP Orders Report": "تقرير متابعه الاوردرات",
      "Shipper Tracking": "تتبع المندوب",
      "SMS Report": "تقرير الرسائل",
      "Shipper Transfer Report": "تقرير تحويلات المندوب",
      "Expenses Report": "تقرير المصاريف",
      "Shipper Custody Report": "تقرير عهده المندوب",
      "Shipper Closing Request Report": "تقرير طلبات محاسبه المندوب",
      Settings: "الاعدادات",
      Zone: "المنطقه",
      "Customer Category": "تصنيف العميل",
      "Company Category": "تصنيف العميل",
      WhatsApp: "واتساب",
      "WhatsApp Group": "مجوعات الواتساب",
      "Site Settings": "اعدادت الموقع",
      "System Configuration": "اعدادات النظام",
      "Pricing Setting": "اعدادات الاسعار",
      Permissions: "السماحيات",
      "R/C User Permissions": "سماحيات التحصيل والمرتجع الزمنيه",
      "User Customer Permissions": "سماحيات المسخدمين للعملاء",
      "User Shipper Permissions": "سماحيات المستخدمين للمندوبين",
      "Shipper Transfer Permissions": "سماحيات تحويل المندوبين",
      "User Evaluation Permissions": "سماحيات تقييم المستخدمين",
      "Follow up Setting": "اعدادت المتابعه",
      "Customer List": "قائمة العملاء",
      Active: "نشط",
      "New Message": "رساله جديده",
      "Add Customer": "اضافة عميل",
      "Choose Auto Collect": "اختر حاله التحصيل الاوتو",
      "Choose Auto Pickup": "اختر حاله البيكب الاوتو",
      Yes: "نعم",
      No: "لا",
      "Search Client": "البحث بالعميل",
      "Search client": "البحث بالعميل",
      "search client": "البحث بالعميل",
      "Create Pickup": "اضافه بيكب",
      "Collect Delivered": "تحصيل الموصل",
      "Return Undelivered": "ارجاع غير الموصل",
      "Add Order": "أضافه أوردر",
      "Rows per page": "عدد الصفوف في الصفحه",
      "you don't have permission to Collect Order":
        "لاتملك الصلاحيه لتحصيل الاوردر",
      "you don't have permission to open Collect for this Customer":
        "لاتملك الصلاحيه لتحصيل الاوردرات لهذا العميل",
      "you don't have permission to open Return":
        "لاتملك الصلاحيه ل ارتجاع الاوردر",
      "Deleted Successfuly": "تم الحذف بنجاح",
      "Can't Delete": "لايمكن الحذف",
      "you don't have permission to add order to this customer":
        "لايمكنك اضافه اوردرات لهذا العميل",
      "you don't have permission to open revenue":
        "لايمكن فتح قائمه الربح لهذا العميل",
      "Will be send to All Customers": "سترسل الي كل عملاء",
      "Message Content": "محتوي الرسالة",
      "Will be send to": "سترسل الي",
      "All Customers": "جميع العملاء",
      "Selected Customers": "عميل مختار",
      Send: "أرسال",
      Submit: "تسجيل",
      Save: "حفظ",
      Cancel: "ألغاء",
      Customer: "عميل",
      "Create Customer": "أضافه عميل",
      "Create a new Customer": "أضافه عميل جديد",
      "Full name": "ألاسم",
      "Pickup Shipper": "مندوب البيكب",
      "Email address": "الايميل الألكترونى",
      "Deel Fees": "سعر الاتفاق",
      "Shipment Can Open": "يمكن فتح الشحنة",
      "Shipment Can't Open": "لا يمكن فتح الشحنة",
      "User Name": "أسم المستخدم",
      Password: "كلمه السر",
      "Confirm Password": "تاكيد كلمة السر",
      "Mobile No": "رقم الموبيل",
      "Show Attachment": "عرض المرفق",

      "Upload Attachment": "تحميل مرفق",
      "Collect Attachment": "مرفق التحصيل",
      "Phone number is required": "رقم الهاتف مطلوب",
      "City is required": "المدينة مطلوبة",
      "Name is required": "الأسم مطلوب",
      "Username is required": "اسم المستخدم مطلوب",
      "Not Valid should have no spaces": "يجب ان لا تحتوي علي فراغات",
      "Password must have no spaces": "كلمه السر لايجب ان تحتوي علي فراغات",
      "Password is required": "كلمه السر مطلوبة",
      "Passwords must match": "يجب ان كلمه السر تكون متطابقة",
      "Customer Edit": "تعديل العميل",
      "full name or company name or phone number may be is dubplicated in this customers":
        "اسم العميل او اسم الشركة او رقم الهاتف يجب أن لا يتكرر, وهم موجودين فى العميل رقم",
      "should not be duplicated": "لايجب تكرار القيمه",
      "Shipment count": "عدد الشحنات",
      "Shipment count should be greater than zero and less than 500":
        "عدد الشحنات يجب ان يكون اكبر من صفر واقل من ۵۰۰",
      "Orders will be downloaded soon...": "سيتم تنزيل ملف الاوردرات قريبا",
      "Reason for deletion": "سبب الحذف",
      "Are you sure you wish to delete this Orders?":
        "هل أنت متأكد من رغبتك في حذف هذه الطلبات؟",
      "Enter a delete reason at least three words":
        "أدخل سبب الحذف بثلاث كلمات على الأقل",
      "delete reason should be at least three words":
        "يجب أن يكون سبب الحذف ثلاث كلمات على الأقل",
      "Customer Debt Report": "تقرير ديون العملاء",
      "Auto Collect Request": "طلب التحصيل الأوتو",
      "Collect Days": "أيام التحصيل",
      "Auto Pickup Request": "طلب البيكب الأوتو",
      "Pickup Days": "أيام البيكب",
      "Update Customer": "تعديل العميل",
      "Price List": "قائمه أسعار العميل",
      "Create Client Price List": "أضافه الي لقائمه أسعار العميل",
      "Edit Client Price List": "تعديل قائمه اسعار العملاء",
      "Shipper Fees": "نسبة المندوب",
      "Copy Client Price List": "نسخ قائمة اسعار عميل",
      "Request sent!": "تم أرسال الطلب",
      "From Company": "الشركه",
      "Invalid Customer": "عميل غير موجود",
      "Please Select Customer": "بالرجاء اختيار عميل",
      "Something went wrong!": "يوجد شئ ما خطأ",
      "Print Details": "طباعة التفاصيل",
      "Should select at least one order": "يرجى إختيار طلب واحد على الاقل",
      Search: "بحث",
      "Choose Submit Money Cashed Status": "اختيار حالة التحويل",
      "Full Submit": " تحويل كامل",
      "Partialy Submit": "تحويل جزئي",
      "No Submit": "لم يتم التحويل",
      "Total Orders": "عدد الاوردرات",
      Amount: "قيمه التحصيل",
      Fees: "قيمه الشحن",
      COD: "الصافى",
      Case: "الحاله",
      "Show Details": "عرض التفاصيل",
      "Show Detail": "عرض التفاصيل",
      // "Status Note":"ملاحظة الحاله",
      "Money Cashed": "استلام النقديه",
      "Money CASHED": "استلام النقديه",
      of: "من",
      "Updated Successfully": "تم التحديث بنجاح",
      "Operation is scheduled": "تم جدولة العملية",
      "Please select Customers": "برجاء أختيار العملاء",
      "Submit Money Cash": "أستلام النقديه",
      "Zerod Money Cash": "لم يستلم النقديه",
      "MC Details": "تفاصيل النقدية",
      "Go Money": "تحصيل نقديه",
      "one Customer only must be selected": "يجب أختيار عميل واحد فقط",
      "Select Shipper": "أختر مندوب",
      "Please Select Mandoob": "برجاء أختيار مندوب",
      "Customer Income Detail": "تفاصيل تحصيل العميل",
      Close: "أغلاق",
      "Order / City Statistics": "حميع الاوردرات مجمعه بالمدن",
      "Orders By City Details": "تفاصيل الاوردرات مجمعه بالمدن",

      // Collected: "تحصيل",
      Returned: "مرتجع",
      "Money With Mandoob [Uncollected Mandoob]": "لم يتم التسوية مع المندوب",
      "CC & Uncollected Mandoob": "تحصيلات مع المناديب / تم التسوية مع العميل",
      "Uncollected Client & Collected Mandoob":
        "لم يتم التسوية مع العميل - تم التسوية مع المندوب",
      "Uncollected Client & Uncollected Mandoob":
        "لم يتم التسوية مع العميل - لم يتم التسوية مع المندوب",
      "Top Mandoob Evaluation": "اعلى تقيمات للمناديب",
      "Top Mandoob Orders": "اعلى نسبة توصيل للمناديب",
      "New Orders": "الاوردرات الاحدث",
      Username: "اسم المستخدم",
      "Return Price List": "اسعار المرتجعات",
      "Company Staticts": "احصائيات الشركه",
      "Shipment Type": "نوع الشحنه",
      "Whatsapp Number": "واتساب",
      "Extra Weight Charge": "تكلفه الوزن الزائد",
      Weight: "الوزن",
      Motorcycle: "موتوسكل",
      Car: "سيارة",
      "Transportation Type": "وسيلة النقل",
      Sunday: "الأحد",
      "something wrong happened please check logs":
        "حدث خطأ ما، يرجى التحقق من السجلات",
      "Click to show return orders": "انقر لإظهار أوامر الإرجاع",
      Monday: "الاثنين",
      Tuesday: "الثلاثاء",
      Wednesday: "الأربعاء",
      Thursday: "الخميس",
      Friday: "الجمعة",
      Saturday: "السبت",
      Accounts: "الخزنة",
      Withdraw: "سحب",
      "New Treasury": "إضافة خزنة",
      Code: "الكود",
      "Open balance": "الرصيد الافتتاحي",
      Deposit: "إيداع",
      Transfer: "تحويل",
      Mandoob: "المندوب",
      "Mandoob List": "قائمه المناديب",
      "Mandoob Revenue": "ربح المناديب",
      "Phone Number": "موبايل",
      "New Mandoob": "مندوب جديد",
      "Mandoob Name": "اسم المندوب",
      Email: "ايميل",
      "National ID": "الرقم القومي",
      "Mandoob Income Report": "كشف حساب المندوب",
      Filter: "تصفيه",
      "Edits history": "سجل التعديل",
      "Maximum Weight": "",
      "Minimum order for free pickup": "",
      "Pickup fees": "",
      "Follow Status": "",
      "Mandoob Fees": "عموله المندوب",
      "Can Edit Price Without Request": "",
      "Deadline By Days": "ايام التوصيل",
      "Shipping Fees": "تكلفه الشحن",
      "Copy From Another Mandob": "نسخ من مندوب اخر",
      Amout: "القيمه",
      "Change Mandoob Date": "تاريخ تغيير المندوب",
      Confirm: "تاكيد",
      "ORDER NOTES": "ملاحظات الاوردر",
      "Mandoob Evaluations": "قييمات المناديب",
      "Mandoob Orders Review": "مراجعه التاجيلات والملغي",
      "Mandoob Status Follow Up": "متابعه حالات المناديب",
      "Fast Request": "استعجالات",
      "Sort and classify orders": "فرز وتصنيف الاوردرات",
      "Assign Order To Mandoob": "تسليم الاوردرات للمناديب",
      "Upload Mandoob Order": "تحديث حالات الوكلاء",
      "Delivery returns to customer": "تسليم المرتجعات للعملاء",
      "Mandoob Closing": "تقفيل حساب المناديب",
      "Mandoob Edit Approval": "مراجعة تعديلات المناديب",
      "Customer Material Limit": "حدود تسليم الفلاير للعملاء",
      "Customer Material Balance": "ارصده الفلاير لدى العملاء",
      "Customer Material Difference": "فروق ارصده الفلاير",
      Materials: "المواد",
      Warehouses: "المخازن",
      "Stock Entry": "إدخال المخزون",
      "Stock Balance": "رصيد المخزون",
      "Stock History": "تاريخ المخزون",
      "Mandoob Tracking": "تتبع المناديب",
      "Chat Report": "تقرير الشكاوي ",
      "Mandoob Transfer Report": "تقرير التحويلات بين المناديب",
      "Mandoob Closing Request Report": "تقرير طلب تقفيل المناديب",
      "Mandoob Custody Report": "عهدة المناديب",
      "Customer Undelivered": "تقرير مرتجعات العملاء",
      "User Mandoob Permissions": "صلاحيات المستخدمين / المناديب",
      "Mandoob Transfer Permissions": "صلاحيات التحويل للمناديب",
      "Return Report": "تقرير المرتجعات",
      "Unactive Customer": "العملاء الغير نشيطين",
      "Receive returns from mandoob": "استلام المرتجعات من المناديب",
      "Shipment Requests": "طلبات الشحن",
      "Weigth (KG)": "الوزن (كج)",
      "Amount(LE)": "القيمه(جم)",
      Company: "الشركه",
      "City Include": "يشمل مدن",
      "City Exclude": "لا يشمل مدن",
      "Mandoob Include": "يشمل مندوب",
      "Mandoob exclude": "لا يشمل مندوب",
      "Customer Include": "يشمل مندوب",
      "Customer Exclude": "لا يشمل مندوب",
      "Total Amount(LE)": "اجمالي المبلغ",
      "Total Fees(LE)": "اجمالي الرسوم",
      "Client Company Name": "اسم شركه العميل",
      "Mandoob Fees(LE)": "عموله المندوب",
      "Return Status": "حاله المرتجع",
      "Order Notes": "ملاحظات الاوردر",
      "Add to Alerts": "اضافه الي التنبيهات",
      "Status History": "تاريخ الحالات",
      "Repeat Order": "الاوردرات المكرره",
      Reset: "إعادة تعيين",
      "Column Visibility": "ظهور الاعمده",
      "Print Today": "طباعه اليوم",
      "Print Page": "طباعه",
      "Customer Group Include": "يشمل مجموعه",
      "Customer Group Exclude": "لا يشمل مجموعه",
      "All Mandoob": "كل المناديب",
      "Status Notes": "ملاحظات الحالة",
      Mobile: "موبايل",
      Done: "تم",
      "Fast Request List": "قائمه الاستعجالات",
      "Change All Mandoob Fees": "تغيير عموله كل المناديب",
      "Add Extra Expenses": "مصروفات اضافيه",
      "Expense Amount": "قيمه المصروف",
      "Order No": "رقم الاوردر",
      "Has Return": "توصبل جزئي",
      "Change Shiper Date": "تاريخ تغيير المندوب",
      Today: "اليوم",
      "Treasury List": "قائمة الخزينة",
      Tomorow: "غدا",
      Yesterday: "امس",
      "Return Count": "عدد المرتجع",
      "Approval List": "قائمه الموافقات",
      "Orders that added to return request":
        "الطلبات التي تمت إضافتها إلى طلب الإرجاع",
      "Edit By": "تعديل بواسطة",
      "Change Request": "طلب تغيير",
      "Customer Approval List": "الموافقه علي تعديلات الشركات",
      "Mandoob Approval List": "مراجعه تعديلات المناديب",
      "Created by": "تم إنشاؤه بواسطة",
      "Created on": "تم الإنشاء في",
      Order: "الاوردر",
      "Select Status": "اختر الحاله",
      "User Role": "دور المستخدم",
      "Users Evaluations List": "تقييم المستخدمين",
      "Material List": "قائمه المواد",
      "New Material": "ماده جديده",
      "Material Name": "الاسم",
      "Min Qty": "الحد الأدنى للكمية",
      "Warehouse List": "قائمه المخازن",
      "New Warehouse": "مخزن جديد",
      Default: "الافتراضي",
      "Warehouse Name": "اسم المخزن",
      "source warehouse": "من مخزن",
      "Target warehouse": "الي مخزن",
      "Total Material": "اجمالي المواد",
      Material: "الماده",
      Qty: "الكميه",
      Source: "من",
      Target: "الي",
      "Stock Entry List": "قائمه ادخال المخزون",
      Type: "النوع",
      "New Stock Entry": "ادخال مخزون",
      "Customer Material Limit List": "حدود تسليم الفلاير للعملاء",
      "No.": "مسلسل",
      Warehouse: "المخزن",
      "Orders IN HUB": "تقرير الاوردرات بالمخزن",
      "New City": "مدينه جديده",
      "City Name": "اسم المدينه",
      "Zone List": "قائمه المناطق",
      "Zone Name": "اسم المنطقه",
      "New Zone": "منطقه جديده",
      "First Name": "الاسم الاول",
      "Last Name": "الاسم الاخير",
      "Change Shipper": "تغيير المندوب",
      "Show Phone": "اظهار الموبايل",
      "Show Address": "اظهار العنوان",
      "Show Report": "اظهار التقرير",
      "Print Setting": "اعدادات الطباعه",
      "is Hub": "هل مخزن",
      "Default Hub": "مخزن افتراضي",
      "Can Write Custom Note": "يسمح بكتابه ملاحظات",
      "Can Send Whatsapp": "يسمح بارسال واتساب",
      "Add to Shipment": "استقبال اوردارت جديدة",
      Reject: "رفض",
      "Total Hold Status": "اجمالي المعلق",
      Attatched: "مرفق صورة",
      Unattached: "لم يرفق صورة",
      Followed: "يتبع",
      Unfollowed: "إلغاء المتابعة",
      "Pickup Mandoob": "مندوب الاستلام",
      "Count of pieces": "عدد القطع",
      "Reference ID": "الرقم المرجعى",
      "Download order template": "تحميل ملف الأوردر",
      Rate: "التقييم",
      "Selected orders is greater than return request orders ":
        "الطلبات المحددة أكبر من أوامر طلب الإرجاع",
      "Shipment Can Open?": "يسمح بفتح الشحنه",
      DeadLine: "اخر ميعاد تسليم",
      "Settled [Client]": "تمت التسويه مع العميل",
      "Settled [Mandoob]": "تمت التسويه مع المندوب",
      "Not Settled": "لم تتم التسويه",
      "Return [Mandoob]": "استلام من المندوب",
      "Withdrawal List": "قائمة عمليات السحب",
      "Expense Type": "نوع المصاريف",
      "Create Withdrawal": "إنشاء سحب",
      "The transaction was applied successfully": "تم تطبيق المعاملة بنجاح",
      "An error occurred during the transaction": "حدث خطأ أثناء المعاملة",
      Treasury: "الخزينة",
      Receiver: "المستلم",
      "Returned [Client]": "تسليم للعميل",
      "R/C User Permissions List": "سماحيات التحصيل والمرتجع الزمنيه",
      "Paid by sender": "تم الدفع بواسطه الراسل",
      "Copy Tracking Link": "نسخ لينك التتبع",
      Commission: "العموله",
      "Total Order": "اجمالي الاوردرات",
      "Telesales Comissions Report": "تقرير عمولات التيليسيلز",
      "Telesales Comissions": "عمولات التيليسيلز",
      Year: "سنه",
      Month: "شهر",
      "Return Request Status": "حالة طلب المرتجعات",
      "Created at": "تاريخ الإنشاء",
      "employee List": "قائمة موظف",
      "Create an employee": "إنشاء موظف",
      "Edit employee": "تعديل الموظف",
      "New employee": "إضافة موظف",
      "Deposit List": "قائمة الودائع",
      "Create Deposit": "إنشاء إيداع",
      "Transfer transaction": "عملية تحويل",
      "Deposit transaction": "عملية إداع",
      "Withdrawal transaction": "عملية سحب",
      "Please fill in the details below:": "يرجى ملء التفاصيل أدناه:",
      "From Treasury": "من الخزينة",
      "To Treasury": "إلى الخزينة",
      "Transfer List": "قائمة التحويلات",
      "Create Transfer": "إنشاء تحويل",
      "warings!": "تحذيرات",
      "Transfer operation not allowed now, because you have one Treasury.": "عملية التحويل غير مسموح بها الآن، لأن لديك خزينة واحدة.",
      "Transactions List": "قائمة المعاملات",
      "Transaction List": "قائمة المعاملات",
      "Selected Return price list is not valid this rule not apply": " قائمة أسعار الإرجاع المحددة غير صالحة لذلك لا يمكن تطبيقها",
      "Mandoob History Report": "تقرير المندوب",
      "Hide": " إخفاء",
      "Log date": "تاريخ العمليه",
      "Create Treasury": "أنشاء خزينة",
      "Recipient": "المستلم",
      "Recipient Type": "نوع المستلم",
      "Opening Balance": "الرصيد الافتتاحي",
      "Closing Balance": "الرصيد الختامي",
      "Historical Balance": "الرصيد بعد العملية",
      "Transaction Type": "نوع المعملة",
      "if you select a treasury the shipper closing net total will be inserted into the selected one as a deposit": "إذا قمت بتحديد خزانة، فسيتم إدراج إجمالي صافي إغلاق المندوب في الخزانة المحددة كوديعة"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
