import { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { subDays, subHours } from "date-fns";
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Duplicate from "../../icons/Duplicate";
import ChatAltIcon from "../../icons/ChatAlt";
import CreditCardIcon from "../../icons/CreditCard";
import ArrowRightIcon from "../../icons/ArrowRight";

import ShoppingCartIcon from "../../icons/ShoppingCart";
// import socketIOClient from "socket.io-client";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { NotificationsApi } from "../../__fakeApi__/NotificationsApi";
import useMounted from "../../hooks/useMounted";
import useAuth from "../../hooks/useAuth";
import ScrollBar from "react-perfect-scrollbar";
import MailIcon from "../../icons/Mail";
import { useTranslation } from "react-i18next";
import { DateApi } from "../../__fakeApi__/DateApi";

const now = DateApi.getDate();


const iconsMap = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon,
};

const RepeatedOrdersPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const mounted = useMounted();
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(
    notifications.length
  );
  const [response, setResponse] = useState("");
  const { user } = useAuth();
  const ENDPOINT = `ws://127.0.0.1:8000/ws/room/${user.id}/`;
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(0);
  const [read_all, setReadAll] = useState(false);
  const { t } = useTranslation();
  function recieve_notifications() {
    // client.onopen = () => {
    // };
    // client.onmessage = (message) => {
    //   setResponse(message)
    // };
  }

  const GetDataList = useCallback(async () => {
    const data = await NotificationsApi.getRepeatedOrdersList(user.id);
    setNotifications(data.length ? data : []);
    setNotificationCount(data.length);
    setCount(Math.ceil(parseFloat(notifications.length / 10, 10)));
    // }
  }, [open]);

  useEffect(() => {
    GetDataList();
  }, [GetDataList]);
  useEffect(() => {
    recieve_notifications();
    setCount(Math.ceil(parseFloat(notifications.length / 10, 10)));
  }, [mounted, response, open, count, read_all]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function mark_as_read(id) {
    const data = await NotificationsApi.mark_as_read(user.id, [id]);
  }
  async function mark_all_as_read() {
    const data = await NotificationsApi.mark_allRepeatedOrder_as_read(user.id);
  }

  return (
    <>
      <Tooltip title={t("Repeated Orders")}>
        <IconButton color="inherit" ref={anchorRef} onClick={handleOpen}>
          <Badge color="error" badgeContent={notificationCount}>
            <Duplicate fontSize="medium" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 500, maxHeight: 600 },
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
          }}
        >
          <Typography color="textPrimary" variant="h6">
            {t("Repeated Orders")}
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              {t("There are no Repeated Orders")}
            </Typography>
          </Box>
        ) : (
          <>
            <ScrollBar>
              <List disablePadding sx={{ height: 400 }}>
                {notifications.map((notification) => {
                  const Icon = ChatAltIcon;

                  return (
                    <ListItem divider key={notification.id}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: "primary.main",
                            color: "primary.contrastText",
                          }}
                        >
                          <Icon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Link
                            color="textPrimary"
                            sx={{ cursor: "pointer" }}
                            underline="none"
                            variant="subtitle2"
                          >
                            {notification.subject}
                          </Link>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </ScrollBar>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={async () => {
                  await mark_all_as_read(user.id);
                  await GetDataList();
                  window.open(
                    "/dashboard/adminorders/repeatedorder/isRepeated",
                    "_blank"
                  );
                  handleClose();
                }}
              >
                {t("Show")}
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default RepeatedOrdersPopover;
