import { useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
    Box,
    Breadcrumbs,
    Container,
    Grid, InputAdornment,
    Link, TextField,
    Typography,
} from "@material-ui/core";
import useMounted from "../../../hooks/useMounted";
import useSettings from "../../../hooks/useSettings";
import ChevronRightIcon from "../../../icons/ChevronRight";
import { DateApi } from "../../../__fakeApi__/DateApi";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import
import { useTranslation } from "react-i18next";
import ArchivedOrderTable from "./ArchivedOrderTable";
import {archivedOrderApis} from "../../../__fakeApi__/ArchivedOrderApis";
import {Autocomplete} from "@material-ui/lab";
import {ReturnRequestApi} from "../../../__fakeApi__/ReturnRequestApi";
import SearchIcon from "../../../icons/Search";

const ArchivedOrder = () => {
    const mounted = useMounted();
    const { settings } = useSettings();
    const [dataList, setDataList] = useState([]);
    const [refresh, setRefresh] = useState(DateApi.getDate());
    const [serial, setSerial] = useState(null);
    const [company, setCompany] = useState(null);
    const [customers, setCustomers] = useState([]);
    const { t } = useTranslation();

    const getCompanies = useCallback(async () => {
        try {
            const data = await ReturnRequestApi.getCompanyReportFilterList();
            if (data) {
                setCustomers(data.customers || []);
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        getCompanies();
    }, [getCompanies]);

    const GetDataList = useCallback(async () => {
        try {
            let query = {company: company, serial: serial};
            const data = await archivedOrderApis.getArchivedOrders(query);
            setDataList(data || []);
        } catch (err) {
            console.error(err);
        }
    }, [mounted, company, serial, refresh]);

    useEffect(() => {
        GetDataList();
    }, [GetDataList]);

    return (
        <>
            <Helmet>
                <title>Dashboard: Archived Order List</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "50%",
                    Height: "50%",
                    py: 8,
                }}
            >
                <Container maxWidth={settings.compact ? "xl" : false}>
                    <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item>
                            <Typography color="textPrimary" variant="h5">
                                {t("Archived Order List")}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard/"
                                    variant="subtitle2"
                                >
                                    {t("Dashboard")}
                                </Link>
                                <Typography color="textSecondary" variant="subtitle2">
                                    {t("Archived Order List")}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            flexWrap: "wrap",
                            m: -1,
                            p: 2,
                        }}
                    >
                        <Box
                            sx={{
                                m: 1,
                                maxWidth: "100%",
                                width: 240,
                            }}
                        >
                            <Autocomplete
                                size="small"
                                options={customers}
                                getOptionLabel={(option) =>
                                    `${option.id} ${option.company_name}`
                                }
                                onChange={(event, value) => {
                                    if (value !== null) {
                                        setCompany(value.id);
                                    } else {
                                        setCompany(null);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        fullWidth
                                        label="Company Name"
                                        name="Customer"
                                        variant="outlined"
                                        {...params}
                                    />
                                )}
                            />
                        </Box>
                        <Box
                            sx={{
                                m: 1,
                                maxWidth: "100%",
                                width: 487,
                            }}
                        >
                            <TextField
                                size="small"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                onBlur={(event) => {
                                    const value = event.target.value;
                                    if (value !== null) {
                                        setSerial(value);
                                    } else {
                                        setSerial(null);
                                    }
                                }}
                                placeholder={t("Search")}
                                variant="outlined"
                            />
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <ArchivedOrderTable
                            dataList={dataList}
                            isLoading={false}
                            setLoading={false}
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ArchivedOrder;