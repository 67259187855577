import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import numeral from "numeral";
import PropTypes from "prop-types";
import { getOrderStatusLabel } from "../../../__fakeApi__/OrderStatus";

import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Rating,
  Autocomplete,
} from "@material-ui/core";
// import ReactHtmlTableToExcel  from "react-html-table-to-excel"
import ArrowRightIcon from "../../../icons/ArrowRight";
import ImageIcon from "../../../icons/Image";
import PencilAltIcon from "../../../icons/PencilAlt";
import Scrollbar from "../../Scrollbar";
import styles from "../Styles";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../../icons/Check";
const sortOptions = [
  {
    label: "Name Descending",
    value: "name|desc",
  },
  {
    label: "Name Ascending",
    value: "name|asc",
  },
];

const applyFilters = (row, query, filters) =>
  row.filter((row) => {
    let matches = true;
    if (query && !row.full_name.toLowerCase().includes(query.toLowerCase())) {
      matches = false;
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && row[key] !== value) {
        matches = false;
      }
    });
    return matches;
  });
const groupBy = (array, key) => {
  // Return the reduced array
  return array.reduce((result, currentItem) => {
    // If an array already present for key, push it to the array. Otherwise create an array and push the object.
    (result[currentItem[key]] = result[currentItem[key]] || []).push(
      currentItem
    );
    // return the current iteration `result` value, this will be the next iteration's `result` value and accumulate
    return result;
  }, {}); // Empty object is the initial value for result object
};

const applyPagination = (dataList, page, limit) => {
  let result = [];
  if (dataList && dataList.length !== 0) {
    result = dataList.slice(page * limit, page * limit + limit);
  }
  return result;
};

const MabdoobOrderDetailsReportTable = (props) => {
  const { dataList, query, setFilterdData, ...other } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  // const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const { t } = useTranslation();
  const handleQueryChange = (event) => {
    // setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSelectAllProducts = (event) => {
    setSelectedRows(event.target.checked ? dataList.map((row) => row.id) : []);
  };

  const handleSelectOneProduct = (event, rowId) => {
    if (!selectedRows.includes(rowId)) {
      setSelectedRows((prevSelected) => [...prevSelected, rowId]);
    } else {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => id !== rowId)
      );
    }
  };
  const handleCustomerChange = (event, filtervalue) => {
    let value = null;

    if (filtervalue) {
      value = filtervalue.id;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      customer_id: value,
    }));
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };
  function get_row_status(row) {
    let result = "";
    if (row.Order_Collect_Status == 1 && row.Returned_Client == 1) {
      result = `${t("Collected")} [${row.Collect_status_date}] , ${t("Returned")} [${row.returned_Client_date}]`;
    } else if (row.Order_Collect_Status == 1) {
      result = `${t("Collected")} [${row.Collect_status_date}]`;
    } else if (row.Returned_Client == 1) {
      result = `${t("Returned")} [${row.returned_Client_date}]`;
    }
    return result;
  }
  function get_totals(data, key) {
    let total = 0;
    data = data || [];

    data.map((e) => {
      total += e[key];
      return e;
    });
    return total;
  }

  // Usually query is done on backend with indexing solutions
  const filteredProducts = applyFilters(dataList, query, filters);
  // setFilterdData(filteredProducts)
  const paginatedProducts = applyPagination(filteredProducts, page, limit);
  const selectedSomeProducts =
    selectedRows.length > 0 && selectedRows.length < filteredProducts.length;
  const selectedAllProducts = selectedRows.length === filteredProducts.length;

  const groupData = groupBy(paginatedProducts, "day_date");

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1,
          p: 2,
        }}
      ></Box>

      <Scrollbar>
        <Box sx={{ minWidth: 1200 }}>
          <Table id="data-table" sx={{ height: "100%", width: "100%" }}>
            <TableHead>
              <TableRow style={styles.h4}>
                <TableCell padding="checkbox">
                  {/* <Checkbox
                    checked={selectedAllProducts}
                    color="primary"
                    indeterminate={selectedSomeProducts}
                    onChange={handleSelectAllProducts}
                  /> */}
                </TableCell>
                <TableCell style={styles.table_head}>{t("Serial")}</TableCell>
                <TableCell style={styles.table_head}>{t("Reference ID")}</TableCell>
                <TableCell style={styles.table_head}>{t("Shipper")}</TableCell>
                <TableCell style={styles.table_head}>{t("COMPANY NAME")}</TableCell>
                <TableCell style={styles.table_head}>{t("NAME")}</TableCell>
                <TableCell style={styles.table_head}>{t("ADDRESS")}</TableCell>
                <TableCell style={styles.table_head}>{t("PHONE")}</TableCell>
                <TableCell style={styles.table_head}>{t("SHIPMENT CONTENTS")}</TableCell>
                <TableCell style={styles.table_head}>{t("SHIPMENT TYPE")}</TableCell>
                <TableCell style={styles.table_head}>{t("STATUS")}</TableCell>
                <TableCell style={styles.table_head}>{t("STATUS NOTE")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProducts.length !== 0 &&
                paginatedProducts.map((row) => {
                  const isProductSelected = selectedRows.includes(row.id);

                  return (
                    <TableRow
                      hover
                      // key={row.id}
                      selected={isProductSelected}
                      sx={{ height: 1 }}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox
                        checked={isProductSelected}
                        color="primary"
                        onChange={(event) => handleSelectOneProduct(event, row.id)}
                        value={isProductSelected}
                      /> */}
                      </TableCell>

                      <TableCell align="center">{row.serial}</TableCell>
                      <TableCell align="center">{row.referenceID}</TableCell>
                      <TableCell align="center">{row.shipper}</TableCell>
                      <TableCell align="center">{row.company_name}</TableCell>
                      <TableCell align="center">{row.full_name}</TableCell>
                      <TableCell align="center">{row.address}</TableCell>
                      <TableCell align="center">{row.phone}</TableCell>
                      <TableCell align="center">{row.shipment_contents}</TableCell>
                     <TableCell>{row.shipment_type_str}</TableCell>
                      <TableCell align="center">{getOrderStatusLabel(row.Order_Delivery_Status)}</TableCell>
                      <TableCell>{row.StatusNote}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={filteredProducts.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 100]}
          />

          {/* <ReactHtmlTableToExcel
            table = "data-table"
            filename = "Top Companies By Order"
            sheet = "Sheet"
            buttonText = "Export"
        /> */}
        </Box>
      </Scrollbar>
    </Card>
  );
};

MabdoobOrderDetailsReportTable.propTypes = {
  dataList: PropTypes.array.isRequired,
  query: PropTypes.string.isRequired,
  setFilterdData: PropTypes.func.isRequired,
};

export default MabdoobOrderDetailsReportTable;
