import toast from "react-hot-toast";
import {
  ServerURL,
  NewCustomerRevenueApiMethodUrl,
  CustomerRevenueApiMethodUrl,
  NewCustomerRevenueDetailApiMethodUrl,
  GetMoneyCashedOrdersApiMethodUrl,
  UpdateOrdersCustomerRevenueDetailApiMethodUrl,
  getShipperListCustomerRevenueDetailApiMethodUrl,
  GoMoneyCustomerRevenueDetailApiMethodUrl,
  NewCustomerRevenueSearchApiMethodUrl,
  NewCustomerRevenueTotalPrintDetailApiMethodUrl,
  getAccessToken,
  fetch_instance,
  AddPickupCustomerRevenueDetailApiMethodUrl,
  CustomerCollectAttachmentUrl,
} from "./ServerConfig";

class customer_revenue_order_api {
  async get_customer_revenue(body) {
    let obj = {};
    await fetch_instance(`${ServerURL}${NewCustomerRevenueApiMethodUrl}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async get_shipper_list() {
    let obj = {};
    await fetch_instance(
      `${ServerURL}${getShipperListCustomerRevenueDetailApiMethodUrl}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  

  async get_customer_revenue_detail(body) {
    let obj = {};
    try {
      await fetch_instance(
        `${ServerURL}${NewCustomerRevenueDetailApiMethodUrl}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
          body: JSON.stringify(body),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          obj = data;
          return Promise.resolve(obj);
        });
    } catch (error) {
      toast.error(error.toString());
      return Promise.resolve(obj);
    }
    return Promise.resolve(obj);
  }
  async topCompanyDetails(body) {
    let obj = {};

    await fetch_instance(`${ServerURL}customization/get_company_details`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async topCompanyCityDetails(body) {
    let obj = {};

    await fetch_instance(`${ServerURL}customization/get_company_city_details`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async topCompanyShipperDetails(body) {
    let obj = {};

    await fetch_instance(
      `${ServerURL}customization/get_company_shipper_details`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async get_customer_revenue_details_search(body) {
    let obj = {};

    await fetch_instance(
      `${ServerURL}${NewCustomerRevenueSearchApiMethodUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async get_customer_revenue_totals_details(body) {
    let obj = {};

    await fetch_instance(
      `${ServerURL}${NewCustomerRevenueTotalPrintDetailApiMethodUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async update_orders_money_cashed(body) {
    let obj = {};
    await fetch_instance(
      `${ServerURL}${UpdateOrdersCustomerRevenueDetailApiMethodUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response;
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async go_money(body) {
    let obj = {};
    await fetch_instance(
      `${ServerURL}${GoMoneyCustomerRevenueDetailApiMethodUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response;
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async request_order(body) {
    let obj = {};
    await fetch_instance(
      `${ServerURL}${AddPickupCustomerRevenueDetailApiMethodUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        return response;
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async get_orders_money_cashed(body) {
    let obj = {};
    await fetch_instance(`${ServerURL}${GetMoneyCashedOrdersApiMethodUrl}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  // my apis
  async get_customer_revenue_by_id(body) {
    let obj = {};
    await fetch_instance(`${ServerURL}${CustomerRevenueApiMethodUrl}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async getCustomerCollectAttachment(customer, collect_date) {
    let orders = [];
    let url = `${ServerURL}${CustomerCollectAttachmentUrl}?customer=${customer}&collect_date=${collect_date}`;
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }

  async uploadCustomerCollectAttach(obj) {
    // delete settings.submit
    let res = {};
    let data = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      data.append(key, value || "");
    }
    await fetch_instance(`${ServerURL}${CustomerCollectAttachmentUrl}`, {
      method: "POST",
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: data, // JSON.stringify(data)
    })
      .then((response) => {
        // console.log(response)
        res = response;
        return Promise.resolve(res);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(res);
  }
}

export const CustomerRevenueNewApi = new customer_revenue_order_api();
