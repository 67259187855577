import toast from "react-hot-toast";
import {
  ServerURL,
  TopCompanyReportFiltersApiMethodUrl,
  getAccessToken,
  fetch_instance,
  ReturnRequestListMethodURL,
  ReturnRequestListDetailsMethodURL,
  ReturnRequestListMethodURLV1,
  ReturnRequestListDetailsMethodURLV1,
} from "./ServerConfig";

class ReturnRequestApis {
  async getCompanyReportFilterList() {
    let obj = {};
    await fetch_instance(`${ServerURL}${TopCompanyReportFiltersApiMethodUrl}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async get_return_request_list(body) {
    let obj = {};
    await fetch_instance(`${ServerURL}${ReturnRequestListMethodURL}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  
  async get_return_request_list_v1(params) {
    let obj = {};
    await fetch_instance(`${ServerURL}${ReturnRequestListMethodURLV1}?from_date=${params.from_date}&to_date=${params.to_date}&customer=${params.customer ? params.customer : ''}&status=${params.status}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async get_return_request_details(body) {
    let obj = {};
    await fetch_instance(`${ServerURL}${ReturnRequestListDetailsMethodURL}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async get_return_request_details_v1(id) {

    let obj = {};
    await fetch_instance(`${ServerURL}${ReturnRequestListDetailsMethodURLV1}?id=${id ? id : ''}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
}

export const ReturnRequestApi = new ReturnRequestApis();
