import { useCallback, useEffect, useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import "../../components/contacts/contacts.css";
import { PricingPlan } from "../../components/pricing";
import { Helmet } from "react-helmet-async";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { blogApi } from "../../__fakeApi__/blogApi";
import { BlogPostCard, BlogNewsletter } from "../../components/blog";
import PlusIcon from "../../icons/Plus";
import SortAscendingIcon from "../../icons/SortAscending";
import SortDescendingIcon from "../../icons/SortDescending";
import SearchIcon from "../../icons/Search";
import useMounted from "../../hooks/useMounted";
import gtm from "../../lib/gtm";
import { StyleSheet } from "@react-pdf/renderer";
import PricingFooter from "./PricingFooter";
import User from "../../icons/User";
import { PostConstruct } from "ag-grid-community";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 650 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const styles = StyleSheet.create({
  maindiv: {
    width: 270,
    border: ".5px solid #bfcade",
    padding: 1,
    borderRadius: 5,
    // font-family: 'Nunito', sans-serif;
  },
  smaindiv: {
    fontSize: 15,
  },
  greendiv: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 2.5,
    color: "white",
    backgroundColor: "#44b15a",
    width: "auto",
    align: "center",
    height: 60,
    padding: 5,
    textAlign: "center",
  },
  whitediv: {
    fontSize: 21,
    letterspacing: 2,
    fontWeight: 1400,
    lineHeight: 1.5,
    height: 60,
    color: "black",
    backgroundColor: "#ffffff",
    width: "auto",
    textAlign: "center",
    fontFamily: "Nunito sans-serif",
    // fontWeight:"bold"
  },
  pricediv: {
    textAlign: "center",
    fontFamily: "Nunito sans-serif",
    fontSize: 20,
    fontWeight: 200,
    color: "#000",
  },
});
const sortOptions = [
  {
    label: "Newest",
    value: "desc",
    icon: SortDescendingIcon,
  },
  {
    label: "Older",
    value: "asc",
    icon: SortAscendingIcon,
  },
];

const PricingCarousel = (props) => {
  const { deviceType } = props;
  const mounted = useMounted();
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [posts, setPosts] = useState([]);
  const [contact, setContact] = useState([]);
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  // console.log("posts",posts)
  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  const getPosts = useCallback(async () => {
    try {
      const data = await blogApi.getpricelist(); //http://127.0.0.1:8000/settings/get_user_settings

      if (mounted.current) {
        setPosts(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);
  const getContacts = useCallback(async () => {
    try {
      const data = await blogApi.getContacts(); //http://127.0.0.1:8000/settings/get_user_settings

      if (mounted.current) {
        setContact(data.settings);
      }
    } catch (err) {
      console.error(err);
    }
  });
  useEffect(() => {
    getPosts();
  }, [getPosts]);
  useEffect(() => {
    getContacts();
  }, []);

  const handleSortOpen = () => {
    setOpenSort(true);
  };

  const handleSortClose = () => {
    setOpenSort(false);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    setOpenSort(false);
  };

  const { icon: SortOptionIcon } = sortOption;

  return (
    // <>
    // {/* <Helmet>
    //   <title>Pricing: Price List | Dynamic Shipping System</title>
    // </Helmet> */}

    <Box
      sx={{
        backgroundColor: "#fff",
        minHeight: "100%",
      }}
    >
      {/* <div>
          <div className="overlay">
            <RouterLink to="/">
              <div className="loginbtn">
                <Button
                  color="warning"
                  component={RouterLink}
                  size="small"
                  startIcon={<User fontSize="small" />}
                  to="/login"
                  variant="outlined"
                >
                  Login
                </Button>
              </div>
            </RouterLink>
            <img
              className=".image1"
              alt="hello"
              src="/static/contact/shipmentcover.jpeg"
              style={{ height: 500, width: "100%", marginTop: -50 }}
            />
          </div>

          <div>
            <img
              className="image2"
              alt="hello"
              src="/static/contact/1549292064_701.png"
              style={{ maxWidth: "100%", left: "20%", width: "50%" }}
            />
          </div>
        </div>
         */}

      {/* <Box sx={{ py: 6 }}> */}
      <Container maxWidth="lg" sx={{ backgroundColor: "#fff" }}>
        <Box
          sx={{
            alignItems: "center",
            // display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#fff"
          }}
        ></Box>
        <div style={styles.pricediv}>
          <h1>Pricing</h1>
        </div>
        <Box sx={{ mt: 4 }}>
          <Carousel
            swipeable={true}
            draggable={true}
            // showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            slidesToSlide={1}
            // customTransition="all .5"
            transitionDuration={300}
            // focusOnSelect={true}
            // centerMode={true}
            customTransition="transform 300ms ease-in-out	"
            containerClass="carousel-container"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={deviceType || "desktop"}
            dotListClass="react-multi-carousel-dot-list"
            sliderClass="react-multi-carousel-track"
            // itemClass="carousel-item-padding-0-px"
          >
            {/* <Container maxWidth="lg" sx={{ py: 6 }}> */}
            {/* <Grid container spacing={4}> */}
            {posts.map((post) => (
              //   <Grid
              //   item
              //   md={4}
              //   xs={12}
              // >

              <PricingPlan
                // cta="Contact Us"
                currency="LE"
                description={post.plan_delivery}
                // option.detail_description
                features={(post.details_child || []).map((e) => e.detail_description)}
                // image="/static/pricing/plan3.svg"
                name={post.plan_name}
                price={post.plan_price}
                sx={{
                  height: "100%",
                  // maxWidth: 250,
                  // mx: "auto",
                  ml: 2,
                }}
              />
              // </Grid>
            ))}

            {/* </Grid> */}
            {/* </Container> */}

          </Carousel>
        </Box>
      </Container>
      {/* </Box> */}
      {/*<BlogNewsletter />*/}
      {/* <PricingFooter contact={contact} /> */}
    </Box>
    // </>
  );
};

export default PricingCarousel;
