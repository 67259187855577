import {
  ServerURL,
  CityMethodURL,
  getAccessToken,
  fetch_instance,
  HoldReason,
  UpdateCityMethodURL,
  GetCityMethodURL,
  DeleteCityMethodURL,
} from "./ServerConfig";
import axiosInstance from "../components/qpexpress/axios";
import toast from "react-hot-toast";

class HoldReasonApi {
  async get_hold_list(reason_type = "", show_type = "") {
    let city = [];
    await fetch_instance(
      `${ServerURL}${HoldReason}?reason_type=${reason_type || ""}&show_type=${
        show_type || ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        city = data;
        return Promise.resolve(city);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(city);
  }
  async get_hold_obj(id) {
    let city = {};
    await fetch_instance(`${ServerURL}${HoldReason}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        city = data;
        return Promise.resolve(city);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(city);
  }
  async new_HoldReason(city) {
    await fetch_instance(`${ServerURL}${HoldReason}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(city),
    }).catch((error) => {
      toast.error(error.toString());
    });
  }

  async update_hold_reason(city) {
    await fetch_instance(`${ServerURL}${HoldReason}/${city.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(city),
    }).catch((error) => {
      toast.error(error.toString());
    });
  }
  async delete_hold_reason(id) {
    await fetch_instance(`${ServerURL}${HoldReason}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }).catch((error) => {
      toast.error(error.toString());
    });
  }
}
export const holdReasonApi = new HoldReasonApi();
