import toast from "react-hot-toast";
import {
    getAccessToken,
    fetch_instance,
    ServerURL,
    ContentTypeUrl,
} from "./ServerConfig";

class ContentTypeAPI {
    async get() {
        let response = [];
        await fetch_instance(`${ServerURL}${ContentTypeUrl}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
            },
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                response = data;
                return Promise.resolve(response);
            })
            .catch((error) => {
                toast.error(error.toString());
                console.log(error);
            });
        return Promise.resolve(response);
    }

    async getContentTypeObjectList(id) {
        let response = [];
        await fetch_instance(`${ServerURL}${ContentTypeUrl}${id}/object_list`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
            },
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                response = data;
                return Promise.resolve(response);
            })
            .catch((error) => {
                toast.error(error.toString());
                console.log(error);
            });
        return Promise.resolve(response);
    }

}

export const contentTypeAPI = new ContentTypeAPI();
