import { useRef, useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useSettings from "../../hooks/useSettings";
// import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';

const languageOptions = {
  en: {
    icon: '/static/icons/uk_flag.svg',
    label: 'English'
  },
  ar: {
    icon: '/static/icons/de_flag.svg',
    label: 'Arabic'
  }
};

const LanguagePopover = () => {
  const anchorRef = useRef(null);
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState(settings);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = (language) => {
    localStorage.setItem("lang",language)
    const storedData =settings
    if(language=="ar"){
        setValues({
          ...values,
          "direction": "rtl",
         });
    saveSettings(values);
    }else{
        setValues({
          ...values,
          "direction": "ltr",
        });
    }
    i18n.changeLanguage(language);
    setOpen(false);
  };
    useEffect(() => {
        saveSettings(values);
  }, [values]);
  const selectedOption = languageOptions[i18n.language];

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
      >
        <Box
          sx={{
            display: 'flex',
            height: 20,
            width: 20,
            '& img': {
              width: '100%'
            }
          }}
        >
          <img
            alt={selectedOption.label}
            src={selectedOption.icon}
          />
        </Box>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            onClick={() => handleChangeLanguage(language)}
            key={language}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  height: 20,
                  width: 20,
                  '& img': {
                    width: '100%'
                  }
                }}
              >
                <img
                  alt={languageOptions[language].label}
                  src={languageOptions[language].icon}
                />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {languageOptions[language].label}
                </Typography>
              )}
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
