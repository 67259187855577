import { useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {Box, Breadcrumbs, Button, Container, Grid, Link, Typography} from "@material-ui/core";
import { CustomerCategoryApi } from "../../../__fakeApi__/CustomerCategoryApi";
import ShipperCategoryTable from "./ShipperCategoryTable";
import useMounted from "../../../hooks/useMounted";
import useSettings from "../../../hooks/useSettings";
import ChevronRightIcon from "../../../icons/ChevronRight";
import PlusIcon from "../../../icons/Plus";
import gtm from "../../../lib/gtm";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import {shipperCategoryApi} from "../../../__fakeApi__/ShipperCategoryApi";

const ShipperCategoryList = () => {
  const mounted = useMounted();
  const { user } = useAuth();
  const { settings } = useSettings();
  const [dataList, setDataList] = useState([]);
  const { t } = useTranslation();
  // let urls=localStorage.getItem("permitted_url") || ''
  let urls = user.permitted_url || ["dashboard"];
  // urls=urls.split(',')
  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);
  // console.log("ddddd",dataList)
  const GetDataList = useCallback(async () => {
    console.log('mandoob list API')
    try {
      const data = await shipperCategoryApi.get_category_list();
      if (mounted.current) {
        setDataList(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    GetDataList();
  }, [GetDataList]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Mandoob Category List</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "50%",
          Height: "50%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("Mandoob Category List")}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/"
                  variant="subtitle2"
                >
                  {t("Dashboard")}
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  {t("Mandoob Category List")}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                {user.is_superuser || urls.includes("/dashboard/mandoob_categories/new") == true  ? (
                  <Button
                    color="primary"
                    component={RouterLink}
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 ,backgroundColor:"#DF2F2F"}}
                    to="/dashboard/mandoob_categories/new"
                    variant="contained"
                  >
                    {t("New Mandoob Category")}
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <ShipperCategoryTable dataList={dataList} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ShipperCategoryList;
