import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
// import { ListItemIcon, ListItemText, Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';
import Facebook from "../../icons/Facebook";
import Twitter from "../../icons/Twitter";
import Instagram from "../../icons/Instagram";
import LinkedIn from "../../icons/LinkedIn";
import { alpha } from "@material-ui/core/styles";
import MinusIcon from "../../icons/Minus";
import { color, fontSize } from "@material-ui/system";
// import Logo from '../../Logo';
import Home from "../../icons/Home";
import Inbox from "../../icons/Inbox";
import Phone from "../../icons/Phone";
import { Link as RouterLink } from "react-router-dom";
import User from "../../icons/User";
// import WhatsApp from "../../icons/WhatsApp";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import "./styles.css";
import WhatsappDrawer from "./WhatsappDrawer";
import {
  LogoHeight,
  LogoWidth,
  ServerURL,
} from "../../__fakeApi__/ServerConfig";
const sections = [
  {
    title: "Quick Links",
    links: [
      // {
      //   title: "Home",
      //   href: "/",
      // },
      // {
      //   title: "About Us",
      //   href: "/about",
      // },
      // {
      //   title: "Services",
      //   href: "/service",
      // },
      // {
      //   title: "Find us",
      //   href: "/contact",
      // },
      // {
      //   title: "Price Offers",
      //   href: "/pricing",
      // },
    ],
  },
  // {
  //   title: 'More',
  //   links:[
  //     {
  //       title: 'Help/FAQ',
  //       href: '/'
  //     },
  //     {
  //       title: 'Privacy policy',
  //       href: '/'
  //     },
  //       {
  //       title: 'Terms of use',
  //       href: '/'
  //     },
  //   ]
  // }
];

const PricingFooter = (props) => {
  const { contact } = props;
  let logo = "/static/contact/qplogo.png";
  if (contact.logo) {
    logo = `${ServerURL.substring(0, ServerURL.length - 1)}${contact.logo}`;
  }
  // const [logo, setLogo] = useState(logo_path);
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        pb: 6,
        pt: {
          md: 6,
          xs: 6,
        },
      }}
      {...props}
    >
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid
            item
            md={5}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              order: {
                md: 1,
                xs: 4,
              },
            }}
            xs={12}
          >
            {/*<Logo />*/}
            <Typography sx={{ mt: 1, color: "#000" }} variant="caption">
              {/*<h1 style={fontSize(20)}>About</h1>*/}
              <RouterLink to="/">
                <img
                  // className="image2"
                  alt="hello"
                  src={logo}
                  // style={{ maxWidth: "20%", width: "110" }}
                  width={LogoWidth}
                  height={LogoHeight}
                />
              </RouterLink>

              <br />
              {contact.homeSlogin}
              <br />
              <br />
              {/* <Facebook />
            <Twitter />
            <Instagram/>
            <LinkedIn /> */}
              {/* <WhatsAppIcon className='whatsAppIcon' title = "Message Us"/> */}
            </Typography>
          </Grid>
          {sections.map((section, index) => (
            <Grid
              item
              key={section.title}
              md={2}
              // sm={4}
              sx={{
                ml: 3,
                mr: 5,
                order: {
                  md: 2,
                  xs: 1,
                },
              }}
              // xs={10}
            >
              <Typography
                sx={{ width: "max-content", color: "#000" }}
                variant="overline"
              >
                {section.title}
              </Typography>
              <List disablePadding>
                {section.links.map((link) => (
                  <ListItem
                    className="footerLink"
                    disableGutters
                    key={link.title}
                    sx={{
                      pb: 0,
                      pt: 0,
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        minWidth: 0,
                        mr: 0.5,
                      }}
                    >
                      <MinusIcon sx={{ color: "green" }}  />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Link
                          href={link.href}
                          color="#000"
                          variant="subtitle2"
                        >
                          <span className="footerLink">{link.title}</span>
                        </Link>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
          <Grid
            item
            md={4}
            sm={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              order: {
                mr: 1,
                md: 3,
                xs: 3,
              },
            }}
            xs={10}
          >
            <Typography color="textSecondary" variant="overline">
              Contact
            </Typography>
            <div className="contactInfo">
              <ListItem className="footerLink" disableGutters>
                <ListItemAvatar>
                  <Home fontSize="medium" />
                </ListItemAvatar>
                <ListItemText sx={{ color: "#000" }} className="contactText">
                  {" "}
                  {contact.address}
                </ListItemText>
              </ListItem>
              {/* <Home sx={{ mb: 0 }} /> {contact.address} */}
            </div>
            <div className="contactInfo">
              <ListItem className="footerLink" disableGutters>
                <ListItemAvatar>
                  <Inbox fontSize="medium" />
                </ListItemAvatar>
                <ListItemText sx={{ color: "#000" }} className="contactText">
                  {" "}
                  {contact.email}
                </ListItemText>
              </ListItem>
            </div>
            <div className="contactInfo">
              <ListItem className="footerLink" disableGutters>
                <ListItemAvatar>
                  <Phone sx={{ color: "green" }} fontSize="medium" />
                </ListItemAvatar>
                <ListItemText sx={{ color: "#000" }} className="contactText">
                  {" "}
                  {contact.phone}
                </ListItemText>
              </ListItem>
              {/* <Phone sx={{ color: "green" }} /> {contact.phone} */}
            </div>
            <div className="social" sx={{ p: 1 }}>
              {/* <Phone sx={{ color: "green" }} /> */}
              <Link
                href={contact.facebook_link ? contact.facebook_link : ""}
                color="textPrimary"
                variant="subtitle2"
                underline="none"
                target="_blank"
              >
                <Facebook className="socialIcon" />{" "}
              </Link>
              <Link
                href={contact.twitter_link ? contact.twitter_link : ""}
                color="textPrimary"
                variant="subtitle2"
                underline="none"
                target="_blank"
              >
                <Twitter className="socialIcon" />{" "}
              </Link>
              <Link
                href={contact.instgram_link ? contact.instgram_link : ""}
                color="textPrimary"
                variant="subtitle2"
                underline="none"
                target="_blank"
              >
                <Instagram className="socialIcon" />{" "}
              </Link>
              <Link
                href={contact.linkedin_link ? contact.linkedin_link : ""}
                color="textPrimary"
                variant="subtitle2"
                underline="none"
                target="_blank"
              >
                <LinkedIn className="socialIcon" />{" "}
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
      <WhatsappDrawer phone={contact.phone} />
    </Box>
  );
};

export default PricingFooter;
