import toast from "react-hot-toast";
import {
    getAccessToken,
    fetch_instance,
    ServerURL,
    WithdrawalUrl, TransactionUrl, getAgentCommissionsCalculations, treasuryTransactionsSummaryUrl,
} from "./ServerConfig";
import axios from "axios";

class TransactionAPI {
    async getById(id) {
        let response = [];
        await fetch_instance(`${ServerURL}${WithdrawalUrl}${id}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
            },
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                response = data;
                return Promise.resolve(response);
            })
            .catch((error) => {
                toast.error(error.toString());
                console.log(error);
            });
        return Promise.resolve(response);
    }

    async get(searchParams) {
        let response = [];
        await fetch_instance(
            `${ServerURL}${TransactionUrl}?created_at_after=${searchParams.fromDate}&created_at_before=${searchParams.toDate}&note=${searchParams.note}&amount=${searchParams.amount}&treasury=${searchParams.treasury}&content_type=${searchParams.content_type}&object_id=${searchParams.object_id}&transaction_type=${searchParams.transaction_type}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                response = data;
                return Promise.resolve(response);
            })
            .catch((error) => {
                toast.error(error.toString());
                console.log(error);
            });
        return Promise.resolve(response);
    }

    async getTransactionsSources() {
        let response = [];
        await fetch_instance(
            `${ServerURL}${TransactionUrl}sources/`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                response = data;
                return Promise.resolve(response);
            })
            .catch((error) => {
                toast.error(error.toString());
                console.log(error);
            });
        return Promise.resolve(response);
    }

    async create(payload) {
        let response = {};
        await fetch_instance(`${ServerURL}${WithdrawalUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getAccessToken()}`,
            },
            body: JSON.stringify(payload),
        }).then((res) => {
            response = res;
        });
        return Promise.resolve(response);
    }

    async getTransactionsSummary(query) {
        try {
            const response = await axios.get(`${ServerURL}${treasuryTransactionsSummaryUrl}`, {
                    headers: { Authorization: `Bearer ${getAccessToken()}` },
                    params: query
                },
            );
            console.log('response.data', response.data);
            return response.data;
        } catch (error) {
            console.log('error --> ', error)
            return error;
        }
    }
}

export const transaction_api = new TransactionAPI();
