import { format, subDays, subHours, subMinutes, subSeconds } from "date-fns";
import {
  ServerURL,
  getAccessToken,
  fetch_instance,
  ExportToExcelApiMethodUrl,
  ExportToShipmentExcelApiMethodUrl,
  ExportDashboardToExcelApiMethodUrl,
  ExportDashboardTodayOutForDeliverOrdersExcelApiMethodUrl, getShipperOrderReportMethodUrl
} from "./ServerConfig";
import toast from "react-hot-toast";
import { DateApi } from "./DateApi";


class ExportToExcelApi {
  async downloadexcel(dataList, filename = "Export Data", paymentMethod= null) {
    let body = {
      data: dataList,
      filename: filename,
    }
    if (paymentMethod) {
      body.paymentMethod = paymentMethod
    }
    if (dataList.length > 0) {
      toast.success("File to be downloaded");
      fetch_instance(`${ServerURL}${ExportToExcelApiMethodUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.ms-excel",
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          if (blob.size > 0) {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${filename}.xls`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();
            toast.success("Download is Done");

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          } else {
            toast.error("Empty Data");
          }
        })
        .catch((e) => {
          toast.error("Something wrong");
        });
    } else {
      toast.error("Empty Data");
    }
  }
  async downloadshipmentexcel(dataList, filename = "Export Data") {
    if (dataList.length > 0) {
      toast.success("File to be downloaded");
      fetch_instance(`${ServerURL}${ExportToShipmentExcelApiMethodUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.ms-excel",
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          data: dataList,
          filename: filename,
        }),
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          if (blob.size > 0) {
            // console.log('blob' , blob)
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${filename}.xls`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();
            toast.success("Download is Done");

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          } else {
            toast.error("Empty Data");
          }
        })
        .catch((e) => {
          toast.error("Something wrong");
          console.log(e);
        });
    } else {
      toast.error("Empty Data");
    }
  }
  async downloadDashboardExcel() {
    toast.success("File to be downloaded");
    fetch_instance(`${ServerURL}${ExportDashboardToExcelApiMethodUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        if (blob.size > 0) {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          let date_Str = format(DateApi.getDate(), "yyMMddHHmmss").toString()
          link.setAttribute("download", `Dashboard_${date_Str}.xls`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();
          toast.success("Download is Done");

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        } else {
          toast.error("Empty Data");
        }
      })
      .catch((e) => {
        toast.error("Something wrong");
        console.log(e);
      });
  }

  async downloadDashboardTodayOutForDeliverOrdersExcel() {
    toast.success("File to be downloaded");
    fetch_instance(`${ServerURL}${ExportDashboardTodayOutForDeliverOrdersExcelApiMethodUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        if (blob.size > 0) {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          let date_Str = format(DateApi.getDate(), "yyMMddHHmmss").toString()
          link.setAttribute("download", `Dashboard_TodayOutForDeliverOrders_${date_Str}.xls`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();
          toast.success("Download is Done");

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        } else {
          toast.error("Empty Data");
        }
      })
      .catch((e) => {
        toast.error("Something wrong");
        console.log(e);
      });
  }

  async downloadMandoobCustodyExcel(shipper,query, exportExcel) {
    console.log('downloadMandoobCustodyExcel')
    toast.success("File to be downloaded");
    fetch_instance(`${ServerURL}${getShipperOrderReportMethodUrl}`, {
      method: "POST",
      headers: {
        "Content-Type":"application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({shipper,query, exportExcel}),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        if (blob.size > 0) {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          let date_Str = format(DateApi.getDate(), "dd-MM-yyyy").toString()
          link.setAttribute("download", `Mandoob Custody Report ${date_Str}.xls`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();
          toast.success("Download is Done");

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        } else {
          toast.error("Empty Data");
        }
      })
      .catch((e) => {
        toast.error("Something wrong");
        console.log(e);
      });
  }
}

export const ExportToExcelApis = new ExportToExcelApi();
