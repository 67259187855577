import Label from "../components/Label";
import DownloadIcon from "../icons/Download";
import UploadIcon from "../icons/Upload";
import { Button, IconButton } from "@material-ui/core";

import toast from "react-hot-toast";
import { number } from "prop-types";

export const OrderDeliveryStatus = {
  1: "Pending",
  2: "Out For Deliver",
  3: "Deliverd",
  4: "Hold",
  5: "Undeliverd",
  6: "Rejected",
};

export const orderDeliveryStatusList = [
  {id: 1, value: "Pending"},
  {id: 2, value: "Out For Deliver"},
  {id: 3, value: "Deliverd"},
  {id: 4, value: "Hold"},
  {id: 5, value: "Undeliverd"},
  {id: 6, value: "Rejected"},
]

export const getOrderStatusLabelwithAction = (key, ClickAction, params) => {
  const map = {
    1: {
      text: "Pending",
      color: "primary",
    },
    2: {
      text: "Out For Deliver",
      color: "secondary",
    },
    3: {
      text: "Deliverd",
      color: "success",
    },
    4: {
      text: "Hold",
      color: "warning",
    },
    5: {
      text: "Undeliverd",
      color: "error",
    },
    6: {
      text: "Rejected",
      color: "error",
    },
    7:{
      text: "In Hub",
      color: "info",
    }
  };

  const { text, color } = map[key];
  // alert(fun)
  if (ClickAction && params) {
    return (
      <Label color={color} onClick={(event) => ClickAction(params)}>
        {text}
      </Label>
    );
  }
  return <Label color={color}>{text}</Label>;
};
export const getOrderStatusLabel = (key) => {
  const map = {
    1: {
      text: "Pending",
      color: "primary",
    },
    2: {
      text: "Out For Deliver",
      color: "secondary",
    },
    3: {
      text: "Deliverd",
      color: "success",
    },
    4: {
      text: "Hold",
      color: "warning",
    },
    5: {
      text: "Undeliverd",
      color: "error",
    },
    6: {
      text: "Rejected",
      color: "error",
    },
    7: {
      text: "In Hub",
      color: "info",
    },
  };

  const { text, color } = map[key];
  // alert(fun)

  return <Label color={color}>{text}</Label>;
};
export const getReturnStatusLabel = (key) => {
  const map = {
    pending: {
      text: "Pending",
      color: "primary",
    },
    success: {
      text: "Returned",
      color: "success",
    },
    error: {
      text: "Error",
      color: "error",
    },
  };

  const { text, color } = map[key];
  // alert(fun)

  return <Label color={color}>{text}</Label>;
};
export const getStockEntryStatusLabel = (key) => {
  const map = {
    0: {
      text: "Draft",
      color: "primary",
    },
    1: {
      text: "Submitted",
      color: "success",
    },
    2: {
      text: "Cancelled",
      color: "error",
    },
  };

  const { text, color } = map[key];
  // alert(fun)

  return <Label color={color}>{text}</Label>;
};
export const getOrderStatusAdminLabel = (
  order,
  handleUpdateOrderStatusModel,
  can_change_status,
  is_superuser,
  is_return_request,
) => {
  const map = {
    1: {
      text: "Pending",
      color: "primary",
    },
    2: {
      text: "Out For Deliver",
      color: "secondary",
    },
    3: {
      text: "Deliverd",
      color: "success",
    },
    4: {
      text: "Hold",
      color: "warning",
    },
    5: {
      text: "Undeliverd",
      color: "error",
    },
    6: {
      text: "Rejected",
      color: "error",
    },
    7: {
      text: "In Hub",
      color: "info",
    },
  };

  const { text, color } = map[order.Order_Delivery_Status];
  if (is_return_request) {
    return  <Label color={color}>{text}</Label>
  }
  if (is_superuser) {
    return (
      <Label
        color={color}
        onClick={(event) => handleUpdateOrderStatusModel(order)}
      >
        {text}
      </Label>
    );
  } else {
    return can_change_status &&
      !order.Order_Collect_Status &&
      !order.Returned_Client ? (
      <Label
        color={color}
        onClick={(event) => handleUpdateOrderStatusModel(order)}
      >
        {text}
      </Label>
    ) : (
      <Label color={color}>{text}</Label>
    );
  }
};

export const getboolLabel = (val, cellColor) => {
  if (val == true) {
    return (
      <span style={{ color: cellColor, fontWeight: "bold", float: "center" }}>
        &#10003;
      </span>
    );
  } else {
    return (
      <span style={{ color: cellColor, fontWeight: "bold", float: "center" }}>
        &#10005;
      </span>
    );
  }
};

export const GetInOutOrder = (
  val,
  orderid,
  updateInOutFunc,
  can_edit_in_out,
  is_superuser
) => {
  // console.log('teeeeeest',{val,orderid,updateInOutFunc,can_edit_in_out,is_superuser})
  if (val == true) {
    return can_edit_in_out == true || is_superuser ? (
      <>
      <Label color="success">Confirmed</Label>
      <Button
        
        sx={{ m: 1, padding: "1px",backgroundColor:'#a51010' }}
        variant="contained"
        onClick={(event) => updateInOutFunc(orderid, false)}
      >
       
      x

      </Button>
    </>
    ) : (
      <Label color="success">Confirmed</Label>
    );
  } else {
    return can_edit_in_out == true || is_superuser ? (
      <>
      
      <Button
        
        sx={{ m: 1, padding: "1px",backgroundColor : '#a51010' }}
        variant="contained"
        onClick={(event) => updateInOutFunc(orderid, true)}
      >
        Confirm
      </Button>
      </>
    ) : (
      <Label color="success">Confirm</Label>
    );
  }
};

export const GetMoneyCashed = (
  val,
  orderid,
  updateMoneyCashedFunc,
  cellColor,
  can_edit_moneycashed,
  is_superuser
) => {
  if (val == false) {
    return can_edit_moneycashed == true || is_superuser ? (
      <Button
        color="primary"
        sx={{
          width: "120px",
          padding: "1px",
          backgroundColor: "primary",
          color: "#fff",
        }}
        variant="contained"
        onClick={(event) => updateMoneyCashedFunc(orderid, false)}
      >
        {" "}
        submit cash{" "}
      </Button>
    ) : (
      ""
    );
  } else {
    return (
      <span style={{ color: cellColor, fontWeight: "bold", float: "center" }}>
        &#10003;
      </span>
    );
  }
};
